import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

interface EmailDetailAccordionProps {
  id: string;
  label: React.ReactNode;
  children: React.ReactNode;
  defaultExpanded?: boolean;
}

export const EmailDetailAccordion: React.FC<EmailDetailAccordionProps> = ({
  id,
  label,
  children,
  defaultExpanded = true,
}) => (
  <Accordion defaultExpanded={defaultExpanded}>
    <AccordionSummary aria-controls={`${id}-content`} expandIcon={<ExpandMoreIcon />} id={`${id}-header`} tabIndex={-1}>
      <Typography component="span">{label}</Typography>
    </AccordionSummary>
    <AccordionDetails>{children}</AccordionDetails>
  </Accordion>
);
