import { IListResponse, ISearchRequest } from "@app/services/apiTypes";
import { appApi } from "@app/services/appApi";
import { IChangePasswordUser, ILoginRequest, ILoginResponse, IUserCreate, IUserRead, IUserUpdate } from "./authTypes";

// this is a workaroung, in fact we should do the opposite: make appApi mount this slice. but right now i cant get to make it work
const authApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    // Fetch authz policy
    getPolicy: builder.query<{ payload: string }, void>({
      query: () => ({ url: "/authz" }),
    }),

    // Login mutation with token storage on success
    login: builder.mutation<ILoginResponse, ILoginRequest>({
      query: ({ username, password }) => ({
        url: "/user/login",
        method: "POST",
        body: { username, password },
      }),
      invalidatesTags: ["USER", "AUTH_CONTEXT"],
      transformResponse: (response: ILoginResponse) => {
        if (response.token) {
          // This is a side effect: we shouldn't do this but it works
          localStorage.setItem("token", response.token);
        }
        return response;
      },
    }),

    // Logout mutation that clears the token
    logout: builder.mutation<void, void>({
      query: () => ({
        url: "/user/logout",
        method: "POST",
      }),
      invalidatesTags: ["USER", "AUTH_CONTEXT"],
      transformResponse: (response: void) => {
        localStorage.removeItem("token");
        return response;
      },
    }),

    // Current user information endpoint
    currentUser: builder.query<IUserRead, void>({
      query: () => "/user/current",
      providesTags: ["USER"],
    }),

    // Retrieve a single user by ID
    getUser: builder.query<IUserRead, number | string>({
      query: (userId) => `/user/${userId}`,
      providesTags: ["USER"],
    }),

    // Retrieve a list of users supporting pagination and search
    getUsers: builder.query<IListResponse<IUserRead>, ISearchRequest>({
      query: ({ page, pageSize, search }) => {
        const params = new URLSearchParams();
        if (page !== undefined) params.append("page", page.toString());
        if (pageSize !== undefined) params.append("page_size", pageSize.toString());
        if (search !== undefined && search !== "") params.append("search", search);
        params.append("show_all", "true");
        return `/user?${params.toString()}`;
      },
      providesTags: ["USER"],
    }),

    // Create user mutation
    createUser: builder.mutation<IUserRead, IUserCreate>({
      query: (user) => ({
        url: "/user",
        method: "POST",
        body: user,
      }),
      invalidatesTags: ["USER"],
    }),

    // Signup mutation for new users
    signup: builder.mutation<IUserRead, { username: string; password: string; organization: string }>({
      query: (credentials) => ({
        url: "/user/signup",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["USER"],
    }),

    // Update user mutation
    updateUser: builder.mutation<IUserRead, IUserUpdate & { id: number | string }>({
      query: ({ id, ...user }) => ({
        url: `/user/${id}`,
        method: "PUT",
        body: user,
      }),
      invalidatesTags: ["USER"],
    }),

    // Change password mutation
    changePasswordUser: builder.mutation<IUserRead, IChangePasswordUser>({
      query: (payload) => ({
        url: "/user/update-password",
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["USER"],
    }),

    // Delete user mutation
    deleteUser: builder.mutation<void, number | string>({
      query: (userId) => ({
        url: `/user/${userId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["USER"],
    }),
  }),
});
export const {
  useGetPolicyQuery,
  useLoginMutation,
  useLogoutMutation,
  useCurrentUserQuery,
  useGetUserQuery,
  useGetUsersQuery,
  useCreateUserMutation,
  useSignupMutation,
  useUpdateUserMutation,
  useChangePasswordUserMutation,
  useDeleteUserMutation,
} = authApi;
