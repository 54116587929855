import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import { enqueueSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useCurrentUserQuery, useLoginMutation, useLogoutMutation } from "./authApi"
import { AuthContext } from "./AuthContext";
import { IUserRead } from "./authTypes"

interface Props { }

const AuthContextProvider = ({ children }: React.PropsWithChildren<Props>) => {
  // Init user to null if no token is present, undefined if there is so we can show a loading indicator
  const [user, setUser] = React.useState<IUserRead | null | undefined>(
    localStorage.getItem("token") ? undefined : null,
  );

  // Prepare API calls
  const [login, { data: loginData, isError, isSuccess }] = useLoginMutation();
  const [logout, { isError: isLogoutError, isSuccess: isLogoutSuccess }] = useLogoutMutation();
  const { data, isError: isUserError, isSuccess: isUserSuccess, error: userError } = useCurrentUserQuery();

  // handle login success: save token to localstorage
  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar("Login successful", { variant: "success" });
    }
  }, [isSuccess, loginData]);

  // Handle logout success: clear the token from localstorage and set user to null
  useEffect(() => {
    if (isLogoutSuccess) {
      setUser(null);
      enqueueSnackbar("Logout successful", { variant: "success" });
    }
  }, [isLogoutSuccess]);

  // Handle login errors: show a toast message
  useEffect(() => {
    if (isError) {
      enqueueSnackbar("Login failed", { variant: "error" });
    }
  }, [isError]);

  // Handle logout errors: show a toast message
  useEffect(() => {
    if (isLogoutError) {
      enqueueSnackbar("Logout failed", { variant: "error" });
    }
  }, [isLogoutError]);

  // Handle current user updates: set or clear the user
  useEffect(() => {
    if (isUserSuccess && data) {
      // We have a user, set it
      setUser(data);
    } else if (isUserError && (userError as FetchBaseQueryError).status === 401) {
      // Request unauthorized, remove token and set user to null
      setUser(null);
    }
  }, [isUserSuccess, isUserError, data, userError]);

  return <AuthContext.Provider value={{ user, login, logout }}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
