import { Box, Typography } from "@mui/material";
import Stack from "@mui/system/Stack";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useGetOrdersQuery } from "../../app/services/appApi";
import { withAuth } from "@core/auth/authWrappers";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const OrderListPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();


  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  });

  const [searchValue, setSearchValue] = useState("");
  const { data, isLoading } = useGetOrdersQuery({
    page: paginationModel.page + 1,
    pageSize: paginationModel.pageSize,
    search: searchValue,
  });

  const columns: GridColDef[] = [
    {
      field: "Id",
      headerName: t("order.list.id"),
      width: 180,
    },
    {
      field: "price",
      headerName: t("order.list.price"),
      flex: 1,
    },
    {
      field: "product",
      headerName: t("order.list.product"),
      // Grow the column width
      flex: 1,
    },
    {
      field: "qty_ordered",
      headerName: t("order.list.qty_ordered"),
      // Grow the column width
      flex: 1,
    },
    {
      field: "qty_delivered",
      headerName: t("order.list.qty_delivered"),
      // Grow the column width
      flex: 1,
    },
    {
      field: "contact",
      headerName: t("order.list.contact"),
      // Grow the column width
      flex: 1,
    },
    {
      field: "state",
      headerName: t("order.list.state"),
      // Grow the column width
      flex: 1,
    }


  ];

  return (
    <>
      <Stack justifyContent="space-between" gap={2} direction="row" sx={{ mb: 2, alignItems: "center" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <AttachMoneyIcon />
          <Typography variant="h5" component="h1" noWrap>
            <b>{t("order.list.title")}</b>
          </Typography>
        </Box>
      </Stack>

      <DataGrid
        disableRowSelectionOnClick
        rows={data?.results || []}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        pageSizeOptions={[20, 50, 100]}
        sx={{ flex: 1 }}
        loading={isLoading}
        paginationMode="server"
        rowCount={data?.count || 0}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
      />
    </>
  );
};


export default withAuth(OrderListPage);
