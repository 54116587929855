import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import JsonView from "react18-json-view";
import "react18-json-view/src/style.css";
import { HighlightAreaWithLabelAndId } from "../EmailDetailPage";
import EmailArtifactsTab from "./artifacts/EmailArtifactsTab";
import EmailFieldsTab from "./fields/EmailFieldsTab";
import { getTabPlugins } from "./plugins/TabPluginRegistry";
import { useEmailForm } from "../context/EmailFormContext";
import UnsavedChangesDialog from "./UnsavedChangesDialog";
import * as Sentry from "@sentry/react";

interface EmailTabsColumnProps {
  email: any;
  isLoading: boolean;
  handleTarget: (target: HighlightAreaWithLabelAndId) => void;
}

interface TabDefinition {
  id: string;
  label: string;
  priority: number;
  content: React.ReactNode;
}

const TabErrorBoundary: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => (
        <div>
          <h3>{t("error.tabCrashed")}</h3>
          <p>{t("error.tabCrashedDescription")}</p>
          <pre>{error.toString()}</pre>
        </div>
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};


function EmailTabsColumn({
  email,
  isLoading,
  handleTarget,
}: EmailTabsColumnProps) {
  const { t } = useTranslation();
  const { form, isDirty, handleValidation2 } = useEmailForm();
  const [activeTabId, setActiveTabId] = useState("fields");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [pendingTabId, setPendingTabId] = useState<string | null>(null);

  // Define your built-in tabs. Note here that we use custom string IDs.
  const builtInTabs: TabDefinition[] = useMemo(
    () => [
      {
        id: "fields",
        label: t("email.extractedData"),
        priority: 0,
        content: (
          <TabPanel value="fields" sx={{ height: "100%", p: 0 }}>

            <EmailFieldsTab
              email={email}
              isLoading={isLoading}
              onTarget={handleTarget}
            />
          </TabPanel>
        ),
      },
      {
        id: "artifacts",
        label: t("email.artifacts"),
        priority: -20,
        content: (
          <TabPanel value="artifacts" sx={{ height: "100%", p: 0 }}>
            <EmailArtifactsTab artifacts={email.artifacts} />
          </TabPanel>
        ),
      },
      // {
      //   id: "events",
      //   label: t("email.alerts"),
      //   content: (
      //     <TabPanel value="events" sx={{ height: "100%", p: 0 }}>
      //       <EmailEventsTab events={email.events} />
      //     </TabPanel>
      //   ),
      // },
      {
        id: "code",
        label: t("email.codeExport"),
        priority: -40,
        content: (
          <TabPanel value="code" sx={{ height: "100%", p: 0 }}>
            <Box sx={{ p: 3 }}>
              <Typography variant="body1" sx={{ mb: 2 }}>
                <JsonView
                  src={email.parsed_info}
                  collapsed={2}
                  collapseStringMode="directly"
                  collapseStringsAfterLength={30}
                />
              </Typography>
            </Box>
          </TabPanel>
        ),
      },
    ],
    [email, handleTarget, isLoading, t],
  );

  const pluginTabs = useMemo(
    () =>
      getTabPlugins(email.workflow.name).map((plugin) => ({
        id: `plugin-${plugin.id}`,
        label: plugin.label,
        priority: plugin.priority,
        content: (
          <TabPanel value={`plugin-${plugin.id}`} sx={{ height: "100%", p: 0 }}>
            {form ? plugin.render(form, email, t) : <Typography>{t("email.loadingPlugin")}</Typography>}
          </TabPanel>
        ),
      })),
    [email, form, t],
  );

  // Combine all tabs together.
  const allTabs = useMemo(
    () => [...builtInTabs, ...pluginTabs].sort((a, b) => b.priority - a.priority),
    [builtInTabs, pluginTabs],
  );

  // When switching tabs, if we're leaving the "fields" tab and the email form is dirty, warn the user.
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    if (activeTabId === "fields" && isDirty && newValue !== "fields") {
      setPendingTabId(newValue);
      setDialogOpen(true);
    } else {
      setActiveTabId(newValue);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setPendingTabId(null);
  };

  const handleDialogContinue = () => {
    setDialogOpen(false);
    if (pendingTabId) {
      setActiveTabId(pendingTabId);
      setPendingTabId(null);
    }
  };

  /**
   * Handles the save action in the dialog when the user is trying to switch tabs and the form is dirty.
   * If submit success, switch to the new tab and close the dialog.
   * Otherwise, close the dialog without switching tabs.
   */
  const handleDialogSave = async () => {
    const formData = form.getValues();
    try {
      const validationAndSubmitSuccess = await handleValidation2(formData);
      if (validationAndSubmitSuccess && pendingTabId) {
        // Submit success, switch to the new tab and close the dialog.
        setActiveTabId(pendingTabId);
        setPendingTabId(null);
      }
    } finally {
      setDialogOpen(false);
    }
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <TabContext value={activeTabId}>
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%", px: 1 }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={activeTabId}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable tabs"
            >
              {allTabs.map((tab) => (
                <Tab key={tab.id} label={tab.label} value={tab.id} />
              ))}
            </Tabs>
          </Box>
          <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
            {allTabs.map((tab) => (
              <React.Fragment key={tab.id}>
                <TabErrorBoundary>{tab.content}</TabErrorBoundary>
              </React.Fragment>
            ))}
          </Box>
        </Box>
      </TabContext>
      <UnsavedChangesDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        onContinue={handleDialogContinue}
        onSave={handleDialogSave}
      />
    </div>
  );
}

export default EmailTabsColumn;