import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FactoryIcon from "@mui/icons-material/Factory";
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/system/Stack";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDeleteOrganizationMutation, useGetOrganizationsQuery } from "../../app/services/appApi";
import { withAuth } from "@core/auth/authWrappers";
import { IOrganizationRead } from "@core/auth/authTypes";
import OrganizationFormDialog from "./OrganizationFormDialog";
import OrganizationSearchBar from "./OrganizationSearchBar";
import DeleteDialog from "@core/delete_dialog/DeleteDialog";
import OrganizationTableRowMenu from "./OrganizationTableRowMenu";

const OrganizationListPage = () => {
  const navigate = useNavigate();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  });

  const [searchValue, setSearchValue] = useState("");
  const { data, isLoading } = useGetOrganizationsQuery({
    page: paginationModel.page + 1,
    pageSize: paginationModel.pageSize,
    search: searchValue,
  });

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      width: 30,
    },
    {
      field: "is_deactivated",
      headerName: "Is Deactivated",
      width: 120
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      width: 80,
      field: "edit",
      headerName: "",
      //@ts-ignore-next-line
      renderCell: (params: GridValueGetterParams) => <OrganizationFormDialog organizationId={params.row.id} />,

    },
    {
      width: 80,
      field: "actions",
      headerName: "",
      //@ts-ignore-next-line
      renderCell: (params) => (
        <OrganizationTableRowMenu
          onDelete={() => handleDelete(params.row.id)}
        />
      ),
    },
  ];

  const [deleteOrganization] = useDeleteOrganizationMutation();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [organizationToDelete, setOrganizationToDelete] = useState<IOrganizationRead | null>(null);
  const { t } = useTranslation();

  const handleDelete = (organizationId: number) => {
    const organization = data?.results.find(org => org.id.toString() === organizationId.toString());
    if (organization) {
      setOrganizationToDelete(organization);
      setOpenDeleteDialog(true);
    }
  };

  const handleDeleteConfirm = async () => {
    if (organizationToDelete) {
      try {
        await deleteOrganization(organizationToDelete.id).unwrap();
        enqueueSnackbar(t("Model deleted", { model: "Organization", count: 1 }), { variant: "success" });
      } catch (error) {
        enqueueSnackbar(t("Model deletion failed", { model: "Organization", count: 1 }), { variant: "error" });
      }
    }
    setOpenDeleteDialog(false);
  };

  return (
    <>
      <Stack justifyContent="space-between" gap={2} direction="row" sx={{ mb: 2, alignItems: "center" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <FactoryIcon />
          <Typography variant="h5" component="h1" noWrap>
            <b> Organizzazioni</b>
          </Typography>
        </Box>
        <Stack direction="row" gap={2} alignItems="center">
          {/* <OrganizationSearchBar onSearch={setSearchValue} size="small" /> */}
          <OrganizationFormDialog
            render={(props) => (
              <Button startIcon={<AddIcon />} variant="contained" color="primary" {...props}>
                Aggiungi
              </Button>
            )}
          />
        </Stack>
      </Stack>

      <DataGrid
        disableRowSelectionOnClick
        rows={data?.results || []}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        pageSizeOptions={[20, 50, 100]}
        sx={{ flex: 1 }}
        loading={isLoading}
        paginationMode="server"
        rowCount={data?.count || 0}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
      />

      <DeleteDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onConfirm={handleDeleteConfirm}
        itemName={organizationToDelete?.name || ""}
        itemType={t("Organization")}
      />
    </>
  );
};

export default withAuth(OrganizationListPage);