// frontend/src/features/admin_organization/OrganizationTableRowMenu.tsx

import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Menu, MenuItem } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuList from "@mui/material/MenuList";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface IOrganizationTableRowMenu {
    onDelete: () => void;
}

export default function OrganizationTableRowMenu({
    onDelete,
}: IOrganizationTableRowMenu) {
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        onDelete();
        handleMenuClose();
    };

    return (
        <>
            <GridActionsCellItem
                icon={<MoreVertIcon />}
                label={t("common.more")}
                className="actions"
                onClick={handleMenuOpen}
            />
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuList>
                    <MenuItem onClick={handleDelete}>
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary={t("common.delete")} />
                    </MenuItem>
                </MenuList>
            </Menu>
        </>
    );
}