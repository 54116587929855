import AddIcon from "@mui/icons-material/Add";
import RouteIcon from '@mui/icons-material/Route';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
  Avatar,
  useTheme,
  IconButton,
  Dialog,
  DialogTitle,
  TextField,
  DialogContent,
  FormControlLabel,
  DialogActions,
  CircularProgress,
  Switch,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useGetWorkflowsQuery, useDeleteWorkflowMutation, useCreateWorkflowMutation } from "../../app/services/appApi";
import { withAuth } from "@core/auth/authWrappers";
import { IWorkflowRead } from "@features/workflow/workflowTypes";
import { useAuthorization } from "@core/auth/authz/useAuthorization";
import React, { useState, useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import DeleteDialog from "@core/delete_dialog/DeleteDialog";
import WorkflowTableRowMenu from "../admin_workflows/WorkflowTableRowMenu";

interface WorkflowFormData {
  code: string;
  title: string;
  subtitle: string;
  workflow_docs: string;
  ratelimit: number;
  name: string;
  enable_register: boolean;
  memories: string;
}

const WorkflowCard = ({ workflow }: { workflow: IWorkflowRead }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const { can } = useAuthorization();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteWorkflow, { isLoading: isDeleting, isError: deleteError, isSuccess: deleteSuccess }] = useDeleteWorkflowMutation();

  const handleOpenClick = (id: number) => {
    navigate(`/workflow/${id}`);
  };

  const handleEditClick = (id: number) => {
    navigate(`/workflow/${id}/edit`);
  };

  const handleDeleteClick = () => {
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = () => {
    deleteWorkflow(workflow.id);
  };

  useEffect(() => {
    if (deleteSuccess) {
      enqueueSnackbar(t("workflow.deleteSuccess"), { variant: "success" });
    }
    if (deleteError) {
      enqueueSnackbar(t("workflow.deleteError"), { variant: "error" });
    }
  }, [deleteSuccess, deleteError, t]);

  const { id, email_addr, count_emails, count_emails_current_month, count_emails_last_24h, title, subtitle, ratelimit } = workflow;

  const progressPercentage = Math.min((count_emails_current_month / ratelimit) * 100, 100);

  return (
    <Grid item xs={12} md={6} lg={4}>
      <Card elevation={3} sx={{ height: '100%', display: 'flex', flexDirection: 'column', transition: 'transform 0.2s', '&:hover': { transform: 'scale(1.02)' } }}>
        <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Stack direction="row" alignItems="center" spacing={2} mb={2}>
            <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
              <RouteIcon />
            </Avatar>
            <Box>
              <Typography variant="h6" component="div" noWrap>
                <b>{title}</b>
              </Typography>
              <Typography variant="body2" color="text.secondary" noWrap>
                {email_addr}
              </Typography>
            </Box>
          </Stack>

          <Box mb={3} display="flex" justifyContent="space-between">
            <StatBox label={t("workflow.stats.total")} value={count_emails} />
            <StatBox label={t("workflow.stats.currentMonth")} value={`${count_emails_current_month}/${ratelimit}`} />
            <StatBox label={t("workflow.stats.last24h")} value={count_emails_last_24h} />
          </Box>
          <LinearProgress
            variant="determinate"
            value={progressPercentage}
            sx={{
              height: 8,
              borderRadius: 4,
              mb: 2,
              backgroundColor: theme.palette.grey[200],
              '& .MuiLinearProgress-bar': {
                borderRadius: 4,
                backgroundColor: progressPercentage > 90 ? theme.palette.error.main : theme.palette.primary.main,
              },
            }}
          />
          <Stack direction="row" spacing={1} sx={{ mt: 'auto', alignSelf: 'flex-end' }}>
            {can("view", "workflow", "edit_menu") &&
              <WorkflowTableRowMenu
                workflowId={id}
                deleteBtn={can("view", "workflow", "delete_btn")}
                duplicateBtn
                editBtn
              />
            }
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => handleOpenClick(id)}
              endIcon={<ArrowForwardIcon />}
              sx={{ borderRadius: 2, minWidth: 100 }}
            >
              {t("workflow.view")}
            </Button>
          </Stack>
        </CardContent>
      </Card>
      <DeleteDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onConfirm={handleDeleteConfirm}
        itemName={title}
        itemType={t("workflow.type")}
      />
    </Grid>
  );
};

const StatBox = ({ label, value }: { label: string; value: string | number }) => (
  <Box textAlign="center" sx={{ bgcolor: (theme) => theme.palette.background.default, p: 1, borderRadius: 1 }}>
    <Typography variant="h6" fontWeight="bold">{value}</Typography>
    <Typography variant="caption" color="text.secondary">{label}</Typography>
  </Box>
);

const AddWorkflowCard = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleAddWorkflow = () => {
    navigate('/workflow/new');
  };

  return (
    <Grid item xs={12} md={6} lg={4}>
      <Card
        elevation={3}
        onClick={handleAddWorkflow}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          opacity: 0.8,
          border: `1px dashed ${theme.palette.grey[400]}`,
          backgroundColor: theme.palette.background.default,
          transition: 'transform 0.2s',
          '&:hover': { transform: 'scale(1.01)', color: theme.palette.primary.main, cursor: 'pointer' },
        }}
      >
        <CardContent sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <Box textAlign="center">
            <AddIcon sx={{ fontSize: 60, color: theme.palette.grey[500], mb: 2 }} />
            <Typography variant="h6" color="text.secondary">
              {t("workflow.newWorkflow")}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {t("workflow.createAutomation")}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

const WorkflowListPage = () => {
  const { data, isLoading } = useGetWorkflowsQuery({});
  const rows = data?.results || [];
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
      <Typography variant="h5" component="h1" noWrap sx={{ mb: 3, display: "flex", alignItems: "center", gap: 1 }}>
        <RouteIcon />
        <b>{t("navigation.workflows")}</b>
      </Typography>
      <Grid container spacing={3}>
        {isLoading ? (
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        ) : (
          <>
            {rows.map((workflow) => (
              <WorkflowCard key={workflow.id} workflow={workflow} />
            ))}
            <AddWorkflowCard />
          </>
        )}
      </Grid>
    </Box>
  );
};

export default withAuth(WorkflowListPage);