import { Box, Button, CircularProgress, LinearProgress, Typography, Card, CardContent, useTheme, Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetSyncStatusQuery, useTriggerSyncMutation } from "../../app/services/appApi";
import { SyncProgress } from "./syncTypes";
import SyncIcon from "@mui/icons-material/Sync";

type ProgressType = SyncProgress["addresses"] | SyncProgress["contacts"] | SyncProgress["products"] | SyncProgress["offers"];

const ProgressBar: React.FC<{ progress: ProgressType; label: string }> = ({ progress, label }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const percentage = progress.total > 0 ? (progress.current / progress.total) * 100 : 0;
  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="subtitle1" fontWeight="medium">{t(label)}</Typography>
      <LinearProgress
        variant="determinate"
        value={percentage}
        sx={{
          mt: 1,
          mb: 1,
          height: 8,
          borderRadius: 4,
          backgroundColor: theme.palette.grey[200],
          '& .MuiLinearProgress-bar': {
            borderRadius: 4,
          },
        }}
      />
      <Box display="flex" justifyContent="space-between">
        <Typography variant="body2">{`${Math.round(percentage)}% (${progress.current}/${progress.total})`}</Typography>
        <Typography variant="body2">
          {t("sync.progressDetails", { created: progress.created, updated: progress.updated, deleted: progress.deleted })}
        </Typography>
      </Box>
    </Box>
  );
};

const SyncAllComponent: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [triggerSync, { isLoading: isSyncTriggering }] = useTriggerSyncMutation();
  const [taskId, setTaskId] = useState<string | null>(null);
  const [syncComplete, setSyncComplete] = useState(false);

  const { data: syncStatus, isLoading: isSyncStatusLoading, error: syncStatusError } = useGetSyncStatusQuery(
    { task_id: taskId || "" },
    { skip: !taskId, pollingInterval: 5000 },
  );

  useEffect(() => {
    if (syncStatus && syncStatus.status === "SUCCESS") {
      setTaskId(null);
      setSyncComplete(true);
    }

    // Handle non-200 status responses
    if (syncStatusError) {
      console.error("Sync status error:", syncStatusError);
      setTaskId(null);
    }
  }, [syncStatus, taskId, syncStatusError]);

  const handleSyncClick = async () => {
    try {
      const result = await triggerSync().unwrap();
      setTaskId(result.task_id);
      setSyncComplete(false);
    } catch (error) {
      console.error("Failed to trigger sync:", error);
    }
  };

  const isSyncing = !!taskId && !syncComplete;


  const getSyncStatusMessage = () => {
    if (!syncStatus) return t("sync.initializing");
    switch (syncStatus.status) {
      case "SYNCING_PRODUCTS":
        return t("sync.syncingProducts");
      case "SYNCING_CONTACTS":
        return t("sync.syncingContacts");
      case "SYNCING_ADDRESSES":
        return t("sync.syncingAddresses");
      case "SYNCING_OFFERS":
        return t("sync.syncingOffers");
      case "UPDATING_RELATIONS":
        return t("sync.updatingRelations");
      case "SUCCESS":
        return t("sync.syncCompleted");
      default:
        return t("sync.syncInProgress");
    }
  };

  const renderSyncReport = () => {
    if (!syncStatus || !syncStatus.progress) return null;
    const { products, contacts, addresses, offers } = syncStatus.progress;
    const totalCreated = products.created + contacts.created + addresses.created + offers.created;
    const totalUpdated = products.updated + contacts.updated + addresses.updated + offers.updated;
    const totalDeleted = products.deleted + contacts.deleted + addresses.deleted + offers.deleted;

    return (
      <Alert severity="success" sx={{ mt: 3 }}>
        <Typography variant="h6" gutterBottom>{t("sync.syncCompletedSuccessfully")}</Typography>
        <Typography>{t("sync.totalItemsProcessed", { total: totalCreated + totalUpdated + totalDeleted })}</Typography>
        <Typography>{t("sync.totalCreated", { count: totalCreated })}</Typography>
        <Typography>{t("sync.totalUpdated", { count: totalUpdated })}</Typography>
        <Typography>{t("sync.totalDeleted", { count: totalDeleted })}</Typography>
      </Alert>
    );
  };

  return (
    <Box>
      <Button
        variant="contained"
        onClick={handleSyncClick}
        disabled={isSyncTriggering || isSyncing}
        startIcon={isSyncTriggering || isSyncing ? <CircularProgress size={20} color="inherit" /> : <SyncIcon />}
        size="large"
        sx={{ px: 4, py: 1 }}
      >
        {t("sync.syncAll")}
      </Button>
      {syncStatusError && (
        <Alert severity="error" sx={{ mt: 3 }}>
          {t("sync.errorFetchingStatus")}
        </Alert>
      )}
      {(isSyncing || syncComplete) && (
        isSyncing ? (
          <>
            <Typography sx={{ mt: 3 }} variant="h6" gutterBottom> {getSyncStatusMessage()}</Typography>
            {syncStatus && syncStatus.progress && (
              <>
                <ProgressBar progress={syncStatus.progress.products} label="sync.products" />
                <ProgressBar progress={syncStatus.progress.contacts} label="sync.contacts" />
                <ProgressBar progress={syncStatus.progress.addresses} label="sync.addresses" />
                <ProgressBar progress={syncStatus.progress.offers} label="sync.offers" />
              </>
            )}
          </>
        ) : (
          renderSyncReport()
        )
      )}
    </Box>
  );
};

export default SyncAllComponent;