import { createContext, useContext } from "react";
import { ILoginRequest, IUserRead } from "./authTypes"

interface IAuthContextProps {
  user?: IUserRead | null; // User is undefined while loading, null if not logged in, IUser if logged in
  login: (creds: ILoginRequest) => void;
  logout: () => void;
}

export const AuthContext = createContext<IAuthContextProps | null>(null);

const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuthContext must be used within an AuthProvider");
  }

  return context;
};
export default useAuthContext;
