// frontend/src/features/email/dynamicUi/VerifiedBadge.tsx

import ErrorIcon from "@mui/icons-material/Error";
import VerifiedIcon from "@mui/icons-material/Verified";
import WarningIcon from "@mui/icons-material/Warning";
import { Badge, Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface VerifiedBadgeProps {
  item: any
}

const VerifiedBadge: React.FC<VerifiedBadgeProps> = ({ item }) => {
  const { t } = useTranslation();

  if (!item || !item.validation_checks) return null;

  const { value, message } = item?.validation_checks
  const title = message || t("email.rowVerified")


  const getIcon = () => {
    switch (value) {
      case "success":
        return <VerifiedIcon fontSize="small" color="primary" />;
      case "warning":
        return <WarningIcon fontSize="small" color="warning" />;
      case "error":
        return <ErrorIcon fontSize="small" color="error" />;
      default:
        return null;
    }
  };

  const icon = getIcon();

  if (!icon) return null;

  return (
    <Tooltip title={title}>
      <Badge>{icon}</Badge>
    </Tooltip>
  );
};

export default VerifiedBadge;
