import { useChangePasswordUserMutation } from "@core/auth/authApi"
import { Page } from "@features/layout/Page";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import BusinessIcon from '@mui/icons-material/Business';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import TagIcon from '@mui/icons-material/Tag';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Button from "@mui/material/Button";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import useAuthContext from "@core/auth/AuthContext";
import { withAuth } from "@core/auth/authWrappers";
import { Avatar } from "@mui/material";

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '10px',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '900px',
  },
}));

const ProfileItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2),
  borderRadius: '10px',
  backgroundColor: theme.palette.background.default,
  boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1)',
  border: '1px solid',
  borderColor: theme.palette.divider,
}));

const Profile: FC = () => {
  const { t } = useTranslation();
  const { user, logout } = useAuthContext();

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString("it-IT", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
  };

  return (
    <Page title={t("profile.title")}>
      <Stack
        direction="column"
        sx={{
          minHeight: '100vh',
          background: 'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 50%, 100%))',
          p: { xs: 2, sm: 4 },
        }}
      >
        <StyledCard>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mb: 4 }}>
            <Avatar sx={{ bgcolor: 'primary.main', width: 80, height: 80 }}>
              <AccountCircleOutlinedIcon sx={{ fontSize: 50 }} />
            </Avatar>
            <Typography variant="h4" sx={{ mt: 2, fontWeight: "bold" }}>
              {user?.username}
            </Typography>
          </Box>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <ProfileItem>
                <Box>
                  <Typography color="text.secondary" variant="subtitle2" sx={{ mb: 1 }}>
                    {t("profile.fields.organization")}
                  </Typography>
                  <Typography variant="body1" fontWeight="medium">
                    {user?.organization.name}
                  </Typography>
                </Box>
                <BusinessIcon sx={{ color: 'primary.light' }} />
              </ProfileItem>
            </Grid>

            <Grid item xs={12} sm={6}>
              <ProfileItem>
                <Box>
                  <Typography color="text.secondary" variant="subtitle2" sx={{ mb: 1 }}>
                    {t("profile.fields.organizationId")}
                  </Typography>
                  <Typography variant="body1" fontWeight="medium">
                    {user?.organization.id}
                  </Typography>
                </Box>
                <TagIcon sx={{ color: 'primary.light' }} />
              </ProfileItem>
            </Grid>

            <Grid item xs={12} sm={6}>
              <ProfileItem>
                <Box>
                  <Typography color="text.secondary" variant="subtitle2" sx={{ mb: 1 }}>
                    {t("profile.fields.role")}
                  </Typography>
                  <Typography variant="body1" fontWeight="medium">
                    {user?.role}
                  </Typography>
                </Box>
                <SupervisorAccountIcon sx={{ color: 'primary.light' }} />
              </ProfileItem>
            </Grid>

            <Grid item xs={12} sm={6}>
              <ProfileItem>
                <Box>
                  <Typography color="text.secondary" variant="subtitle2" sx={{ mb: 1 }}>
                    {t("profile.fields.createdAt")}
                  </Typography>
                  <Typography variant="body1" fontWeight="medium">
                    {user?.created_at && formatDate(user.created_at)}
                  </Typography>
                </Box>
                <CalendarTodayIcon sx={{ color: 'primary.light' }} />
              </ProfileItem>
            </Grid>
          </Grid>

          <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            <Button
              variant="contained"
              color="error"
              onClick={logout}
              sx={{
                minWidth: 120,
                borderRadius: '10px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                py: 1,
              }}
            >
              {t("profile.logout")}
            </Button>
          </Box>
        </StyledCard>
      </Stack>
    </Page>
  );
};

export default withAuth(Profile);