import MyAuthzProvider from "@core/auth/authz/MyAuthzProvider";
import LabellerPage from "@features/labeller/LabellerPage";
import SheetImportPage from "@features/sheet_import/SheetImportPage";
import CssBaseline from "@mui/material/CssBaseline";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/it";
import { SnackbarProvider } from "notistack";
import { Route, Routes } from "react-router-dom";
import SettingsPage from "./features/Settings";
import SyncPage from "./features/Sync/SyncPage";
import OrganizationListPage from "./features/admin_organization/OrganizationListPage";
import UserListPage from "./features/admin_user/UserListPage";
import AuthContextProvider from "@core/auth/AuthProvider";
import Login from "./features/auth/Login";
import ProfilePage from "./features/auth/Profile";
import UserDeactivatedPage from "./features/auth/UserDeactivatedPage";
import BenchmarkDetailPage from "./features/benchmark/BenchmarkDetailPage";
import BenchmarkListPage from "./features/benchmark/BenchmarkListPage";
import ContactListPage from "./features/contact/ContactListPage";
import DebugPage from "./features/debug";
import Signup from "./features/auth/Signup";
import { DevSettingsProvider } from "./features/debug/devSettings/DevSettings";
import EmailDetailPage from "./features/email/emailDetailPage/EmailDetailPage";
import EmailListPage from "./features/email/emailListPage";
import NavigationDrawer from "./features/navigation/NavigationDrawer";
import WorkflowListPage from "./features/workflow/WorkflowListPage";
import WorkflowAdminListPage from "@features/admin_workflows/WorkflowAdminListPage";
import ProductListPage from "./features/product/ProductListPage";
import usePageTracking from "./features/tracking/useTracking";
import "./i18n";
import AddressListPage from "@features/address/AddressListPage";
import OfferListPage from "@features/offer/OfferListPage";
import OrderListPage from "@features/order/OderListPage";
import WorkflowEditPage from "@features/admin_workflows/WorkflowEditPage";
import { useLocation } from "react-router-dom";


function App() {
  usePageTracking();
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";
  const isSignupPage = location.pathname === "/signup";

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DevSettingsProvider>
        <AuthContextProvider>
          <MyAuthzProvider>
            <SnackbarProvider autoHideDuration={4000} />
            <CssBaseline />
            {!isLoginPage && !isSignupPage ? (
              <NavigationDrawer>
                <RoutesConfig />
              </NavigationDrawer>
            ) : (
              <RoutesConfig />
            )}
          </MyAuthzProvider>
        </AuthContextProvider>
      </DevSettingsProvider>
    </LocalizationProvider>
  );
}

function RoutesConfig() {
  return (
    <Routes>
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/deactivated" element={<UserDeactivatedPage />} />
      <Route path="/settings" element={<SettingsPage />} />
      <Route path="/" element={<WorkflowListPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route
        path="/workflow/:workflowId/email/:id"
        element={<EmailDetailPage />}
      />
      <Route path="/products" element={<ProductListPage />} />
      <Route path="/contacts" element={<ContactListPage />} />
      <Route path="/offer" element={<OfferListPage />} />
      <Route path="/order" element={<OrderListPage />} />
      <Route path="/addresses" element={<AddressListPage />} />
      <Route path="/benchmarks" element={<BenchmarkListPage />} />
      <Route path="/benchmark/:id" element={<BenchmarkDetailPage />} />
      <Route path="/workflow" element={<WorkflowListPage />} />
      <Route path="/workflow/:id" element={<EmailListPage />} />
      <Route path="/workflow/new" element={<WorkflowEditPage />} />
      <Route path="/workflow/:id/edit" element={<WorkflowEditPage />} />
      <Route path="/admin/user" element={<UserListPage />} />
      <Route path="/admin/workflow" element={<WorkflowAdminListPage />} />
      <Route path="/admin/workflow/:id/edit" element={<WorkflowEditPage />} />
      <Route path="/admin/workflow/new" element={<WorkflowEditPage />} />
      <Route path="/admin/organization" element={<OrganizationListPage />} />
      <Route path="/debug" element={<DebugPage />} />
      <Route path="/sync" element={<SyncPage />} />
      <Route path="/sheet_import" element={<SheetImportPage />} />
      <Route path="/labeller" element={<LabellerPage />} />
    </Routes>
  );
}

export default App;
