import { Search } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface ContactSearchBarProps extends React.ComponentProps<typeof TextField> {
  onSearch: (searchValue: string) => void;
}

export default function ContactSearchBar({ onSearch, ...rest }: ContactSearchBarProps) {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = React.useState("");

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSearch(searchValue);
  };

  return (
    <form onSubmit={handleSearchSubmit}>
      <TextField
        placeholder={t("common.search")}
        inputProps={{ "aria-label": "search" }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton type="submit" aria-label="search">
                <Search />
              </IconButton>
            </InputAdornment>
          ),
        }}
        size="medium"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        {...rest}
      />
    </form>
  );
}
