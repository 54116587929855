import { useUploadEmailMutation } from "@app/services/appApi";
import { UploadFileOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputLabel,
  Stack,
  Switch,
  TextField,
  ThemeProvider,
  Typography,
  alpha,
  createTheme,
  Link,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

interface DropZoneProps {
  isActive: boolean;
  hasFile: boolean;
}

interface FileUploadDialogProps {
  onClose: () => void;
}

const DropZone = styled(Box)<DropZoneProps>(({ theme, isActive, hasFile }) => ({
  border: `2px dashed ${isActive ? theme.palette.primary.main : theme.palette.grey[300]}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(4),
  textAlign: "center",
  color: isActive ? theme.palette.primary.main : theme.palette.text.primary,
  backgroundColor: isActive
    ? alpha(theme.palette.primary.light, 0.1)
    : hasFile
      ? alpha(theme.palette.primary.light, 0.05)
      : "transparent",
  transition: "all 0.3s ease",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.light, 0.1),
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
}));

const theme = createTheme();

const SubtleLink = styled(Link)(({ theme }) => ({
  color: theme.palette.grey[600],
  fontSize: '0.75rem',
  '&:hover': {
    color: theme.palette.grey[800],
  },
}));


const FileUploadDialog: React.FC<FileUploadDialogProps> = ({ onClose }) => {
  const { t } = useTranslation();

  const [showAdditionalInfo, setShowAdditionalInfo] = React.useState<boolean>(false);

  const { id: workflowId } = useParams();
  const [uploadEmail, { isLoading }] = useUploadEmailMutation();
  const [selectedFiles, setSelectedFiles] = React.useState<File[]>([]);
  const [groupFiles, setGroupFiles] = React.useState<boolean>(false);
  const [emailBody, setEmailBody] = React.useState<string>("");

  const onDrop = React.useCallback((acceptedFiles: File[]) => {
    setSelectedFiles((prevFiles) => {
      // Filter out duplicate files based on name and size
      const newFiles = acceptedFiles.filter(
        (newFile) =>
          !prevFiles.some((existingFile) => existingFile.name === newFile.name && existingFile.size === newFile.size),
      );
      return [...prevFiles, ...newFiles];
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"], "application/vnd.ms-excel": [".xls", ".xlsx"], "text/csv": [".csv"], "message/rfc822": [".eml"]
    },
    multiple: true,
  });

  const handleUpload = async () => {
    if (!workflowId) return;

    // Verifica che ci sia almeno un file o il body
    if (selectedFiles.length === 0 && !emailBody.trim()) {
      // Potresti voler mostrare un errore all'utente
      console.error("At least one file or email body is required");
      return;
    }

    try {
      const formData = new FormData();

      // Aggiungi i file solo se ce ne sono
      if (selectedFiles.length > 0) {
        selectedFiles.forEach((file) => {
          formData.append("files", file);
        });
      }

      const payload = {
        workflowId: Number(workflowId),
        ...(selectedFiles.length > 0 && { files: formData }),
        ...(selectedFiles.length > 0 && { group: groupFiles }),
        ...(emailBody.trim() && { body: emailBody.trim() }),
      };

      await uploadEmail(payload).unwrap();
      onClose();
    } catch (error) {
      console.error("Upload failed:", error);
    }
  };


  return (
    <ThemeProvider theme={theme}>
      <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>{t("email.uploadFiles")}</DialogTitle>
        <Stack direction="row" alignItems="center" spacing={2} sx={{ ml: 2 }}>
          <FormControlLabel
            sx={{ ml: 2 }}
            control={<Switch checked={groupFiles} onChange={(e) => setGroupFiles(e.target.checked)} />}
            label={t("email.groupMoreFilesInOneEmail")}
          />
        </Stack>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <Stack spacing={2}>
            <Box>
              <InputLabel htmlFor="file-input">
                <DropZone {...getRootProps()} hasFile={selectedFiles.length > 0} isActive={isDragActive}>
                  <input {...getInputProps()} />
                  <UploadFileOutlined sx={{ fontSize: 48, mb: 2 }} />
                  <Typography variant="h6" component="div" gutterBottom>
                    {t("fileUpload.dropFileHere")}
                  </Typography>
                  <Typography variant="body2">{t("fileUpload.orClickToSelect")}</Typography>
                  {selectedFiles.length > 0 && (
                    <Box mt={2}>
                      <Typography variant="body2">
                        {t("fileUpload.selectedFiles")}: {selectedFiles.length}
                      </Typography>
                    </Box>
                  )}
                </DropZone>
              </InputLabel>
            </Box>
            {!showAdditionalInfo && (
              <Stack alignItems="center">

                <SubtleLink
                  onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}
                  variant="body2"
                  sx={{ cursor: 'pointer' }}
                  type="button"
                >
                  {t("workflow.upload.addAdditionalInstructions")}
                </SubtleLink>
              </Stack>
            )}
            {showAdditionalInfo && (
              <TextField
                label={t("workflow.upload.additionalInstructions")}
                multiline
                rows={4}
                fullWidth
                variant="outlined"
                value={emailBody}
                onChange={(e) => setEmailBody(e.target.value)}
              />
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t("common.cancel")}</Button>
          <LoadingButton
            color="primary"
            variant="contained"
            loading={isLoading}
            onClick={handleUpload}
            disabled={selectedFiles.length === 0 && !emailBody.trim()}
          >
            {t("common.upload")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default FileUploadDialog;