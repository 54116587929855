import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useParams, Navigate, useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { JsonEditor } from 'json-edit-react'
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import RouteIcon from '@mui/icons-material/Route';
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { skipToken } from "@reduxjs/toolkit/query";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useCreateWorkflowMutation, useGetOrganizationsQuery, useGetWorkflowQuery, useUpdateWorkflowMutation } from "../../app/services/appApi";
import { IWorkflowCreate, IWorkflowRead, IWorkflowUpdate } from "@features/workflow/workflowTypes"
import ToolDocsDialog from "./ToolDocsDialog";
import { Container, FormControlLabel, MenuItem, Paper, Switch, Typography } from "@mui/material";
import { useAuthorization } from "@core/auth/authz/useAuthorization";

const generateRandomCode = () => {
    return Math.floor(100000 + Math.random() * 900000).toString();
};

const DEFAULT_CREATE_VALUES: IWorkflowCreate = {
    code: generateRandomCode(),
    title: "",
    subtitle: "",
    workflow_docs: "",
    ratelimit: 1000,
    ui_schema: {},
    extraction_schema: {},
    extraction_extra_context: "",
    name: "",
    organization_id: "",
    enable_register: false,
    memories: "",
    tool_config: {},
};

const cleanReadWorkflowDataForUpdate = (workflow: IWorkflowRead): IWorkflowUpdate => {
    const {
        id,
        code,
        title,
        subtitle,
        workflow_docs,
        ratelimit,
        name,
        created_at,
        updated_at,
        ui_schema,
        organization,
        extraction_extra_context,
        extraction_schema,
        enable_register,
        memories,
        tool_config,
    } = workflow;
    return {
        code,
        title,
        subtitle,
        workflow_docs,
        ratelimit,
        name,
        ui_schema,
        extraction_schema,
        extraction_extra_context,
        organization_id: organization.id,
        enable_register,
        memories: workflow.memories,
        tool_config,
    };
};

interface IWorkflowEditPageProps {
    workflowId?: IWorkflowRead["id"];
    render?: (props: { onClick: () => void }) => React.ReactNode;
}


/**
 * Dialog component for creating and editing workflows.
 * It can be opened in two modes:
 * 1. Create mode: workflowId is not given
 * 2. Edit mode: workflowId is given
 *
 * In edit mode, the user data is fetched from the API so
 * that the form can be pre-filled with the existing user data.
 */

const WorkflowEditPage: React.FC<IWorkflowEditPageProps> = () => {
    const { id } = useParams<{ id: string }>();
    const createMode = !id;
    const workflowId = id ? parseInt(id) : undefined;
    const [customName, setCustomName] = useState("");
    const [selectedValue, setSelectedValue] = useState("");

    const form = useForm<IWorkflowCreate | IWorkflowUpdate>();
    const { data } = useGetOrganizationsQuery({});
    const organizations = data?.results ?? [];
    const navigate = useNavigate();
    const { can } = useAuthorization();

    const { data: workflow, isLoading: isDataLoading } = useGetWorkflowQuery(
        workflowId ?? skipToken
    );

    const [triggerCreate, { isLoading: isCreateLoading }] = useCreateWorkflowMutation();
    const [triggerUpdate, { isLoading: isUpdateLoading }] = useUpdateWorkflowMutation();

    const isLoading = isCreateLoading || isUpdateLoading || isDataLoading;



    React.useEffect(() => {
        if (workflow && !createMode) {
            // Clean and set the existing workflow data
            const cleanedData = cleanReadWorkflowDataForUpdate(workflow);
            form.reset({
                ...cleanedData,
                ui_schema: cleanedData.ui_schema || {},
                extraction_schema: cleanedData.extraction_schema || {},
                tool_config: cleanedData.tool_config || {},
                organization_id: cleanedData.organization_id.toString(),
                ratelimit: cleanedData.ratelimit || 1000,
                enable_register: Boolean(cleanedData.enable_register),
                memories: cleanedData.memories || "",
                extraction_extra_context: cleanedData.extraction_extra_context || ""
            });

            setSelectedValue(cleanedData.name);
            if (!["agent_pipeline", "bellini_oda", "nte_doc_cls", "dynamic_pipeline", "dummy_pipeline"].includes(cleanedData.name)) {
                setSelectedValue("custom");
                setCustomName(cleanedData.name);
            }

        } else if (createMode) {
            form.reset({
                ...DEFAULT_CREATE_VALUES,
                ui_schema: {},
                extraction_schema: {},
                tool_config: {},
                ratelimit: 1000,
                enable_register: false
            });
        }
    }, [workflow, createMode, form]);

    if (isDataLoading && !createMode) {
        return (
            <Container>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    const handleClose = () => {
        navigate('/workflow');
    };

    const sendData = async (formData: IWorkflowCreate | IWorkflowUpdate) => {
        try {
            const processedData = {
                code: formData.code,
                title: formData.title,
                subtitle: formData.subtitle,
                workflow_docs: formData.workflow_docs || "",
                ratelimit: Number(formData.ratelimit),
                organization_id: formData.organization_id,
                name: formData.name,
                ui_schema: formData.ui_schema,
                extraction_schema: formData.extraction_schema,
                extraction_extra_context: formData.extraction_extra_context || "",
                enable_register: Boolean(formData.enable_register),
                memories: formData.memories || "",
                tool_config: formData.tool_config
            };

            const requiredFields = {
                code: "Codice",
                title: "Titolo",
                name: "Pipeline Name",
                organization_id: "Organizzazione",
                ratelimit: "Rate Limit"
            };

            for (const [field, label] of Object.entries(requiredFields)) {
                if (!processedData[field as keyof typeof processedData]) {
                    enqueueSnackbar(`Il campo ${label} è obbligatorio`, {
                        variant: "error",
                        autoHideDuration: 3000
                    });
                    return;
                }
            }

            if (createMode) {
                const response = await triggerCreate(processedData as IWorkflowCreate).unwrap();
                if (response) {
                    enqueueSnackbar("Workflow creata con successo", {
                        variant: "success",
                        autoHideDuration: 2000
                    });
                    setTimeout(() => {
                        handleClose();
                    }, 2000);
                }
            } else if (workflowId) {
                const response = await triggerUpdate({
                    id: workflowId,
                    ...processedData
                }).unwrap();
                if (response) {
                    enqueueSnackbar("Workflow aggiornata con successo", {
                        variant: "success",
                        autoHideDuration: 2000
                    });
                    setTimeout(() => {
                        handleClose();
                    }, 1000);
                }
            }
        } catch (error: any) {
            console.error('Errore durante il salvataggio:', error);

            // Handle specific API errors
            if (error.data?.detail) {
                enqueueSnackbar(`Errore: ${error.data.detail}`, {
                    variant: "error",
                    autoHideDuration: 4000
                });
            } else if (error.status === 422) {
                enqueueSnackbar("Errore di validazione dei dati", {
                    variant: "error",
                    autoHideDuration: 4000
                });
            } else {
                enqueueSnackbar("Si è verificato un errore durante il salvataggio", {
                    variant: "error",
                    autoHideDuration: 4000
                });
            }
        }
    };

    return (
        <Container
            maxWidth={false}
            sx={{
                height: '100vh',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                    width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: 'transparent',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#bdbdbd',
                    borderRadius: '4px',
                },
            }}
        >
            <Stack justifyContent="space-between" gap={2} direction="row" sx={{ mb: 2, alignItems: 'center', p: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <RouteIcon />
                    <Typography variant="h5" component="h1" noWrap>
                        <b>{createMode ? "Crea nuova workflow" : "Modifica workflow"}</b>
                    </Typography>
                </Box>
            </Stack>

            <Paper sx={{ p: 2, mx: 2, mb: 4 }}>
                {!isDataLoading ? (
                    <form onSubmit={form.handleSubmit(sendData)}>
                        <Stack gap={2}>
                            <Controller
                                name="code"
                                rules={{ required: true, maxLength: 6 }}
                                control={form.control}
                                defaultValue=""
                                render={({ field, fieldState }) => (
                                    <TextField
                                        label={"Code"}
                                        type="text"
                                        placeholder={"123456"}
                                        required
                                        error={!!fieldState.error}
                                        helperText={
                                            !!fieldState.error
                                                ? "Il campo deve contenere massimo 6 caratteri"
                                                : `Codice a 6 cifre es. 123456}`
                                        }
                                        {...field}
                                    />
                                )}
                            />

                            <Controller
                                name="title"
                                rules={{ required: true }}
                                control={form.control}
                                defaultValue=""
                                render={({ field, fieldState }) => (
                                    <TextField
                                        label={"Title"}
                                        type="text"
                                        placeholder="La mia workflow"
                                        fullWidth
                                        required
                                        error={!!fieldState.error}
                                        helperText={!!fieldState.error ? "Il campo è obbligatorio" : "Nome workflow da mostrare nella UI"}
                                        {...field}
                                    />
                                )}
                            />

                            <Controller
                                name="subtitle"
                                rules={{ required: true }}
                                control={form.control}
                                defaultValue=""
                                render={({ field, fieldState }) => (
                                    <TextField
                                        label={"Subtitle"}
                                        type="text"
                                        placeholder="Workflow che fa cose"
                                        fullWidth
                                        required
                                        error={!!fieldState.error}
                                        helperText={
                                            !!fieldState.error ? "Il campo è obbligatorio" : "Sottotitolo workflow da mostrare nella UI"
                                        }
                                        {...field}
                                    />
                                )}
                            />

                            <Controller
                                name="workflow_docs"
                                control={form.control}
                                defaultValue={workflow?.workflow_docs || ""}
                                render={({ field, fieldState }) => (
                                    <TextField
                                        label={"workflow_docs"}
                                        type="text"
                                        placeholder="Inserisci documentazione relativa all'organizzazione"
                                        fullWidth
                                        multiline
                                        minRows={2}
                                        maxRows={20}
                                        helperText={
                                            !!fieldState.error
                                                ? "Il campo deve contenere massimo 4096 caratteri"
                                                : "Opzionale. Specifica documentazione per agent. max 4096 caratteri"
                                        }
                                        {...field}
                                    />
                                )}
                            />

                            <Controller
                                name="organization_id"
                                rules={{ required: true }}
                                control={form.control}
                                defaultValue=""
                                render={({ field, fieldState }) => (
                                    <TextField
                                        select
                                        label="Organizzazione"
                                        fullWidth
                                        required
                                        error={!!fieldState.error}
                                        helperText={
                                            !!fieldState.error
                                                ? "Il campo è obbligatorio"
                                                : "Seleziona l'organizzazione a cui associare la workflow"
                                        }
                                        {...field}
                                    >
                                        <MenuItem value="" disabled>
                                            Seleziona un'organizzazione
                                        </MenuItem>
                                        {organizations?.map((org) => (
                                            <MenuItem key={org.id} value={org.id}>
                                                #{org.id} {org.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                            />
                            {can("view", "workflow", "advanced_fields") &&
                                <Controller
                                    name="ratelimit"
                                    rules={{ required: true }}
                                    control={form.control}
                                    defaultValue={1000}
                                    render={({ field, fieldState }) => (
                                        <TextField
                                            label={"Ratelimit mensile"}
                                            type="text"
                                            placeholder="1000"
                                            fullWidth
                                            required
                                            error={!!fieldState.error}
                                            helperText={!!fieldState.error && "Il campo è obbligatorio"}
                                            {...field}
                                        />
                                    )}
                                />
                            }

                            <Controller
                                name="extraction_extra_context"
                                control={form.control}
                                defaultValue=""
                                rules={{ maxLength: 50000 }}
                                render={({ field, fieldState }) => (
                                    <TextField
                                        label={"Prompt Context"}
                                        type="text"
                                        fullWidth
                                        multiline
                                        placeholder="Prompt context, insert every detail about your workflow"
                                        minRows={20}
                                        maxRows={40}
                                        error={!!fieldState.error}
                                        helperText={
                                            !!fieldState.error
                                                ? "Il campo deve contenere massimo 4096 caratteri"
                                                : "Opzionale. Per workflow generiche. Specifica il contesto dell'estrazione in markdown. max 50000 caratteri"
                                        }
                                        {...field}
                                    />
                                )}
                            />

                            <Controller
                                name="extraction_schema"
                                rules={{ required: false }}
                                control={form.control}
                                defaultValue={{}}
                                render={({ field, fieldState }) => (
                                    <Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                            <Box component="label" sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '12px' }}>
                                                Extraction Json Schema
                                            </Box>
                                            <ToolDocsDialog />
                                        </Box>
                                        <JsonEditor
                                            data={field.value}
                                            collapse={true}
                                            keySort={false}
                                            onEdit={({ newData }) => {
                                                field.onChange(newData);
                                                return true;
                                            }}
                                        />
                                        <Box component="span" sx={{ color: 'text.secondary', fontSize: '12px', mt: 1, display: 'block' }}>
                                            Opzionale. Per workflow generiche
                                        </Box>
                                    </Box>
                                )}
                            />

                            <Controller
                                name="ui_schema"
                                rules={{ required: false }}
                                control={form.control}
                                defaultValue={{}}
                                render={({ field, fieldState }) => (
                                    <Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                            <Box component="label" sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '12px' }}>
                                                UI Schema*
                                            </Box>
                                            <ToolDocsDialog />
                                        </Box>
                                        <JsonEditor
                                            data={field.value}
                                            collapse={true}
                                            keySort={false}
                                            onEdit={({ newData }) => {
                                                field.onChange(newData);
                                                return true;
                                            }}
                                        />
                                        <Box component="span" sx={{ color: 'text.secondary', fontSize: '12px', mt: 1, display: 'block' }}>
                                            Schema per determinare quali campi UI mostrare
                                        </Box>
                                    </Box>
                                )}
                            />

                            <Controller
                                name="memories"
                                control={form.control}
                                defaultValue=""
                                rules={{ maxLength: 50000 }}
                                render={({ field, fieldState }) => (
                                    <TextField
                                        label={"Memories"}
                                        type="text"
                                        fullWidth
                                        multiline
                                        placeholder="# Additional context"
                                        minRows={4}
                                        maxRows={22}
                                        error={!!fieldState.error}
                                        helperText={
                                            !!fieldState.error
                                                ? "Il campo deve contenere massimo 1024 caratteri"
                                                : "Opzionale. Possibilità di aggiungere delle note e memorie per la workflow. Il numero massimo di caratteri è 50000"
                                        }
                                        {...field}
                                    />
                                )}
                            />

                            <Controller
                                name="enable_register"
                                control={form.control}
                                defaultValue={false}
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        control={<Switch checked={value} onChange={onChange} />}
                                        label="Abilita connessione gestionale"
                                    />
                                )}
                            />

                            <Controller
                                name="name"
                                rules={{ required: true }}
                                control={form.control}
                                defaultValue=""
                                render={({ field, fieldState }) => (
                                    <>
                                        <TextField
                                            label="Pipeline Name"
                                            select
                                            fullWidth
                                            required
                                            error={!!fieldState.error}
                                            helperText={
                                                !!fieldState.error
                                                    ? "Il campo è obbligatorio"
                                                    : "Nome da passare nel create_workflow_by_name(...)"
                                            }
                                            {...field}
                                            value={selectedValue}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                setSelectedValue(value);
                                                if (value !== "custom") {
                                                    field.onChange(value);
                                                    setCustomName(""); // Resetta il campo personalizzato se si seleziona un'opzione predefinita
                                                }
                                            }}
                                        >
                                            <MenuItem value="" disabled>
                                                Seleziona un nome per la pipeline
                                            </MenuItem>
                                            <MenuItem value="agent_pipeline">agent_pipeline</MenuItem>
                                            <MenuItem value="bellini_oda">bellini_oda</MenuItem>
                                            <MenuItem value="nte_doc_cls">nte_doc_cls</MenuItem>
                                            <MenuItem value="dynamic_pipeline">dynamic_pipeline</MenuItem>
                                            <MenuItem value="dummy_pipeline">dummy_pipeline</MenuItem>
                                            <MenuItem value="custom">Inserisci il nome personalizzato...</MenuItem>
                                        </TextField>

                                        {selectedValue === "custom" && (
                                            <TextField
                                                label="Nome personalizzato"
                                                fullWidth
                                                required
                                                value={customName}
                                                onChange={(e) => {
                                                    setCustomName(e.target.value);
                                                    field.onChange(e.target.value); // Aggiorna il valore nel form
                                                }}
                                            />
                                        )}
                                    </>
                                )}
                            />

                            <Controller
                                name="tool_config"
                                rules={{ required: false }}
                                control={form.control}
                                defaultValue={{}}
                                render={({ field, fieldState }) => (
                                    <Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                            <Box component="label" sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '12px' }}>
                                                Tool Config*
                                            </Box>
                                            <ToolDocsDialog />
                                        </Box>
                                        <JsonEditor
                                            data={field.value}
                                            collapse={false}
                                            keySort={false}
                                            onEdit={({ newData }) => {
                                                field.onChange(newData);
                                                return true;
                                            }}
                                        />
                                        {fieldState.error && (
                                            <Box component="span" sx={{ color: 'error.main', fontSize: '12px', mt: 1 }}>
                                                {fieldState.error.message || "Invalid JSON format"}
                                            </Box>
                                        )}
                                        <Box component="span" sx={{ color: 'text.secondary', fontSize: '12px', mt: 1, display: 'block' }}>
                                            Sezione per configurare i tool accessibili dall'agent
                                        </Box>
                                    </Box>
                                )}
                            />
                        </Stack>

                        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                            <Button
                                startIcon={<CloseOutlinedIcon />}
                                onClick={() => navigate(-1)}
                            >
                                Annulla
                            </Button>
                            <LoadingButton
                                startIcon={<CheckOutlinedIcon />}
                                loading={isLoading}
                                disabled={isLoading}
                                variant="contained"
                                type="submit"
                            >
                                {createMode ? "Crea" : "Aggiorna"}
                            </LoadingButton>

                        </Box>
                    </form>
                ) : (
                    <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
                        <CircularProgress />
                    </Box>
                )}
            </Paper>
        </Container>
    );
};

export default WorkflowEditPage;