import DeleteIcon from "@mui/icons-material/Delete";
import MemoryIcon from "@mui/icons-material/Memory";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DownloadIcon from "@mui/icons-material/Download"; // Add this import
import { Menu, MenuItem } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuList from "@mui/material/MenuList";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDeleteEmailMutation, useReprocessEmailMutation } from "../../../app/services/appApi";

interface ITableRowActionsMenu {
  emailId: number;
  reprocessBtn: boolean;
  deleteBtn: boolean;
  isRegistered: boolean;
  rawEmlFilePresignedUrl: string | null;
}

export default function TableRowActionsMenu({
  emailId,
  reprocessBtn,
  deleteBtn,
  isRegistered,
  rawEmlFilePresignedUrl
}: ITableRowActionsMenu) {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [deleteEmail, { isLoading: isDelLoading, isError: isDelError, isSuccess: isDelSuccess }] =
    useDeleteEmailMutation();
  const [reprocessEmail, { isLoading: isReprLoading, isError: isReprError, isSuccess: isReprSuccess }] =
    useReprocessEmailMutation();

  useEffect(() => {
    if (isDelError) {
      enqueueSnackbar(t("email.deleteEmailFailure"), { variant: "error" });
    } else if (isDelSuccess) {
      enqueueSnackbar(t("email.deleteEmailSuccess"), { variant: "success" });
    }
  }, [isDelError, isDelSuccess, t]);

  useEffect(() => {
    if (isReprError) {
      enqueueSnackbar(t("email.reprocessEmailFailure"), { variant: "error" });
    } else if (isReprSuccess) {
      enqueueSnackbar(t("email.reprocessEmailSuccess"), { variant: "success" });
    }
  }, [isReprError, isReprSuccess, t]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadEml = () => {
    if (rawEmlFilePresignedUrl) {
      window.open(rawEmlFilePresignedUrl, '_blank');
    }
    handleMenuClose();
  };

  return (
    <>
      <GridActionsCellItem
        icon={<MoreVertIcon />}
        label={t("common.more")}
        className="actions"
        onClick={handleMenuOpen}
      />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuList>
          <MenuItem onClick={() => reprocessEmail(emailId)} disabled={isReprLoading || isRegistered || !reprocessBtn}>
            <ListItemIcon>
              <MemoryIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("email.reprocess")} />
          </MenuItem>
          <MenuItem onClick={handleDownloadEml} disabled={!rawEmlFilePresignedUrl}>
            <ListItemIcon>
              <DownloadIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("email.downloadEml")} />
          </MenuItem>
          <MenuItem onClick={() => deleteEmail(emailId)} disabled={isDelLoading || !deleteBtn}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("common.delete")} />
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
}