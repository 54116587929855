import React, { useState, useEffect, KeyboardEvent, ChangeEvent } from 'react';
import {
  Box,
  Paper,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  IconButton,
  FormControl,
  InputLabel,
  Divider,
  SelectChangeEvent,
} from '@mui/material';
import {
  AddCircle,
  Delete,
  CopyAll,
  ArrowUpward,
  ArrowDownward,
  Edit,
  Close,
} from '@mui/icons-material';

// Define a minimal JSONSchema type.
// You can extend this interface as needed.
interface JSONSchema {
  $schema?: string;
  type: string;
  properties?: Record<string, JSONSchema>;
  required?: string[];
  items?: JSONSchema;
  enum?: string[];
}

// Initial empty valid JSON Schema (draft-07 used here)
const initialSchema: JSONSchema = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  type: 'object',
  properties: {},
  required: [],
};

const JSONSchemaBuilder: React.FC = () => {
  // States are strongly typed.
  const [schema, setSchema] = useState<JSONSchema>(initialSchema);
  const [schemaOutput, setSchemaOutput] = useState<string>('');
  const [currentPath, setCurrentPath] = useState<string[]>([]);
  const [showAddField, setShowAddField] = useState<boolean>(false);
  const [newFieldName, setNewFieldName] = useState<string>('');
  const [newFieldType, setNewFieldType] = useState<string>('string');
  const [expandedNodes, setExpandedNodes] = useState<Record<string, boolean>>({});
  const [editingEnum, setEditingEnum] = useState<string | null>(null);

  // Update JSON output when schema changes
  useEffect(() => {
    setSchemaOutput(JSON.stringify(schema, null, 2));
  }, [schema]);

  // Get the current JSON schema section based on currentPath.
  // If the current section is an array of objects, return the inner object.
  const getCurrentSchemaSection = (): { current: JSONSchema; properties: Record<string, JSONSchema> | null } => {
    let current: JSONSchema = schema;
    for (const key of currentPath) {
      if (current.properties && current.properties[key]) {
        current = current.properties[key];
      } else if (current.type === 'array' && current.items && current.items.type === 'object') {
        // For array-of-object, switch to the inner object schema.
        current = current.items;
      }
    }
    const properties =
      current.type === 'object'
        ? current.properties || {}
        : current.type === 'array' && current.items && current.items.type === 'object'
          ? current.items.properties || {}
          : null;
    return { current, properties };
  };

  // Add a new field. When the current container is an array of objects, we add a key to the inner object.
  const addField = () => {
    if (!newFieldName.trim()) return;

    const fieldConfig: JSONSchema = { type: newFieldType };

    // Initialize proper structure based on type.
    if (newFieldType === 'object') {
      fieldConfig.properties = {};
      fieldConfig.required = [];
    } else if (newFieldType === 'array') {
      fieldConfig.items = { type: 'string' };
    } else if (newFieldType === 'enum') {
      fieldConfig.type = 'string';
      fieldConfig.enum = ['option1', 'option2'];
    }

    // Make a deep clone of the schema (shallow clone is enough in this case)
    const newSchema = { ...schema };

    // Navigate into the target container in newSchema using currentPath
    let target: any = newSchema; // using any for traversing nested objects
    for (const key of currentPath) {
      if (target.properties && target.properties[key]) {
        target = target.properties[key];
      } else if (
        target.type === 'array' &&
        target.items &&
        target.items.type === 'object'
      ) {
        target = target.items;
      }
    }

    // If target is an array of objects, work with the inner object.
    if (target.type === 'array' && target.items && target.items.type === 'object') {
      target = target.items;
    }

    if (target.type === 'object') {
      if (!target.properties) target.properties = {};
      target.properties[newFieldName] = fieldConfig;
      if (!target.required) target.required = [];
    }

    setSchema(newSchema);
    setNewFieldName('');
    setShowAddField(false);
    // Auto-expand newly added nodes.
    const newPath = [...currentPath, newFieldName].join('.');
    setExpandedNodes({ ...expandedNodes, [newPath]: true });
  };

  const deleteField = (fieldName: string) => {
    const newSchema = { ...schema };
    let target: any = newSchema;
    for (const key of currentPath) {
      if (target.properties && target.properties[key]) {
        target = target.properties[key];
      } else if (
        target.type === 'array' &&
        target.items &&
        target.items.type === 'object'
      ) {
        target = target.items;
      }
    }
    if (currentPath.length === 0) {
      if (newSchema.properties) {
        delete newSchema.properties[fieldName];
      }
      if (newSchema.required) {
        newSchema.required = newSchema.required.filter(name => name !== fieldName);
      }
    } else {
      if (target.properties) {
        delete target.properties[fieldName];
        if (target.required) {
          target.required = target.required.filter((name: string) => name !== fieldName);
        }
      }
    }
    setSchema(newSchema);
  };

  const toggleRequired = (fieldName: string) => {
    const newSchema = { ...schema };
    let target: any = newSchema;
    for (const key of currentPath) {
      if (target.properties && target.properties[key]) {
        target = target.properties[key];
      } else if (
        target.type === 'array' &&
        target.items &&
        target.items.type === 'object'
      ) {
        target = target.items;
      }
    }
    if (currentPath.length === 0) {
      if (newSchema.required && newSchema.required.includes(fieldName)) {
        newSchema.required = newSchema.required.filter(name => name !== fieldName);
      } else {
        newSchema.required = newSchema.required ? [...newSchema.required, fieldName] : [fieldName];
      }
    } else {
      if (!target.required) target.required = [];
      if (target.required.includes(fieldName)) {
        target.required = target.required.filter((name: string) => name !== fieldName);
      } else {
        target.required.push(fieldName);
      }
    }
    setSchema(newSchema);
  };

  const navigateTo = (fieldName: string) => {
    const newPath = [...currentPath, fieldName];
    setCurrentPath(newPath);
    setExpandedNodes({ ...expandedNodes, [newPath.join('.')]: true });
  };

  const navigateUp = () => {
    if (currentPath.length > 0) {
      setCurrentPath(currentPath.slice(0, -1));
    }
  };

  const moveFieldUp = (fieldName: string) => {
    const newSchema = { ...schema };
    let target: any = newSchema;
    for (const key of currentPath) {
      if (target.properties && target.properties[key]) {
        target = target.properties[key];
      } else if (
        target.type === 'array' &&
        target.items &&
        target.items.type === 'object'
      ) {
        target = target.items;
      }
    }
    const properties = currentPath.length === 0 ? newSchema.properties : target.properties;
    if (!properties) return;
    const keys = Object.keys(properties);
    const index = keys.indexOf(fieldName);
    if (index > 0) {
      const newProperties: Record<string, JSONSchema> = {};
      const prevKey = keys[index - 1];
      for (let i = 0; i < keys.length; i++) {
        if (i === index - 1) {
          newProperties[fieldName] = properties[fieldName];
        } else if (i === index) {
          newProperties[prevKey] = properties[prevKey];
        } else {
          newProperties[keys[i]] = properties[keys[i]];
        }
      }
      if (currentPath.length === 0) {
        newSchema.properties = newProperties;
      } else {
        target.properties = newProperties;
      }
      setSchema(newSchema);
    }
  };

  const moveFieldDown = (fieldName: string) => {
    const newSchema = { ...schema };
    let target: any = newSchema;
    for (const key of currentPath) {
      if (target.properties && target.properties[key]) {
        target = target.properties[key];
      } else if (
        target.type === 'array' &&
        target.items &&
        target.items.type === 'object'
      ) {
        target = target.items;
      }
    }
    const properties = currentPath.length === 0 ? newSchema.properties : target.properties;
    if (!properties) return;
    const keys = Object.keys(properties);
    const index = keys.indexOf(fieldName);
    if (index < keys.length - 1) {
      const newProperties: Record<string, JSONSchema> = {};
      const nextKey = keys[index + 1];
      for (let i = 0; i < keys.length; i++) {
        if (i === index) {
          newProperties[nextKey] = properties[nextKey];
        } else if (i === index + 1) {
          newProperties[fieldName] = properties[fieldName];
        } else {
          newProperties[keys[i]] = properties[keys[i]];
        }
      }
      if (currentPath.length === 0) {
        newSchema.properties = newProperties;
      } else {
        target.properties = newProperties;
      }
      setSchema(newSchema);
    }
  };

  const duplicateField = (fieldName: string) => {
    const newSchema = { ...schema };
    let target: any = newSchema;
    for (const key of currentPath) {
      if (target.properties && target.properties[key]) {
        target = target.properties[key];
      } else if (
        target.type === 'array' &&
        target.items &&
        target.items.type === 'object'
      ) {
        target = target.items;
      }
    }
    const properties = currentPath.length === 0 ? newSchema.properties : target.properties;
    if (!properties) return;
    let newFieldNameCopy = `${fieldName}Copy`;
    let counter = 1;
    while (properties[newFieldNameCopy]) {
      newFieldNameCopy = `${fieldName}Copy${counter}`;
      counter++;
    }
    properties[newFieldNameCopy] = JSON.parse(JSON.stringify(properties[fieldName]));
    setSchema(newSchema);
  };

  const addEnumValue = (fieldName: string, value: string) => {
    if (!value.trim()) return;
    const newSchema = { ...schema };
    let target: any = newSchema;
    for (const key of currentPath) {
      if (target.properties && target.properties[key]) {
        target = target.properties[key];
      } else if (
        target.type === 'array' &&
        target.items &&
        target.items.type === 'object'
      ) {
        target = target.items;
      }
    }
    const properties = currentPath.length === 0 ? newSchema.properties : target.properties;
    if (properties && properties[fieldName]?.enum && !properties[fieldName].enum.includes(value)) {
      properties[fieldName].enum.push(value);
    }
    setSchema(newSchema);
  };

  const removeEnumValue = (fieldName: string, index: number) => {
    const newSchema = { ...schema };
    let target: any = newSchema;
    for (const key of currentPath) {
      if (target.properties && target.properties[key]) {
        target = target.properties[key];
      } else if (
        target.type === 'array' &&
        target.items &&
        target.items.type === 'object'
      ) {
        target = target.items;
      }
    }
    const properties = currentPath.length === 0 ? newSchema.properties : target.properties;
    if (properties && properties[fieldName]?.enum) {
      properties[fieldName].enum.splice(index, 1);
    }
    setSchema(newSchema);
  };

  const updateArrayItemsType = (fieldName: string, itemType: string) => {
    const newSchema = { ...schema };
    let target: any = newSchema;
    for (const key of currentPath) {
      if (target.properties && target.properties[key]) {
        target = target.properties[key];
      } else if (
        target.type === 'array' &&
        target.items &&
        target.items.type === 'object'
      ) {
        target = target.items;
      }
    }
    const properties = currentPath.length === 0 ? newSchema.properties : target.properties;
    if (properties) {
      if (itemType === 'object') {
        properties[fieldName].items = { type: 'object', properties: {}, required: [] };
      } else {
        properties[fieldName].items = { type: itemType };
      }
    }
    setSchema(newSchema);
  };

  const renderFields = () => {
    const { properties } = getCurrentSchemaSection();
    if (!properties) return null;
    return Object.keys(properties).map((fieldName) => {
      const field = properties[fieldName];
      // Determine if required.
      let isRequired = false;
      if (currentPath.length === 0) {
        isRequired = schema.required?.includes(fieldName) ?? false;
      } else {
        const { current } = getCurrentSchemaSection();
        isRequired = current.required ? current.required.includes(fieldName) : false;
      }
      const nodePath = [...currentPath, fieldName].join('.');
      const isEditing = editingEnum === fieldName;

      return (
        <Paper key={fieldName} variant="outlined" sx={{ p: 2, mb: 2 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="subtitle1" fontWeight="bold">{fieldName}</Typography>
              <Typography variant="caption" color="text.secondary">
                ({field.type}{field.enum ? ' enum' : ''})
              </Typography>
              {isRequired && (
                <Typography variant="caption" sx={{ bgcolor: 'primary.light', px: 1, py: 0.5, borderRadius: 1 }}>
                  Required
                </Typography>
              )}
            </Box>
            <Box>
              {field.type === 'object' && (
                <IconButton size="small" onClick={() => navigateTo(fieldName)}>
                  <Edit fontSize="small" />
                </IconButton>
              )}
              {field.type === 'array' && (
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography variant="caption">Items:</Typography>
                  <FormControl variant="standard" size="small">
                    <Select
                      value={field.items?.type ?? 'string'}
                      onChange={(e: SelectChangeEvent<string>) =>
                        updateArrayItemsType(fieldName, e.target.value as string)
                      }
                    >
                      <MenuItem value="string">String</MenuItem>
                      <MenuItem value="number">Number</MenuItem>
                      <MenuItem value="boolean">Boolean</MenuItem>
                      <MenuItem value="object">Object</MenuItem>
                    </Select>
                  </FormControl>
                  {field.items?.type === 'object' && (
                    <Button variant="outlined" size="small" onClick={() => navigateTo(fieldName)}>
                      Edit
                    </Button>
                  )}
                </Box>
              )}
              {field.enum && (
                <Button size="small" onClick={() => setEditingEnum(isEditing ? null : fieldName)}>
                  {isEditing ? 'Done' : 'Edit Values'}
                </Button>
              )}
              <Button size="small" onClick={() => toggleRequired(fieldName)}>
                {isRequired ? 'Optional' : 'Required'}
              </Button>
              <IconButton size="small" onClick={() => moveFieldUp(fieldName)}>
                <ArrowUpward fontSize="small" />
              </IconButton>
              <IconButton size="small" onClick={() => moveFieldDown(fieldName)}>
                <ArrowDownward fontSize="small" />
              </IconButton>
              <IconButton size="small" onClick={() => duplicateField(fieldName)}>
                <CopyAll fontSize="small" />
              </IconButton>
              <IconButton size="small" onClick={() => deleteField(fieldName)}>
                <Delete fontSize="small" color="error" />
              </IconButton>
            </Box>
          </Box>
          {isEditing && field.enum && (
            <Box pl={2} mt={2} borderLeft={1} borderColor="grey.300">
              <Typography variant="body2" color="text.secondary" mb={1}>
                Allowed Values:
              </Typography>
              <Box display="flex" flexWrap="wrap" gap={1} mb={2}>
                {field.enum.map((value: string, index: number) => (
                  <Box key={index} display="flex" alignItems="center" sx={{ bgcolor: 'grey.100', p: 0.5, borderRadius: 1 }}>
                    <Typography variant="body2">{value}</Typography>
                    <IconButton size="small" onClick={() => removeEnumValue(fieldName, index)}>
                      <Close fontSize="small" />
                    </IconButton>
                  </Box>
                ))}
              </Box>
              <Box display="flex" alignItems="center" gap={1}>
                <TextField
                  placeholder="Add new value"
                  size="small"
                  onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter') {
                      addEnumValue(fieldName, (e.target as HTMLInputElement).value);
                      (e.target as HTMLInputElement).value = '';
                    }
                  }}
                />
                <Button
                  variant="contained"
                  size="small"
                  onClick={(e) => {
                    const input = (e.target as HTMLElement).previousElementSibling as HTMLInputElement;
                    addEnumValue(fieldName, input.value);
                    input.value = '';
                  }}
                >
                  Add
                </Button>
              </Box>
            </Box>
          )}
        </Paper>
      );
    });
  };

  return (
    <Box sx={{ p: 3, minHeight: '100vh', bgcolor: 'grey.100' }}>
      <Typography variant="h4" gutterBottom>
        JSON Schema Visual Builder
      </Typography>
      <Box display="flex" flexDirection={{ xs: 'column', lg: 'row' }} gap={3}>
        <Paper sx={{ flex: 1, p: 3 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h6">Schema Structure</Typography>
            {currentPath.length > 0 && (
              <Button variant="outlined" onClick={navigateUp}>
                ↩ Back
              </Button>
            )}
          </Box>
          <Typography variant="caption" display="block" mb={2}>
            Path: /{currentPath.join('/')}
          </Typography>
          <Divider sx={{ mb: 2 }} />
          {renderFields()}
          <Paper
            variant="outlined"
            sx={{
              p: 2,
              mb: 2,
              borderStyle: 'dashed',
              bgcolor: 'grey.50',
              cursor: 'pointer',
            }}
            onClick={() => setShowAddField(true)}
          >
            {!showAddField ? (
              <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                <AddCircle />
                <Typography>Add New Field</Typography>
              </Box>
            ) : (
              <Box display="flex" alignItems="center" gap={2}>
                <TextField
                  label="Field Name"
                  variant="outlined"
                  size="small"
                  value={newFieldName}
                  onChange={(e) => setNewFieldName(e.target.value)}
                />
                <FormControl variant="outlined" size="small">
                  <InputLabel>Type</InputLabel>
                  <Select
                    label="Type"
                    value={newFieldType}
                    onChange={(e) => setNewFieldType(e.target.value as string)}
                    sx={{ width: 120 }}
                  >
                    <MenuItem value="string">String</MenuItem>
                    <MenuItem value="number">Number</MenuItem>
                    <MenuItem value="boolean">Boolean</MenuItem>
                    <MenuItem value="object">Object</MenuItem>
                    <MenuItem value="array">Array</MenuItem>
                    <MenuItem value="enum">Enum</MenuItem>
                  </Select>
                </FormControl>
                <Button variant="contained" onClick={addField}>
                  Add
                </Button>
                <Button variant="outlined" onClick={() => setShowAddField(false)}>
                  Cancel
                </Button>
              </Box>
            )}
          </Paper>
        </Paper>
        <Paper sx={{ flex: 1, p: 3 }}>
          <Typography variant="h6" gutterBottom>
            JSON Schema Output
          </Typography>
          <Paper variant="outlined" sx={{ p: 2, bgcolor: 'grey.50', height: 400, overflow: 'auto' }}>
            <Box component="pre" sx={{ typography: 'body2', whiteSpace: 'pre-wrap' }}>
              {schemaOutput}
            </Box>
          </Paper>
          <Box mt={2}>
            <Button
              variant="outlined"
              onClick={() => navigator.clipboard.writeText(schemaOutput)}
            >
              Copy to Clipboard
            </Button>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default JSONSchemaBuilder;