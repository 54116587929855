import { baseQueryWithLogout } from "@core/auth/authBaseQuery";
import { IOrganizationCreate, IOrganizationRead, IOrganizationUpdate, IUserRead } from "@core/auth/authTypes";
import { SyncStatus } from "@features/Sync/syncTypes";
import { INteItem } from "@features/email/emailDetailPage/tabs/plugins/nteCode88Plugin";
import { IWorkflowCreate, IWorkflowRead, IWorkflowUpdate } from "@features/workflow/workflowTypes";
import { createApi } from "@reduxjs/toolkit/query/react";
import { IAddressRecord, IAddressRequest } from "../../features/address/addressTypes";
import { IBenchmarkReportListItem, IBenchmarkReportRecord } from "../../features/benchmark/benchmarkTypes";
import { IContactRecord, IContactRequest } from "../../features/contact/contactTypes";
import { IDebugResponse } from "../../features/debug/debugTypes";
import { IEmailNavRequest, IEmailRead, IEmailUpdate } from "../../features/email/emailTypes";
import { IAutocompleteRequest, IAutocompleteResponse } from "../../features/input/select/ServerSideSearchSelect2";
import { IOfferRecord } from "../../features/offer/offerTypes";
import { IOrderRecord } from "../../features/order/orderTypes";
import { IProductRecord, IProductRequest } from "../../features/product/productTypes";
import { SheetImportStatus } from "../../features/sheet_import/sheetImportTypes";
import { IListResponse, ISearchRequest } from "./apiTypes";

// Helper function to create query params
const createQueryParams = (params: Record<string, any>): string => {
  const queryParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined && value !== "") {
      queryParams.append(key, value.toString());
    }
  });
  return queryParams.toString();
};

export const appApi = createApi({
  reducerPath: "appApi",
  baseQuery: baseQueryWithLogout,
  tagTypes: [
    // Auth api
    "USER",
    "AUTH_CONTEXT",
    // Other
    "EMAIL",
    "PRODUCT",
    "CONTACT",
    "WORKFLOW",
    "BENCHMARK",
    "ORGANIZATION",
    "ADDRESS",
    "OFFER",
    "ORDER",
    "NTE_CODE88",
  ],

  endpoints: (builder) => ({
    getEmail: builder.query<IEmailRead, number>({
      query: (id) => `/email/${id}`,
      providesTags: ["EMAIL"],
    }),

    generateCsv: builder.mutation<{ content: string }, number[]>({
      query: (emailIds) => ({
        url: `/email/csv?ids=${emailIds.join(",")}`,
        method: "POST",
      }),
    }),

    getEmails: builder.query<IListResponse<IEmailRead>, ISearchRequest>({
      query: ({ page, pageSize }) => `/email?page=${page}&page_size=${pageSize}`,
      providesTags: ["EMAIL"],
    }),

    getWorkflowEmails: builder.query<IListResponse<IEmailRead>, { workflowId: number } & ISearchRequest>({
      query: ({ workflowId, page, pageSize, search }) => {
        const queryParams = new URLSearchParams();
        if (page !== undefined) {
          queryParams.append("page", page.toString());
        }
        if (pageSize !== undefined) {
          queryParams.append("page_size", pageSize.toString());
        }
        if (search !== undefined && search !== "") {
          queryParams.append("search", search);
        }
        return `/workflow/${workflowId}/email?${queryParams.toString()}`;
      },
      providesTags: ["EMAIL", "WORKFLOW"],
    }),

    patchEmail: builder.mutation<IEmailRead, { id: number; body: Partial<IEmailUpdate> }>({
      query: ({ id, body }) => ({
        url: `/email/${id}`,
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: ["EMAIL"],
    }),

    certifyEmail: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/email/${id}/certify`,
        method: "POST",
      }),
      invalidatesTags: ["EMAIL"],
    }),

    registerEmail: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/email/${id}/register`,
        method: "POST",
      }),
      invalidatesTags: ["EMAIL"],
    }),

    reprocessEmail: builder.mutation<void, number>({
      query: (emailId) => ({
        url: `/email/${emailId}/reprocess`,
        method: "POST",
      }),
      invalidatesTags: ["EMAIL"],
    }),

    getEmailNavigation: builder.query<IEmailNavRequest, number>({
      query: (emailId) => `/email/navigation?email_id=${emailId}`,
      providesTags: ["EMAIL"],
    }),

    deleteEmail: builder.mutation<void, number>({
      query: (emailId) => ({
        url: `/email/${emailId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["EMAIL"],
    }),

    uploadEmail: builder.mutation<
      {
        message: string;
        email_id?: string;
        task_id?: string;
        results?: Array<{ email_id: string; task_id: string }>;
      },
      {
        workflowId: number;
        files?: FormData;
        group?: boolean;
        body?: string;
      }
    >({
      query: ({ workflowId, files, group = false, body }) => {
        const formData = new FormData();

        if (!files && !body) {
          throw new Error("Either files or body must be provided");
        }

        if (body) {
          formData.append("body", body);
        }

        if (files) {
          const fileEntries = files.getAll("files");
          fileEntries.forEach((file) => {
            formData.append("files", file);
          });
        }

        return {
          url: `/email/upload?id=${workflowId}${files && group ? `&group=${group}` : ""}`,
          method: "POST",
          body: formData,
          formData: true,
        };
      },
      invalidatesTags: ["EMAIL"],
    }),

    debugInfo: builder.query<IDebugResponse, void>({
      query: () => `/debug`,
    }),

    uploadFile: builder.mutation<
      { status: string; message: string },
      { recordType: "products" | "contacts"; file: File }
    >({
      query: ({ recordType, file }) => {
        let formData = new FormData();
        formData.append("file", file);
        return {
          url: `/${recordType === "contacts" ? "contact" : "product"}/batch`,
          method: "POST",
          body: formData,
          formData: true,
        };
      },
      invalidatesTags: ["PRODUCT", "CONTACT"],
    }),
    //
    // Products
    //

    getProducts: builder.query<IListResponse<IProductRecord>, ISearchRequest>({
      query: ({ page, pageSize, search }) => {
        const queryParams = new URLSearchParams();
        if (page !== undefined) {
          queryParams.append("page", page.toString());
        }
        if (pageSize !== undefined) {
          queryParams.append("page_size", pageSize.toString());
        }
        if (search !== undefined && search !== "") {
          queryParams.append("search", search);
        }
        return `/product?${queryParams.toString()}`;
      },
      providesTags: ["PRODUCT"],
    }),

    getProduct: builder.query<IProductRecord, IProductRecord["id"]>({
      query: (productId) => `/product/${productId}`,
      providesTags: ["PRODUCT"],
    }),

    createProduct: builder.mutation<IProductRecord, IProductRequest>({
      query: (product) => ({
        url: `/product`,
        method: "POST",
        body: product,
      }),
      invalidatesTags: ["PRODUCT"],
    }),

    updateProduct: builder.mutation<IProductRecord, IProductRequest & { id: IProductRecord["id"] }>({
      query: ({ id, ...product }) => ({
        url: `/product/${id}`,
        method: "PUT",
        body: product,
      }),
      invalidatesTags: ["PRODUCT"],
    }),

    deleteProduct: builder.mutation<void, Pick<IProductRecord, "id">>({
      query: (productId) => ({
        url: `/product/${productId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PRODUCT"],
    }),

    //
    // Addresses
    //

    getAddresses: builder.query<IListResponse<IAddressRecord>, ISearchRequest & IAddressRequest>({
      query: ({ page, pageSize, search }) => {
        const queryParams = new URLSearchParams();
        if (page !== undefined) {
          queryParams.append("page", page.toString());
        }
        if (pageSize !== undefined) {
          queryParams.append("page_size", pageSize.toString());
        }
        if (search !== undefined && search !== "") {
          queryParams.append("search", search);
        }
        return `/address?${queryParams.toString()}`;
      },
      providesTags: ["ADDRESS"],
    }),

    getAddress: builder.query<IAddressRecord, IAddressRecord["id"]>({
      query: (addressId) => `/address/${addressId}`,
      providesTags: ["ADDRESS"],
    }),

    createAddress: builder.mutation<IAddressRecord, IAddressRequest>({
      query: (address) => ({
        url: "/address",
        method: "POST",
        body: address,
      }),
      invalidatesTags: ["ADDRESS"],
    }),

    updateAddress: builder.mutation<IAddressRecord, IAddressRequest & { id: IAddressRecord["id"] }>({
      query: ({ id, ...address }) => ({
        url: `/address/${id}`,
        method: "PUT",
        body: address,
      }),
      invalidatesTags: ["ADDRESS"],
    }),

    deleteAddress: builder.mutation<void, Pick<IAddressRecord, "id">>({
      query: (addressId) => ({
        url: `/address/${addressId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ADDRESS"],
    }),

    //
    // Workflows
    //
    getWorkflows: builder.query<IListResponse<IWorkflowRead>, { show_all?: boolean } & ISearchRequest>({
      query: (props) => {
        return `/workflow?show_all=${props.show_all ? "true" : "false"}`;
      },
      providesTags: ["WORKFLOW"],
    }),

    getWorkflowsByTitle: builder.query<
      IListResponse<IWorkflowRead>,
      { page: number; pageSize: number; search: string; show_all?: boolean }
    >({
      query: ({ page, pageSize, search, show_all }) => {
        const queryParams = new URLSearchParams();
        if (page !== undefined) {
          queryParams.append("page", page.toString());
        }
        if (pageSize !== undefined) {
          queryParams.append("page_size", pageSize.toString());
        }
        if (search !== undefined && search !== "") {
          queryParams.append("search", search);
        }
        return `/workflow?show_all=${show_all ? "true" : "false"}&${queryParams.toString()}`;
      },
      providesTags: ["WORKFLOW"],
    }),

    getWorkflow: builder.query<IWorkflowRead, IWorkflowRead["id"]>({
      query: (workflowId) => `/workflow/${workflowId}`,
      providesTags: ["WORKFLOW"],
    }),

    createWorkflow: builder.mutation<IWorkflowRead, IWorkflowCreate>({
      query: (workflow) => ({
        url: `/workflow`,
        method: "POST",
        body: workflow,
      }),
      invalidatesTags: ["WORKFLOW"],
    }),

    updateWorkflow: builder.mutation<IWorkflowRead, IWorkflowUpdate & { id: IWorkflowRead["id"] }>({
      query: ({ id, ...workflow }) => ({
        url: `/workflow/${id}`,
        method: "PUT",
        body: workflow,
      }),
      invalidatesTags: ["WORKFLOW"],
    }),

    deleteWorkflow: builder.mutation<void, IWorkflowRead["id"]>({
      query: (workflowId) => ({
        url: `/workflow/${workflowId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["WORKFLOW"],
    }),

    duplicateWorkflow: builder.mutation<IWorkflowRead, IWorkflowRead["id"]>({
      query: (workflowId) => ({
        url: `/workflow/${workflowId}/duplicate`,
        method: "POST",
      }),
      invalidatesTags: ["WORKFLOW"],
    }),

    //
    // Contacts
    //

    getContacts: builder.query<IListResponse<IContactRecord>, ISearchRequest>({
      query: ({ page, pageSize, search }) => {
        const queryParams = new URLSearchParams();
        if (page !== undefined) {
          queryParams.append("page", page.toString());
        }
        if (pageSize !== undefined) {
          queryParams.append("page_size", pageSize.toString());
        }
        if (search !== undefined && search !== "") {
          queryParams.append("search", search);
        }
        return `/contact?${queryParams.toString()}`;
      },
      providesTags: ["CONTACT"],
    }),

    getContact: builder.query<IContactRecord, IContactRecord["id"]>({
      query: (contactId) => `/contact/${contactId}`,
      providesTags: ["CONTACT"],
    }),

    createContact: builder.mutation<IContactRecord, IContactRequest>({
      query: (contact) => ({
        url: `/contact`,
        method: "POST",
        body: contact,
      }),
      invalidatesTags: ["CONTACT"],
    }),

    updateContact: builder.mutation<IContactRecord, IContactRequest & { id: IContactRecord["id"] }>({
      query: ({ id, ...contact }) => ({
        url: `/contact/${id}`,
        method: "PUT",
        body: contact,
      }),
      invalidatesTags: ["CONTACT"],
    }),

    deleteContact: builder.mutation<void, Pick<IContactRecord, "id">>({
      query: (contactId) => ({
        url: `/contact/${contactId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["CONTACT"],
    }),

    getAutocomplete: builder.query<IAutocompleteResponse, { searchModel: string; params: IAutocompleteRequest }>({
      query: ({ searchModel, params }) => {
        const queryString = new URLSearchParams(Object.entries(params).filter(([_, v]) => v !== undefined)).toString();

        if (searchModel.startsWith("org::")) {
          const [, modelName] = searchModel.split("::");
          return `/organization/custom-autocomplete?model=${modelName}&${queryString}`;
        } else {
          return `/${searchModel}/autocomplete?${queryString}`;
        }
      },
      providesTags: ["PRODUCT", "CONTACT"],
    }),

    triggerSync: builder.mutation<{ message: string; status: string; task_id: string }, void>({
      query: () => ({
        url: `/organization/sync`,
        method: "POST",
      }),
      invalidatesTags: ["PRODUCT", "CONTACT"],
    }),

    getSyncStatus: builder.query<SyncStatus, { task_id: string }>({
      query: ({ task_id }) => `/organization/sync?task_id=${task_id}`,
    }),

    startLogStreaming: builder.mutation<{ status: string; message: string }, void>({
      query: () => ({
        url: "/organization/gateway/start_log_streaming",
        method: "POST",
      }),
    }),

    stopLogStreaming: builder.mutation<{ status: string; message: string }, void>({
      query: () => ({
        url: "/organization/gateway/stop_log_streaming",
        method: "POST",
      }),
    }),

    getLogs: builder.query<{ status: string; logs: Array<{ timestamp: string; message: string }> }, void>({
      query: () => "/organization/gateway/get_logs",
    }),

    getBenchmarkReports: builder.query<IListResponse<IBenchmarkReportListItem>, ISearchRequest>({
      query: ({ page, pageSize, search }) => {
        const queryParams = new URLSearchParams();
        if (page !== undefined) {
          queryParams.append("page", page.toString());
        }
        if (pageSize !== undefined) {
          queryParams.append("page_size", pageSize.toString());
        }
        if (search !== undefined && search !== "") {
          queryParams.append("search", search);
        }
        return `/benchmark?${queryParams.toString()}`;
      },
      providesTags: ["BENCHMARK"],
    }),

    getBenchmarkReport: builder.query<IBenchmarkReportRecord, number>({
      query: (id) => `/benchmark/${id}`,
      providesTags: ["BENCHMARK"],
    }),

    createBenchmark: builder.mutation<void, { ids: number[]; recompute: boolean }>({
      query: ({ ids, recompute }) => ({
        url: `/benchmark?recompute=${recompute}`,
        method: "POST",
        body: { ids },
      }),
      invalidatesTags: ["BENCHMARK"],
    }),

    deleteBenchmarkReport: builder.mutation<void, number>({
      query: (id) => ({
        url: `/benchmark/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["BENCHMARK"],
    }),

    updateBenchmarkNote: builder.mutation<void, { id: number; note: string }>({
      query: ({ id, note }) => ({
        url: `/benchmark/${id}`,
        method: "PATCH",
        body: { note },
      }),
      invalidatesTags: ["BENCHMARK"],
    }),

    //
    // Organizations
    //
    getOrganizations: builder.query<IListResponse<IOrganizationRead>, ISearchRequest>({
      query: () => {
        const queryParams = new URLSearchParams();
        return `/organization?${queryParams.toString()}`;
      },
      providesTags: ["ORGANIZATION"],
    }),

    getOrganization: builder.query<IOrganizationRead, IOrganizationRead["id"]>({
      query: (organizationId) => `/organization/${organizationId}`,
      providesTags: ["ORGANIZATION"],
    }),

    getOrganizationUsers: builder.query<IUserRead[], IOrganizationRead["id"]>({
      query: (organizationId) => `/organization/${organizationId}/users`,
    }),

    createOrganization: builder.mutation<IOrganizationRead, IOrganizationCreate>({
      query: (organization) => ({
        url: `/organization`,
        method: "POST",
        body: organization,
      }),
      invalidatesTags: ["ORGANIZATION"],
    }),

    updateOrganization: builder.mutation<IOrganizationRead, IOrganizationUpdate & { id: IOrganizationRead["id"] }>({
      query: ({ id, ...organization }) => ({
        url: `/organization/${id}`,
        method: "PUT",
        body: organization,
      }),
      invalidatesTags: ["ORGANIZATION"],
    }),

    deleteOrganization: builder.mutation<void, IOrganizationRead["id"]>({
      query: (organizationId) => ({
        url: `/organization/${organizationId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ORGANIZATION"],
    }),

    //
    //Offer
    //
    getOffers: builder.query<IListResponse<IOfferRecord>, ISearchRequest & { contact_id?: string }>({
      query: ({ page, pageSize, search, contact_id }) => {
        const queryParams = new URLSearchParams();
        if (page !== undefined) {
          queryParams.append("page", page.toString());
        }
        if (pageSize !== undefined) {
          queryParams.append("page_size", pageSize.toString());
        }
        if (search !== undefined && search !== "") {
          queryParams.append("search", search);
        }
        if (contact_id !== undefined) {
          queryParams.append("contact_id", contact_id);
        }
        return `/offer?${queryParams.toString()}`;
      },
      providesTags: ["OFFER"],
    }),

    getOffer: builder.query<IOfferRecord, IOfferRecord["id"]>({
      query: (offertId) => `/offer/${offertId}`,
      providesTags: ["OFFER"],
    }),

    //
    //Orders
    //
    getOrders: builder.query<IListResponse<IOrderRecord>, ISearchRequest>({
      query: ({ page, pageSize, search }) => {
        const queryParams = new URLSearchParams();
        if (page !== undefined) {
          queryParams.append("page", page.toString());
        }
        if (pageSize !== undefined) {
          queryParams.append("page_size", pageSize.toString());
        }
        if (search !== undefined && search !== "") {
          queryParams.append("search", search);
        }
        return `/order?${queryParams.toString()}`;
      },
      providesTags: ["ORDER"],
    }),

    getOrder: builder.query<IOrderRecord, IOrderRecord["id"]>({
      query: (orderId) => `/orderr/${orderId}`,
      providesTags: ["ORDER"],
    }),

    //
    // NTE Code88 Plugin
    //
    getNteCode88Items: builder.query<
      {
        items: Array<INteItem>;
      },
      string
    >({
      query: (workOrder) => `/plugins/nte/code88?work_order=${encodeURIComponent(workOrder)}`,
      providesTags: ["NTE_CODE88"],
    }),

    updateNteCode88Items: builder.mutation<
      void,
      {
        items: Array<INteItem>;
      }
    >({
      query: (body) => ({
        url: "/plugins/nte/code88",
        method: "POST",
        body,
      }),
      invalidatesTags: ["NTE_CODE88"],
    }),

    //
    // sheet import
    //

    triggerSheetImport: builder.mutation<{ message: string; status: string; task_id: string }, File>({
      query: (file) => {
        const formData = new FormData();
        formData.append("file", file);
        return {
          url: "/organization/sheet_import",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["PRODUCT", "CONTACT", "ORGANIZATION"],
    }),

    getSheetImportStatus: builder.query<SheetImportStatus, { task_id: string }>({
      query: ({ task_id }) => `/organization/sheet_import?task_id=${task_id}`,
    }),
  }),
});

export const {
  // Admin and debug
  useDebugInfoQuery,

  // Emails
  useGetWorkflowEmailsQuery,
  useReprocessEmailMutation,
  useGetEmailQuery,
  usePatchEmailMutation,
  useGenerateCsvMutation,
  useGetEmailNavigationQuery,
  useDeleteEmailMutation,
  useCertifyEmailMutation,
  useRegisterEmailMutation,
  useUploadEmailMutation,

  // Upload
  useUploadFileMutation,

  // Product
  useGetProductsQuery,
  useLazyGetProductsQuery,
  useLazyGetAutocompleteQuery,
  useGetProductQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useLazyGetProductQuery,

  // Address
  useGetAddressesQuery,
  useGetAddressQuery,
  useCreateAddressMutation,
  useUpdateAddressMutation,
  useDeleteAddressMutation,

  // Contact
  useGetContactsQuery,
  useLazyGetContactsQuery,
  useGetContactQuery,
  useCreateContactMutation,
  useUpdateContactMutation,
  useDeleteContactMutation,

  // Sync
  useTriggerSyncMutation,
  useGetSyncStatusQuery,

  // Gateway
  useStartLogStreamingMutation,
  useStopLogStreamingMutation,
  useGetLogsQuery,

  // Benchmark
  useGetBenchmarkReportsQuery,
  useGetBenchmarkReportQuery,
  useCreateBenchmarkMutation,
  useDeleteBenchmarkReportMutation,
  useUpdateBenchmarkNoteMutation,

  // Workflow
  useGetWorkflowsQuery,
  useGetWorkflowQuery,
  useGetWorkflowsByTitleQuery,
  useCreateWorkflowMutation,
  useUpdateWorkflowMutation,
  useDeleteWorkflowMutation,
  useDuplicateWorkflowMutation,

  // Organization
  useGetOrganizationsQuery,
  useGetOrganizationQuery,
  useGetOrganizationUsersQuery,
  useCreateOrganizationMutation,
  useDeleteOrganizationMutation,
  useUpdateOrganizationMutation,

  //Offer
  useGetOffersQuery,
  useGetOfferQuery,

  //Order
  useGetOrdersQuery,
  useGetOrderQuery,

  // Sheet Import
  useTriggerSheetImportMutation,
  useGetSheetImportStatusQuery,

  // NTE Code88
  useGetNteCode88ItemsQuery,
  useUpdateNteCode88ItemsMutation,
} = appApi;
