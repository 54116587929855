import { Box, Button, Card, CardContent, CircularProgress, Collapse, Dialog, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchIcon from '@mui/icons-material/Search';
import React, { useState, useRef } from "react";
import { UseFormReturn } from "react-hook-form";
import { useGetOffersQuery } from "../../../../../app/services/appApi";
import { IEmailRead } from "../../../emailTypes";
import { TabPlugin } from "./TabPluginRegistry";


const BelliniOfferTab: React.FC<{ form: UseFormReturn<any>; email: IEmailRead }> = ({ form, email }) => {
    const [selectedOffer, setSelectedOffer] = useState<any>(null);
    const [expandedId, setExpandedId] = useState<number | null>(null);
    const [searchInput, setSearchInput] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);

    const contact_id = email?.parsed_info?.buyer_id?.value?.toString();

    const { data, isLoading } = useGetOffersQuery({
        page: currentPage,
        pageSize: 20,
        ...(() => {
            const filters: any = { contact_id };
            if (searchQuery.trim() !== "") {
                filters.search = searchQuery.trim();
            }
            return filters;
        })()
    });

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight={200}>
                <CircularProgress />
            </Box>
        );
    }

    const handleExpandClick = (id: number) => {
        setExpandedId(expandedId === id ? null : id);
    };

    return (
        <Box sx={{ p: 2 }}>
            <Box mb={2}>
                <TextField
                    label="Cerca offerte"
                    fullWidth
                    variant="outlined"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            setSearchQuery(searchInput);
                            setCurrentPage(1);
                        }
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => {
                                        setSearchQuery(searchInput);
                                        setCurrentPage(1);
                                    }}
                                >
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <TableContainer component={Paper} sx={{ mb: 2 }}>
                <Table aria-label="collapsible table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell><strong>Codice</strong></TableCell>
                            <TableCell><strong>Nome Prodotto</strong></TableCell>
                            <TableCell align="right"><strong>Prezzo</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.results.map((offer: any) => (
                            <React.Fragment key={offer.id}>
                                <TableRow
                                    sx={{
                                        '& > *': { borderBottom: 'unset' },
                                        cursor: 'pointer',
                                        '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.04)' }
                                    }}
                                    onClick={() => handleExpandClick(offer.id)}
                                >
                                    <TableCell>
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                        >
                                            {expandedId === offer.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {offer.product?.code || "Codice non specificato"}
                                    </TableCell>
                                    <TableCell>{offer.product?.name || "Nome non specificato"}</TableCell>
                                    <TableCell align="right">€ {offer.price || "N/A"}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                                        <Collapse in={expandedId === offer.id} timeout="auto" unmountOnExit>
                                            <Box sx={{ margin: 1, py: 2 }}>
                                                {/* <Typography variant="h6" gutterBottom component="div">
                                                    Dettagli
                                                </Typography> */}
                                                <Stack spacing={2} sx={{ pl: 2 }}>
                                                    {offer.product?.description && (
                                                        <Typography><strong>Descrizione Prodotto:</strong> {offer.product?.description}</Typography>
                                                    )}
                                                    {offer.product?.unit_measure && (
                                                        <Typography><strong>Unità di Misura:</strong> {offer.product.unit_measure}</Typography>
                                                    )}
                                                    {offer.product?.unit_size && (
                                                        <Typography><strong>Dimensioni confezione:</strong> {offer.product.unit_size}</Typography>
                                                    )}
                                                    {offer.product?.density !== undefined && (
                                                        <Typography><strong>Densità:</strong> {offer.product.density}</Typography>
                                                    )}
                                                    {offer.description && (
                                                        <Typography><strong>Note Offerta:</strong> {offer.description}</Typography>
                                                    )}
                                                    <Typography><strong>Nome Cliente:</strong> {offer.contact?.name || 'Non specificato'}</Typography>
                                                    {offer.contact?.code && (
                                                        <Typography><strong>Codice Cliente:</strong> {offer.contact.code}</Typography>
                                                    )}
                                                    {offer.contact?.vat && (
                                                        <Typography><strong>Partita IVA:</strong> {offer.contact.vat}</Typography>
                                                    )}
                                                </Stack>
                                            </Box>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box display="flex" justifyContent="center" mt={2}>
                <Button
                    variant="contained"
                    onClick={() => setCurrentPage((p) => Math.max(p - 1, 1))}
                    disabled={currentPage === 1}
                >
                    Prev
                </Button>
                <Box mx={2} display="flex" alignItems="center">
                    Pagina {currentPage}
                </Box>
                <Button
                    variant="contained"
                    onClick={() => setCurrentPage((p) => (data && data.results.length === 20 ? p + 1 : p))}
                    disabled={!data || data.results.length < 20}
                >
                    Next
                </Button>
            </Box>
        </Box>
    );
};

const BelliniOfferPlugin: TabPlugin = {
    id: "bellini_offer",
    label: "Offerte",
    priority: -10,
    render: (form, email) => <BelliniOfferTab form={form} email={email} />,
    shouldLoad: (workflowName) => workflowName === "bellini_oda",
};

export default BelliniOfferPlugin;
