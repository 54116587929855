import { SearchUISchemaField } from "@features/email/emailDetailPage/tabs/fields/dynamicForm/dynamicFormTypes";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { debounce } from "@mui/material/utils";
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import ErrorIcon from "@mui/icons-material/Error";

export interface IAutocompleteOption {
  id: string;
  option_text: string;
  option_details?: string[];
}

export interface IAutocompleteRequest {
  id?: string;
  search?: string;
}

export interface IAutocompleteResponse {
  results: IAutocompleteOption[];
}

interface Props {
  advancedSearchParameters?: SearchUISchemaField["advancedSearchParameters"];
  disabled?: boolean;
  inputProps?: React.ComponentProps<typeof TextField>;
  label: string;
  lazyQuery: any;
  name: string;
  onBlur: () => void;
  onChange: (value: string) => void;
  searchModel: string;
  value: string;
}

const ServerSideSearchSelect2 = forwardRef<HTMLDivElement, Props>(
  (
    {
      advancedSearchParameters,
      disabled,
      inputProps,
      label,
      lazyQuery,
      name,
      onBlur,
      onChange: onChangeProps,
      searchModel,
      value: parentValue,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const form = useFormContext();

    const [isModelNotFound, setIsModelNotFound] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [lastFetchType, setLastFetchType] = useState<'search' | 'id'>('search');

    // Extra params are used to filter the options.
    // Here we get the values from the form and create an object with the key as the parameter and the form value as the value.
    const extraParameterValues = form.watch(advancedSearchParameters?.map((x) => `${x.valueFromField}.value`) ?? []);
    const [extraParameters, setExtraParameters] = useState<Record<string, any>>(
      extraParameterValues.reduce(
        (acc: any, curr: any, index: number) => {
          if (curr) {
            acc[advancedSearchParameters![index].key] = curr;
          }

          return acc;
        },
        {} as Record<string, any>,
      ),
    );
    // This is the currently selected option. it is != value because the value can be a string
    // while the selectedOption is an object
    const [selectedOption, setSelectedOption] = useState<IAutocompleteOption | null>(null);

    // This is the string value of the input field. It is what the use is typing and is used as filter for the options when fetching
    const [inputValue, setInputValue] = useState("");

    // This is the debounced trigger function. It is used to fetch the options when the user types in the input field
    const [trigger, { data, isFetching, isUninitialized, error }] = lazyQuery();
    const debouncedTrigger = debounce(trigger, 300);

    // Here we filter the options to remove duplicates. just in case the server returns duplicates.
    const options = useMemo(() => {
      const typedResults = (data?.results ?? []) as IAutocompleteOption[];
      const uniqueResults = typedResults.filter(
        (option, index, arr) => index === arr.findIndex((t) => t.id === option.id),
      );
      return uniqueResults;
    }, [data?.results]);


    // We update the extra parameters when the form values change
    useEffect(() => {
      const newExtraParameters = extraParameterValues.reduce(
        (acc: any, curr: any, index: number) => {
          if (curr) {
            acc[advancedSearchParameters![index].key] = curr;
          }

          return acc;
        },
        {} as Record<string, any>,
      );

      if (JSON.stringify(extraParameters) !== JSON.stringify(newExtraParameters)) {
        setExtraParameters(newExtraParameters);
        trigger({ searchModel, params: { ...newExtraParameters, search: inputValue } });
      }
    }, [advancedSearchParameters, extraParameterValues, extraParameters, inputValue, searchModel, trigger]);

    // Value is not empty. We find the option in the options array
    const foundSelectedOption = options.find((x) => x.id.toString() === parentValue.toString());

    // Reset errors when parent value changes
    useEffect(() => {
      if (parentValue) {
        // Disable the "not found" state only if the parentValue is not empty.
        // This is needed to show the error when the parentValue is set to "" due to the model not being present in the db
        setIsModelNotFound(false);
      }
      setServerError(false);
    }, [parentValue]);

    // Handle model not found state
    useEffect(() => {
      if (data && lastFetchType === 'id') {
        setIsModelNotFound(data.results.length === 0);
      }
    }, [data, lastFetchType]);

    // Handle server errors
    useEffect(() => {
      setServerError(!!error);
    }, [error]);

    // When the value changes we update the selected option
    useEffect(() => {
      // console.debug(`${name} Value changed in ${parentValue}`);

      if (parentValue === undefined || parentValue.trim().length === 0) {
        // Value is empty. This is the case when:
        // - This is the first mount and the value is empty "" or
        // - the user clears the input field or
        // - the value is cleared by the parent component (e.g. on form reset or changing data without re-rendering the component)
        // we clear the selected option
        // console.debug(`${name} Value is empty`);
        setSelectedOption(null);
        return;
      }

      // Handle the case where
      // - The value is not empty and this is first mount, so we must fetch the option via ID
      // - The value is not empty and the options are dirty because parent component changed the value without re-rendering the component
      if (parentValue &&
        parentValue !== selectedOption?.id &&
        !foundSelectedOption &&
        !isFetching &&
        !isModelNotFound
      ) {
        trigger({
          searchModel,
          params: { id: parentValue, ...extraParameters, search: "" }
        });
        setLastFetchType('id');
      }

      if (foundSelectedOption) {
        // The option is in the options. We need to check if it is already selected

        if (foundSelectedOption.id !== selectedOption?.id) {
          // The option is not selected. We select it. This happens when the user selects an option from the dropdown (?)
          // console.debug(`${name} Option found and selected`);
          setSelectedOption(foundSelectedOption);
          return;
        }

        if (foundSelectedOption.id !== parentValue) {
          // The option is not the same as the value. Notify the parent component.
          // this should never happen (?) as we already checked if the value is in the options
          // console.debug(`${name} Option found and selected but value is different`);
          onChangeProps(foundSelectedOption.id);
          return;
        }

      } else if (!selectedOption && !isUninitialized && !isFetching) {
        // This is the case where the pre-selected option is not found (e.g. deleted from the database)
        // console.debug(`${name} Pre-selected option not found (e.g. deleted from the database)`);
        setSelectedOption(null);
        onChangeProps("");
        return;
      }

    }, [parentValue, options, selectedOption, foundSelectedOption, isFetching,
      isUninitialized, trigger, extraParameters, searchModel, onChangeProps,
      name, isModelNotFound]);


    // Handle user typing in the input field. we update the input value and trigger the search
    const handleInputChange = useCallback(
      (_: any, value: string) => {
        setInputValue(value);
        setIsModelNotFound(false);
        setServerError(false);
        debouncedTrigger({
          searchModel,
          params: { ...extraParameters, search: value }
        });
        setLastFetchType('search');
      },
      [debouncedTrigger, extraParameters, searchModel]
    );

    // Handle user selecting an option
    const handleChange = useCallback(
      (_: any, selectedOption: IAutocompleteOption | null) => {
        if (selectedOption) {
          // The user selected an option. We notify the parent component
          // console.debug(`${name} User selected an option`);
          onChangeProps(selectedOption.id);
        } else {
          // The user cleared the input field (?) We notify the parent component
          // console.debug(`${name} User cleared the input field`);
          onChangeProps("");
        }
      },
      [onChangeProps],
    );

    const renderOption = (props: any, option: IAutocompleteOption) => (
      <li {...props} key={`listItem-${option.id}`}>
        <Box>
          <Typography variant="body1">{option.option_text}</Typography>
          {option.option_details &&
            option.option_details.map((val) => (
              <Typography variant="caption" key={val} display="block">
                {val}
              </Typography>
            ))}
        </Box>
      </li>
    );

    const renderInput = (params: any) => (
      <TextField
        {...params}
        {...inputProps}
        error={isModelNotFound || serverError}
        helperText={
          isModelNotFound
            ? t("error.optionNotFound")
            : serverError
              ? t("error.optionFetchError")
              : undefined
        }
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {isFetching && <CircularProgress color="inherit" size={20} />}
              {(isModelNotFound || serverError) && (
                <ErrorIcon color="error" sx={{ mr: 1 }} />
              )}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
        label={label}
        name={name}
      />
    )

    return (
      <Autocomplete
        ref={ref}
        disabled={disabled}
        filterOptions={(x) => x}
        getOptionLabel={(option) => option.option_text?.toString()}
        inputValue={inputValue}
        isOptionEqualToValue={(option, value) => option.id.toString() === value.id.toString()}
        loading={isFetching}
        noOptionsText={t("search.noResults")}
        onBlur={onBlur}
        onChange={handleChange}
        onInputChange={handleInputChange}
        options={options}
        renderInput={renderInput}
        renderOption={renderOption}
        value={selectedOption}
      />
    );
  },
);

export default ServerSideSearchSelect2;
