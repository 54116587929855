import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Stack from '@mui/material/Stack';
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { LoadingButton } from "@mui/lab";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import { styled } from '@mui/material/styles';
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useAuthContext from "@core/auth/AuthContext";
import { withNoAuth } from "@core/auth/authWrappers";
import FormControl from '@mui/material/FormControl';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useSignupMutation } from "@core/auth/authApi";

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '10px',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  [theme.breakpoints.up('sm')]: {
    width: '450px',
  },
}));

function SignupForm() {
  const { t } = useTranslation();
  const { login } = useAuthContext();
  const [signup, { isLoading: isSignupLoading }] = useSignupMutation();
  const [loading, setLoading] = React.useState(false);

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm({
    defaultValues: { username: "", password: "", confirmPassword: "", organization: "" },
  });

  const validateEmail = (email: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const onSubmit = async (data: any) => {
    if (data.password !== data.confirmPassword) {
      setError('confirmPassword', {
        type: 'manual',
        message: t('Passwords do not match')
      });
      return;
    }

    try {
      const result = await signup({
        username: data.username,
        password: data.password,
        organization: data.organization,
      }).unwrap();
      await login({
        username: data.username,
        password: data.password
      });

    } catch (error: any) {
      if (error.status === 409) {
        setError('username', {
          type: 'manual',
          message: t('Username already exists')
        });
      } else {
        console.error('Signup error:', error);
      }
    }

    setLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 700));
    await login(data);
    setLoading(false);
  };

  return (
    <StyledCard>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2
        }}
      >
        <Stack direction="row" spacing={2} sx={{ mt: 'auto', alignSelf: 'center', mb: 3 }}>
          <Avatar sx={{ bgcolor: 'primary.main', width: 45, height: 45 }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography
            component="h1"
            variant="h4"
            sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)', textAlign: 'center' }}
          >
            {t("Sign up")}
          </Typography>
        </Stack>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <FormControl>
            <Controller
              name="username"
              control={control}
              rules={{ required: true, validate: (value) => validateEmail(value) || t("Enter a valid email") }}
              render={({ field }) => (
                <TextField
                  sx={{
                    borderRadius: '10px',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '10px',
                    },
                  }}
                  {...field}
                  id='username'
                  label={t("Username")}
                  type="email"
                  placeholder={t("Enter your email")}
                  autoComplete="email"
                  required
                  fullWidth
                  error={!!errors.username}
                  helperText={errors.username && t("Required field")}
                  autoFocus
                />
              )}
            />
          </FormControl>

          <FormControl>
            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  sx={{
                    borderRadius: '10px',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '10px',
                    },
                  }}
                  {...field}
                  id="password"
                  label={t("Password")}
                  placeholder="••••••"
                  type={showPassword ? 'text' : 'password'}
                  required
                  fullWidth
                  error={!!errors.password}
                  helperText={errors.password && t("Check your password again")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>

          <FormControl>
            <Controller
              name="confirmPassword"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  sx={{
                    borderRadius: '10px',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '10px',
                    },
                  }}
                  {...field}
                  id="confirmPassword"
                  label={t("Confirm Password")}
                  placeholder="••••••"
                  type={showConfirmPassword ? 'text' : 'password'}
                  required
                  fullWidth
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword && (errors.confirmPassword.message || t("Check your password again"))}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>

          <FormControl>
            <Controller
              name="organization"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  sx={{
                    borderRadius: '10px',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '10px',
                    },
                  }}
                  {...field}
                  id='organization'
                  label={t("Nome dell'azienda")}
                  type="organization"
                  placeholder={t("Enter your Organization name")}
                  autoComplete="off"
                  required
                  fullWidth
                  error={!!errors.organization}
                  helperText={errors.organization && t("Required field")}
                  autoFocus
                />
              )}
            />
          </FormControl>

          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            loading={loading}
            sx={{
              mt: 1,
              borderRadius: '10px',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
              '& .MuiOutlinedInput-root': {
                borderRadius: '10px',
              },
            }}
          >
            {t("Sign up")}
          </LoadingButton>
        </Box>
      </Box>
    </StyledCard>
  );
}


function SignupPage() {
  return (
    <>
      <CssBaseline />
      <Stack
        direction="column"
        component="main"
        sx={{
          justifyContent: 'center',
          height: '100vh',
          minHeight: '100%',
          background: 'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 50%, 100%))',
        }}
      >
        <Stack
          direction={{ xs: 'column-reverse', md: 'row' }}
          sx={{
            justifyContent: 'center',
            gap: { xs: 6, sm: 12 },
            p: { xs: 2, sm: 4 },
            m: 'auto',
          }}
        >
          <SignupForm />
        </Stack>
      </Stack>
    </>
  );
}
export default withNoAuth(SignupPage);