// frontend/src/features/benchmark/BenchmarkBtn.tsx
import SpeedIcon from "@mui/icons-material/Speed";
import { LoadingButton } from "@mui/lab";
import { Button, Menu, MenuItem } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useCreateBenchmarkMutation } from "../../app/services/appApi";

interface BenchmarkBtnProps extends Omit<React.ComponentProps<typeof Button>, "variant"> {
  ids: number[];
}

const BenchmarkBtn: React.FC<BenchmarkBtnProps> = ({ ids, ...props }) => {
  const [createBenchmark, { isLoading }] = useCreateBenchmarkMutation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCreateBenchmark = async (recompute: boolean) => {
    if (ids.length === 0) {
      enqueueSnackbar("Seleziona almeno un'email per creare il benchmark", { variant: "warning" });
      return;
    }
    try {
      await createBenchmark({ ids, recompute });
      enqueueSnackbar(
        <span>
          Benchmark created successfully. View it
          <Link to="/benchmarks" style={{ color: "inherit", textDecoration: "underline" }}>
            {" "}
            here
          </Link>
          .
        </span>,
        { variant: "success" },
      );
    } catch (error: any) {
      enqueueSnackbar(error.data?.error || "Failed to create benchmark", { variant: "error" });
    } finally {
      handleClose();
    }
  };

  return (
    <>
      <LoadingButton
        fullWidth
        loading={isLoading}
        color="secondary"
        variant="contained"
        startIcon={<SpeedIcon />}
        disabled={ids.length === 0}
        onClick={handleClick}
        sx={{ mt: 2 }}
        {...props}
      >
        Benchmark
      </LoadingButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleCreateBenchmark(true)}>With Recompute</MenuItem>
        <MenuItem onClick={() => handleCreateBenchmark(false)}>Without Recompute</MenuItem>
      </Menu>
    </>
  );
};

export default BenchmarkBtn;