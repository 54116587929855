import AddIcon from "@mui/icons-material/Add";
import ContactsIcon from "@mui/icons-material/Contacts";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/system/Stack";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDeleteContactMutation, useGetContactsQuery } from "../../app/services/appApi";
import { withAuth } from "@core/auth/authWrappers";
import ContactFormDialog from "./ContactFormDialog";
import ContactSearchBar from "./ContactSearchBar";
import { IContactRecord } from "./contactTypes";

const ContactListPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();


  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  });

  const [searchValue, setSearchValue] = useState("");
  const { data, isLoading } = useGetContactsQuery({
    page: paginationModel.page + 1,
    pageSize: paginationModel.pageSize,
    search: searchValue,
  });

  const columns: GridColDef[] = [
    {
      field: "code",
      headerName: t("contact.list.code"),
      width: 180,
    },
    {
      field: "name",
      headerName: t("contact.list.name"),
      // Grow the column width
      flex: 1,
    },
    // {
    //   field: "edit", headerName: "",
    //   //@ts-ignore-next-line
    //   renderCell: (params: GridValueGetterParams) => (
    //     <ContactFormDialog contactId={params.row.id} />
    //   ),
    // },
    // {
    //   field: "delete", headerName: "",
    //   //@ts-ignore-next-line
    //   renderCell: (params: GridValueGetterParams) => (
    //     <ContactDeleteBtn contactId={params.row.id} />
    //   ),
    // },
  ];

  return (
    <>
      <Stack justifyContent="space-between" gap={2} direction="row" sx={{ mb: 2, alignItems: "center" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <ContactsIcon />
          <Typography variant="h5" component="h1" noWrap>
            <b>{t("contact.list.title")}</b>
          </Typography>
        </Box>

        <Stack direction="row" gap={2} alignItems="center">
          <ContactSearchBar onSearch={setSearchValue} size="small" />
          <ContactFormDialog
            render={(props) => (
              <Tooltip title={t("contact.list.noPermissionTooltip")}>
                <div>
                  <Button startIcon={<AddIcon />} variant="contained" color="primary" disabled {...props}>
                    {t("contact.list.add")}
                  </Button>
                </div>
              </Tooltip>
            )}
          />
        </Stack>
      </Stack>

      <DataGrid
        disableRowSelectionOnClick
        rows={data?.results || []}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        pageSizeOptions={[20, 50, 100]}
        sx={{ flex: 1 }}
        loading={isLoading}
        paginationMode="server"
        rowCount={data?.count || 0}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
      />
    </>
  );
};

const ContactDeleteBtn: React.FC<{ contactId: Pick<IContactRecord, "id"> }> = ({ contactId }) => {
  const [triggerDelete, { isLoading, isError, isSuccess }] = useDeleteContactMutation();
  const { t } = useTranslation();

  // Handle error and success notifications
  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(t("Model deleted", { model: "Contact", count: 1 }), { variant: "success" });
    }
    if (isError) {
      enqueueSnackbar(t("Model deletion failed", { model: "Contact", count: 1 }), { variant: "error" });
    }
  }, [isSuccess, isError, t]);

  return (
    <IconButton onClick={() => triggerDelete(contactId)} color="error" disabled={isLoading}>
      <DeleteOutlineOutlinedIcon />
    </IconButton>
  );
};

export default withAuth(ContactListPage);
