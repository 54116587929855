import { useGetPolicyQuery } from "../authApi"
import useAuthContext from "../AuthContext";
import { IAuthzSub } from "../authTypes";
import { Enforcer, newEnforcer, newModelFromString, StringAdapter } from "casbin";
import { createContext, ReactNode, useEffect, useState } from "react";

interface AuthzContextType {
  enforcer: Enforcer | null;
  sub: IAuthzSub | null | undefined;
}

export const AuthzContext = createContext<AuthzContextType | undefined>(undefined);

interface MyAuthzProviderProps {
  children: ReactNode;
}

function xorDec(encodedData: string, key: string): string {
  const data = atob(encodedData);
  return data.split('').map((char, index) => String.fromCharCode(char.charCodeAt(0) ^ key.charCodeAt(index % key.length))).join('');
}

export default function MyAuthzProvider({ children }: MyAuthzProviderProps) {
  const { user } = useAuthContext();

  const [enforcer, setEnforcer] = useState<Enforcer | null>(null);
  const { data: policyData, isLoading, error } = useGetPolicyQuery(undefined, { skip: !user });

  useEffect(() => {
    async function initializeEnforcer() {
      if (policyData && !isLoading) {

        const k = 'Igxsr5CdnRpK';
        const sep = "AI4h3rR1D2uq";

        const [encModel, encPolicy] = policyData.payload.split(sep);

        const modelContent = xorDec(encModel, k);
        const policyContent = xorDec(encPolicy, k);

        // console.log("model", modelContent);
        // console.log("policy", policyContent);

        const model = newModelFromString(modelContent);
        const policyAdapter = new StringAdapter(policyContent);
        const e = await newEnforcer(model, policyAdapter);
        setEnforcer(e);
      }
    }

    initializeEnforcer();
  }, [policyData, isLoading]);

  if (isLoading) return <div>Loading authz...</div>;
  if (error) return <div>Authz error: Cannot pull policy</div>;

  return <AuthzContext.Provider value={{ sub: user?.authz_sub, enforcer }}>{children}</AuthzContext.Provider>;
}
