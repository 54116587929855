// BenchmarkListPage.tsx

import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, TextField, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/system/Stack";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  useCreateBenchmarkMutation,
  useDeleteBenchmarkReportMutation,
  useGetBenchmarkReportsQuery,
  useUpdateBenchmarkNoteMutation
} from "../../app/services/appApi";
import { withAuth } from "@core/auth/authWrappers";
import { FormControlLabel, Switch } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";


const BenchmarkListPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [recompute, setRecompute] = useState(false);
  const [editNoteId, setEditNoteId] = useState<number | null>(null);
  const [editNoteText, setEditNoteText] = useState("");


  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  });

  const { data, isLoading } = useGetBenchmarkReportsQuery({
    page: paginationModel.page + 1,
    pageSize: paginationModel.pageSize,
    search: "",
  }, { pollingInterval: 5000 });

  const [createBenchmark] = useCreateBenchmarkMutation();
  const [emailIds, setEmailIds] = useState("");

  const handleCreateBenchmark = async () => {
    const ids = emailIds
      .split(",")
      .map((id) => parseInt(id.trim(), 10))
      .filter((id) => !isNaN(id));
    if (ids.length === 0) {
      enqueueSnackbar(t("Please enter valid email IDs"), { variant: "error" });
      return;
    }
    try {
      await createBenchmark({ ids, recompute });
      enqueueSnackbar(t("Benchmark created successfully"), { variant: "success" });
      setEmailIds("");
    } catch (error: any) {
      enqueueSnackbar(error.data?.error || t("Failed to create benchmark"), { variant: "error" });
    }
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "status", headerName: "Status", width: 120 },
    { field: "app_version", headerName: "App Version", width: 130 },
    {
      field: "email_accuracies", headerName: "Email IDs", flex: 1,
      renderCell: (params) => {
        // return a comma separated list of email ids, taken from object keys
        return Object.keys(params.value).join(", ");
      }

    },
    {
      field: "overall_accuracy",
      headerName: "Overall Accuracy",
      width: 150,
      valueFormatter: (params) => `${(params.value * 100).toFixed(2)}%`,
    },
    {
      field: "is_recomputed",
      headerName: "Recomputed",
      width: 120,
      renderCell: (params) => (
        <Typography color={params.value ? "primary" : "textSecondary"}>
          {params.value ? t("Yes") : t("No")}
        </Typography>
      ),
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 200,
      renderCell: (params) => (
        <>
          <Typography noWrap>{params.value}</Typography>
          <IconButton onClick={() => handleEditNote(params.row.id, params.value)}>
            <EditIcon />
          </IconButton>
        </>
      ),
    },
    { field: "created_at", headerName: "Created At", width: 200 },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      //@ts-ignore-next-line

      renderCell: (params: GridValueGetterParams) => (
        <>
          <IconButton onClick={() => navigate(`/benchmark/${params.row.id}`)}>
            <VisibilityIcon />
          </IconButton>
          <BenchmarkDeleteBtn benchmarkId={params.row.id} />
        </>
      ),
    },
  ];

  const handleEditNote = (id: number, currentNote: string) => {
    setEditNoteId(id);
    setEditNoteText(currentNote);
  };

  const handleCloseEditNote = () => {
    setEditNoteId(null);
    setEditNoteText("");
  };

  return (
    <>
      <Stack gap={2} direction="row" sx={{ mb: 2 }}>
        <Typography variant="h5" component="h1" noWrap sx={{ flexGrow: 1 }}>
          <b>{t("Benchmark Reports")}</b>
        </Typography>
      </Stack>

      <Stack gap={2} direction="row" sx={{ mb: 2 }}>
        <TextField
          label={t("Email IDs (comma-separated)")}
          variant="outlined"
          size="small"
          value={emailIds}
          onChange={(e) => setEmailIds(e.target.value)}
          sx={{ flexGrow: 1 }}
        />
        <FormControlLabel
          control={
            <Switch
              checked={recompute}
              onChange={(e) => setRecompute(e.target.checked)}
            />
          }
          label={t("Recompute")}
        />
        <Button startIcon={<AddIcon />} variant="contained" color="primary" onClick={handleCreateBenchmark}>
          {t("Create Benchmark")}
        </Button>
      </Stack>

      <DataGrid
        disableRowSelectionOnClick
        rows={data?.results || []}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        pageSizeOptions={[20, 50, 100]}
        sx={{ flex: 1 }}
        loading={isLoading}
        paginationMode="server"
        rowCount={data?.count || 0}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
      />
      <EditNoteDialog
        open={editNoteId !== null}
        onClose={handleCloseEditNote}
        id={editNoteId}
        note={editNoteText}
        onNoteChange={setEditNoteText}
      />
    </>
  );
};


interface EditNoteDialogProps {
  open: boolean;
  onClose: () => void;
  id: number | null;
  note: string;
  onNoteChange: (note: string) => void;
}

const EditNoteDialog: React.FC<EditNoteDialogProps> = ({ open, onClose, id, note, onNoteChange }) => {
  const { t } = useTranslation();
  const [updateNote] = useUpdateBenchmarkNoteMutation();

  const handleSave = async () => {
    if (id !== null) {
      try {
        await updateNote({ id, note });
        enqueueSnackbar(t("Note updated successfully"), { variant: "success" });
        onClose();
      } catch (error) {
        enqueueSnackbar(t("Failed to update note"), { variant: "error" });
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t("Edit Note")}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label={t("Note")}
          fullWidth
          multiline
          rows={4}
          value={note}
          onChange={(e) => onNoteChange(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("Cancel")}</Button>
        <Button onClick={handleSave}>{t("Save")}</Button>
      </DialogActions>
    </Dialog>
  );
};

const BenchmarkDeleteBtn: React.FC<{ benchmarkId: number }> = ({ benchmarkId }) => {
  const [triggerDelete, { isLoading, isError, isSuccess }] = useDeleteBenchmarkReportMutation();
  const { t } = useTranslation();

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(t("Benchmark report deleted"), { variant: "success" });
    }
    if (isError) {
      enqueueSnackbar(t("Failed to delete benchmark report"), { variant: "error" });
    }
  }, [isSuccess, isError, t]);

  return (
    <IconButton onClick={() => triggerDelete(benchmarkId)} color="error" disabled={isLoading}>
      <DeleteOutlineOutlinedIcon />
    </IconButton>
  );
};

export default withAuth(BenchmarkListPage);
