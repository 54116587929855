import { useContext } from "react";
import { AuthzContext } from "./MyAuthzProvider";

interface UseAuthorization {
  can: (act: string, obj: string, field: string) => boolean;
  cannot: (act: string, obj: string, field: string) => boolean;
}

export function useAuthorization(): UseAuthorization {
  const context = useContext(AuthzContext);

  if (!context) {
    throw new Error("useAuthorization must be used within a MyAuthzProvider");
  }

  const can = (act: string, obj: string, field?: string): boolean => {
    if (!context.enforcer || !context.sub) return false;

    // We do this because with now the frontend only allows string types for objects
    const richObj = {
      type: obj,
      org_id: "__unavailable_in_frontend__",
      parent_org_id: "__unavailable_in_frontend__",
      owner_id: "__unavailable_in_frontend__",
    };

    // console.log("====================================");
    // console.log("sub", context.sub);
    // console.log("obj", richObj);
    // console.log("field", field || "__any__");
    // console.log("act", act);

    // use async version maybe better?
    const result = context.enforcer.enforceSync(context.sub, richObj, field || "__any__", act);

    return result;
  };

  const cannot = (act: string, obj: string, field?: string): boolean => !can(act, obj, field);

  return { can, cannot };
}
