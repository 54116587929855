//
//TODO: move this to the appApi file, along with a proper baseQuery initialization
//

import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const API_PATH = "/api/v1/"; // Only url part, without localhost or IP. Must always end with a slash

const baseQuery = fetchBaseQuery({
  baseUrl: API_PATH,
  prepareHeaders: (headers) => {
    headers.set("Accept", "application/json; charset=utf-8");
    // Append token to every request
    const token = localStorage.getItem("token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithLogout = async (args: any, api: any, extraOptions: any) => {
  let result = await baseQuery(args, api, extraOptions);

  // Handle authentication errors
  if (result.error && result.error.status === 401) {
    // const isMissingToken = result.error.data.code === "MISSING_TOKEN";
    const isInvalidToken = (result.error.data as { code?: string })?.code === "INVALID_TOKEN";
    const isExpiredToken = (result.error.data as { code?: string })?.code === "EXPIRED_TOKEN";

    if (isInvalidToken || isExpiredToken) {
      console.error("Invalid or missing token, logging out...");
      localStorage.removeItem("token");
      window.location.reload();
    }
  }

  return result;
};

export { baseQueryWithLogout };
