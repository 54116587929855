import React from 'react';
import { Card, CardContent, CardHeader, Typography, styled, Box, Grid, Components, Avatar, Stack } from "@mui/material"
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';

interface EmailBodyProps {
  email: {
    sender: string;
    original_sender?: string;
    recipient: string;
    subject: string;
    body_html: string;
    body_text: string;
  };
}

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  borderRadius: 15,
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 600,
  color: theme.palette.text.primary,
}));

const ContentTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  color: theme.palette.text.primary,
}));

//MUI Avatar source code
function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: name ? name[0].toUpperCase() : 'M',
  };
}

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}


function EmailBody({ email }: EmailBodyProps) {
  const { t } = useTranslation();

  return (
    <Box sx={{
      minHeight: '100vh',
      bgcolor: '#fafafa',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      borderRadius: 2,
      p: 4
    }}>
      <Grid container spacing={4}>
        {[{ label: t('Mittente'), value: email.sender }, { label: t('Mittente originale'), value: email.original_sender }].map((item, index) => (
          <Grid item xs={6} md={6} key={index}>
            <StyledCard>
              <CardContent>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Avatar {...stringAvatar(item.value || "")} />
                  <Box>
                    <TitleTypography>{item.label}</TitleTypography>
                    <ContentTypography whiteSpace={'nowrap'} overflow={'hidden'} textOverflow={'ellipsis'} maxWidth={'350px'}>{item.value}</ContentTypography>
                  </Box>
                </Stack>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}

        <Grid item xs={12}>
          <StyledCard>
            <CardContent>
              <TitleTypography borderBottom={'1px solid #e0e0e0'} paddingBottom={2} >
                {t('Corpo Email')}
              </TitleTypography>
              <Box>
                <ContentTypography
                  variant="body1"
                  sx={{
                    '& *': { fontSize: '0.875rem' }
                  }}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(email.body_html || email.body_text || '')
                  }}
                />
              </Box>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </Box>
  );
}

export default EmailBody;