import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";

interface DeleteDialogProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    itemName: string;
    itemType: string;
}

const DeleteDialog: React.FC<DeleteDialogProps> = ({ open, onClose, onConfirm, itemName, itemType }) => {
    const [confirmName, setConfirmName] = useState('');
    const { t } = useTranslation();

    const handleConfirm = () => {
        if (confirmName === itemName) {
            onConfirm();
            onClose();
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>{t("deleteDialog.confirmTitle", { type: itemType })}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t("deleteDialog.confirmMessage", { type: itemType, name: itemName })}
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    label={t("deleteDialog.confirmLabel", { type: itemType })}
                    fullWidth
                    variant="outlined"
                    value={confirmName}
                    onChange={(e) => setConfirmName(e.target.value)}
                    sx={{ mt: 2 }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{t("common.cancel")}</Button>
                <Button onClick={handleConfirm} color="error" disabled={confirmName !== itemName}>
                    {t("common.delete")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteDialog;