import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useCreateProductMutation, useGetProductQuery, useUpdateProductMutation } from "../../app/services/appApi";
import { IProductRecord, IProductRequest } from "./productTypes";

interface IProductFormDialogProps {
  productId?: IProductRecord["id"];
  render?: (props: { onClick: () => void }) => React.ReactNode;
}

/**
 * Dialog component for creating and editing products.
 * It can be opened in two modes:
 * 1. Create mode: productId is not given
 * 2. Edit mode: productId is given
 *
 * In edit mode, the product data is fetched from the API so
 * that the form can be pre-filled with the existing product data.
 */
const ProductFormDialog: React.FC<IProductFormDialogProps> = ({ productId, render }) => {
  const [open, setOpen] = useState(false);
  const createMode: boolean = !productId;

  // Delay data fetching until dialog is opened
  const { data: product } = useGetProductQuery(productId || -1, { skip: createMode || !open });

  const [triggerCreate, { isLoading: isCreateLoading, isSuccess: isCreateSuccess, isError: isCreateError }] =
    useCreateProductMutation();
  const [triggerUpdate, { isLoading: isUpdateLoading, isSuccess: isUpdateSuccess, isError: isUpdateError }] =
    useUpdateProductMutation();

  // defaultValues are only available after product data is fetched.
  // If product data is not fetched, but productId is given, show loading indicator
  // If product data is not fetched and productId is not given, show empty form
  const defaultProductValues: IProductRequest | undefined =
    // In create mode, show empty form without fetching product data
    createMode
      ? { code: "", name: "" }
      : // In edit mode show loading until product data is fetched
      product
        ? // Product is fetched, show product data
        {
          name: product.name,
          code: product.code,
          // tag_names: product.tags.map(tag => tag.name)
        }
        : // Product is not fetched
        undefined;

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (data: IProductRequest) => {
    if (createMode) {
      triggerCreate(data);
    } else {
      triggerUpdate({
        id: productId || -1,
        ...data,
      });
    }
  };

  // Handle error and success notifications
  const { t } = useTranslation();

  useEffect(() => {
    if (isCreateError) {
      enqueueSnackbar(t("product.createError"), { variant: "error" });
    }
    if (isCreateSuccess) {
      enqueueSnackbar(t("product.createSuccess"), { variant: "success" });
      handleClose();
    }
    if (isUpdateError) {
      enqueueSnackbar(t("product.updateError"), { variant: "error" });
    }
    if (isUpdateSuccess) {
      enqueueSnackbar(t("product.updateSuccess"), { variant: "success" });
      handleClose();
    }
  }, [isCreateError, isCreateSuccess, isUpdateError, isUpdateSuccess]);

  const openButton = render ? (
    render({ onClick: () => setOpen(true) })
  ) : (
    <IconButton onClick={() => setOpen(true)}>
      <EditOutlinedIcon />
    </IconButton>
  );

  return (
    <>
      {openButton}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{createMode ? t("product.createNew") : t("product.edit")}</DialogTitle>
        {defaultProductValues ? (
          <ProductForm
            createMode={createMode}
            isLoading={isCreateLoading || isUpdateLoading}
            onSubmit={handleSubmit}
            onClose={handleClose}
            defaultValues={defaultProductValues}
          />
        ) : (
          <DialogContent>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};

interface IProductFormProps {
  defaultValues: IProductRequest;
  createMode?: boolean;
  isLoading: boolean;
  onSubmit: (data: IProductRequest) => void;
  onClose: () => void;
}

/**
 * Inner form component for ProductFormDialog.
 * Separate component is used to render only when the
 * defaultValues are available in case of edit mode.
 */
const ProductForm: React.FC<IProductFormProps> = ({ defaultValues, onSubmit, onClose, isLoading, createMode }) => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm<IProductRequest>({ defaultValues });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <Stack gap={2}>
          <Controller
            name="code"
            rules={{ required: true, minLength: 3 }}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                autoFocus
                label={t("product.code")}
                type="text"
                fullWidth
                required
                error={!!fieldState.error}
                helperText={!!fieldState.error && t("validation.minWithLabel", { label: t("product.code"), min: 3 })}
                {...field}
              />
            )}
          />
          <Controller
            name="name"
            rules={{ required: true, minLength: 3 }}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                autoFocus
                label={t("product.name")}
                type="text"
                fullWidth
                required
                error={!!fieldState.error}
                helperText={!!fieldState.error && t("validation.minWithLabel", { label: t("product.name"), min: 3 })}
                {...field}
              />
            )}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ m: 2 }}>
        <Button startIcon={<CloseOutlinedIcon />} onClick={onClose}>
          {t("common.cancel")}
        </Button>
        <LoadingButton
          startIcon={<CheckOutlinedIcon />}
          loading={isLoading}
          disabled={isLoading}
          variant="contained"
          type="submit"
        >
          {createMode ? t("common.create") : t("common.update")}
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

export default ProductFormDialog;
