// frontend/src/features/admin_workflows/WorkflowTableRowMenu.tsx

import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import { Menu, MenuItem, IconButton } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuList from "@mui/material/MenuList";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDeleteWorkflowMutation, useDuplicateWorkflowMutation, useGetWorkflowQuery } from "../../app/services/appApi";
import DeleteDialog from "@core/delete_dialog/DeleteDialog";

interface IWorkflowTableRowMenu {
    workflowId: number;
    deleteBtn: boolean;
    duplicateBtn: boolean;
    editBtn: boolean;
    isDataGrid?: boolean;
}

export default function WorkflowTableRowMenu({
    workflowId,
    deleteBtn,
    duplicateBtn,
    editBtn,
    isDataGrid = false,
}: IWorkflowTableRowMenu) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const { data: workflow } = useGetWorkflowQuery(workflowId);

    const [deleteWorkflow, { isLoading: isDelLoading, isError: isDelError, isSuccess: isDelSuccess }] =
        useDeleteWorkflowMutation();

    useEffect(() => {
        if (isDelError) {
            enqueueSnackbar(t("workflow.deleteWorkflowFailure"), { variant: "error" });
        } else if (isDelSuccess) {
            enqueueSnackbar(t("workflow.deleteWorkflowSuccess"), { variant: "success" });
        }
    }, [isDelError, isDelSuccess, t]);

    const [duplicateWorkflow, { isLoading: isDupLoading, isError: isDupError, isSuccess: isDupSuccess }] =
        useDuplicateWorkflowMutation();
    useEffect(() => {
        if (isDupError) {
            enqueueSnackbar(t("workflow.duplicateWorkflowFailure"), { variant: "error" });
        } else if (isDupSuccess) {
            enqueueSnackbar(t("workflow.duplicateWorkflowSuccess"), { variant: "success" });
        }
    }, [isDupError, isDupSuccess, t]);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleEditClick = () => {
        navigate(`/workflow/${workflowId}/edit`);
        handleMenuClose();
    };

    const handleDeleteClick = () => {
        setOpenDeleteDialog(true);
        handleMenuClose();
    };

    const handleDeleteConfirm = async () => {
        await deleteWorkflow(workflowId);
        setOpenDeleteDialog(false);
    };

    const MenuTrigger = isDataGrid ? (
        <GridActionsCellItem
            icon={<MoreVertIcon />}
            label={t("common.more")}
            className="actions"
            onClick={handleMenuOpen}
        />
    ) : (
        <IconButton
            aria-label={t("common.more")}
            onClick={handleMenuOpen}
            size="small"
        >
            <MoreVertIcon />
        </IconButton>
    );

    return (
        <>
            {MenuTrigger}
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuList>
                    {editBtn && (
                        <MenuItem onClick={handleEditClick}>
                            <ListItemIcon>
                                <EditIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={t("common.edit")} />
                        </MenuItem>
                    )}
                    {duplicateBtn && (
                        <MenuItem onClick={() => duplicateWorkflow(workflowId)} disabled={isDupLoading}>
                            <ListItemIcon>
                                <ContentCopyIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={t("common.duplicate")} />
                        </MenuItem>
                    )}
                    {deleteBtn && (
                        <MenuItem onClick={handleDeleteClick} disabled={isDelLoading}>
                            <ListItemIcon>
                                <DeleteIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={t("common.delete")} />
                        </MenuItem>
                    )}
                </MenuList>
            </Menu>
            <DeleteDialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                onConfirm={handleDeleteConfirm}
                itemName={workflow?.title || ""}
                itemType={t("workflow.type")}
            />
        </>
    );
}