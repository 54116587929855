import MoreVertIcon from "@mui/icons-material/MoreVert";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, IconButton, Menu, MenuItem, Tooltip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/system/Stack";
import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import { FormProvider } from "react-hook-form";
import { useEmailForm } from "../../context/EmailFormContext";
import CertifyEmailBtn from "./CertifyEmailBtn";
import RegisterEmailBtn from "./RegisterEmailBtn";
import RegisteredEmailAlert from "./RegisteredEmailAlert";
import DynamicEmailForm from "./dynamicForm/DynamicEmailForm";
import { EmailViewer } from "./dynamicForm/DynamicFieldRenderer";
import { UISchema, UISchemaField } from "./dynamicForm/dynamicFormTypes";
import { EmailParsedInfo, IEmailRead } from "../../../emailTypes";
import { useTranslation } from "react-i18next";
import LoadingPlaceholder from "../../LoadingPlaceholder";

export interface EmailEditableFieldsProps {
  email: IEmailRead;
  isLoading: boolean;
  onTarget: (target: EmailViewer) => void;
}

export const sanitizeParsedInfo = (parsedInfo: EmailParsedInfo, uiSchema: UISchema): EmailParsedInfo => {

  const sanitizeField = (field: UISchemaField, value: any): any => {
    switch (field.type) {
      case "string":
      case "multiline":
        return value === undefined ? "" : String(value);
      case "int":
      case "float":
        return !isNaN(Number(value)) ? Number(value) : null;
      case "enum":
        if (field.multiple) {
          return Array.isArray(value)
            ? value.filter(Boolean).map(String)
            : typeof value === "string"
              ? [value]
              : [];
        } else {
          return value === undefined ? "" : String(value);
        }
      case "search":
        if (field.multiple) {
          return Array.isArray(value)
            ? value.filter(Boolean).map(String)
            : typeof value === "string"
              ? [value]
              : [];
        } else {
          return value === undefined ? "" : String(value);
        }
      case "date":
        // TODO: Validate date format
        return value;
      case "group":
      case "array":
        return value; // These types are handled recursively
      case "hidden":
        return value; // Preserve hidden field values
      default:
        return value;
    }
  };

  const processFields = (fields: UISchemaField[], data: any): any => {
    const result: any = {};
    fields.forEach((field) => {
      if (field.type === "group") {
        const groupData = processFields(field.fields, data);
        if (field.key) {
          result[field.key] = groupData;
        } else {
          // If the group doesn't have a key, merge its contents into the parent
          Object.assign(result, groupData);
        }
      } else if (field.type === "array") {
        result[field.key] = (data[field.key] || []).map((item: any) =>
          processFields(field.fields, item)
        );
      } else {
        result[field.key] = {
          value: sanitizeField(field, data[field.key]?.value),
          bbox: data[field.key]?.bbox || [0, 0, 0, 0],
          page_idx: data[field.key]?.page_idx || 0,
          src_ref: data[field.key]?.src_ref || { id: null, type: null },
          message: data[field.key]?.message || null
        };
      }
    });
    return result;
  };
  return processFields(uiSchema.fields, parsedInfo);
};

const EmailFieldsTab: React.FC<EmailEditableFieldsProps> = ({
  email,
  isLoading,
  onTarget,
}) => {
  const { t } = useTranslation();
  const { form, isDirty, isSubmitting, handleValidation2, isDisableValidation2, setIsDisableValidation2 } = useEmailForm();
  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Handles basic form validation errors
   */
  const handleError = (errors: any) => {
    enqueueSnackbar(t("email.formError"), { variant: "error" });
  };

  const handleEnableUnsafeRegister = () => {
    if (!isDisableValidation2) {
      setIsWarningDialogOpen(true);
    } else {
      setIsDisableValidation2(false);
    }
  };

  const handleWarningConfirm = () => {
    setIsDisableValidation2(true);
    setIsWarningDialogOpen(false);
  };

  const handleWarningCancel = () => {
    setIsWarningDialogOpen(false);
  };

  const WarningDialog = () => (
    <Dialog open={isWarningDialogOpen} onClose={handleWarningCancel}>
      <DialogTitle>{t("email.disableValidationWarningTitle")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("email.disableValidationWarningMessage")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleWarningCancel}>{t("common.cancel")}</Button>
        <Button onClick={handleWarningConfirm} color="error">
          {t("common.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(handleValidation2, handleError)}
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <Box
          sx={{
            overflowY: "auto",
            flexGrow: 1,
            p: 1,
          }}
        >
          {email.is_registered && <RegisteredEmailAlert sx={{ mb: 2 }} />}
          <DynamicEmailForm
            uiSchema={email.ui_schema}
            control={form.control}
            disabled={!isDisableValidation2 && email.is_registered || isLoading}
            onTarget={onTarget}
          />
        </Box>
        <Stack direction={{ xs: "column", sm: "row" }} gap={1} sx={{ mt: 2 }} justifyContent="flex-end">
          <Box sx={{ flexGrow: 1 }}>
            <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
          </Box>
          <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={isDisableValidation2} onChange={handleEnableUnsafeRegister} />}
                  label={t("email.disableValidation")}
                />
              </FormGroup>
            </MenuItem>
          </Menu>
          <Tooltip title={t("common.saveData")}>
            <span>
              <LoadingButton
                type="submit"
                loading={isLoading || isSubmitting}
                disabled={!isDisableValidation2 && email.is_registered}
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
              >
                {isSubmitting ? t("common.validating") : t("common.save")}
              </LoadingButton>
            </span>
          </Tooltip>
          <CertifyEmailBtn email={email} disable={isDirty} forceEnable={isDisableValidation2} />
          <RegisterEmailBtn email={email} disable={isDirty} forceEnable={isDisableValidation2} />
        </Stack>
        <WarningDialog />
      </form>
    </FormProvider>
  );
};

export default EmailFieldsTab;
