import WorkflowSettings from "@features/workflow/WorkflowSettings";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import EditIcon from "@mui/icons-material/Edit";
import TaskIcon from "@mui/icons-material/Task";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Avatar, IconButton, Tooltip, Typography } from "@mui/material";
import Stack from "@mui/system/Stack";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridRowSelectionModel,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import TimeAgo from "react-timeago";
import { useGetWorkflowEmailsQuery, useGetWorkflowQuery } from "../../../app/services/appApi";
import { withAuth } from "@core/auth/authWrappers";
import BenchmarkBtn from "../../benchmark/BenchmarkBtn";
import { useDevSettings } from "../../debug/devSettings/DevSettings";
import { IEmailRead } from "../emailTypes";
import DeleteEmailBtn from "../shared/DeleteEmailBtn";
import DownloadCsvBtn from "../shared/DownloadCsvBtn";
import EmailStatusChip from "../shared/EmailStatusChip";
import EmailCopyBtn from "./EmailCopyBtn";
import FileUploadDialog from "./FileUploadDialog";
import TableRowActionsMenu from "./TableRowActionsMenu";
import AssignEmailBtn from "../shared/AssignEmailBtn";

function EmailListPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { settings } = useDevSettings();
  const { id: workflowId } = useParams();
  const { data: workflow } = useGetWorkflowQuery(Number(workflowId));


  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  });

  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);

  const [rows, setRows] = useState<IEmailRead[]>([]);

  const handleOpenUploadDialog = () => {
    setIsUploadDialogOpen(true);
  };

  const handleCloseUploadDialog = () => {
    setIsUploadDialogOpen(false);
  };

  const { data, isLoading } = useGetWorkflowEmailsQuery(
    {
      workflowId: Number(workflowId),
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
    },
    {
      pollingInterval: 3000,
      skip: !workflowId,
    },
  );

  const handleEditClick = (emailId: number) => {
    navigate(`/workflow/${Number(workflowId)}/email/${emailId}`);
  };

  const getEmailActions = (params: GridRowParams) => {
    const emailId = Number(params.id);
    const editBtn = params.row.status === "PROCESSED";
    const reprocessBtn = params.row.status !== "PROCESSING";
    const deleteBtn = params.row.status !== "PROCESSING";
    const editMsg = editBtn ? t("common.edit") : t("email.emailNotProcessed");
    const rawEmlFilePresignedUrl = params.row.raw_eml_file_presigned_url;

    const handleReprocess = (emailId: number) => {
      if (data?.results) {
        const updatedResults = data.results.map((row) =>
          row.id === emailId ? { ...row, is_exported: false, reprocessed: true } : row,
        );
        setRows(updatedResults);
      }
    };

    return [
      <Tooltip title={editMsg} key="edit">
        <span>
          <GridActionsCellItem
            icon={<EditIcon />}
            label={t("common.edit")}
            className="actions"
            disabled={!editBtn}
            onClick={() => handleEditClick(emailId)}
          />
        </span>
      </Tooltip>,
      <TableRowActionsMenu
        emailId={emailId}
        reprocessBtn={reprocessBtn}
        deleteBtn={deleteBtn}
        isRegistered={params.row.is_registered}
        rawEmlFilePresignedUrl={rawEmlFilePresignedUrl}
      />,
    ];
  };

  const baseColumns: GridColDef[] = [
    {
      field: "status",
      headerName: t("common.status"),
      width: 155,
      //@ts-ignore-next-line
      renderCell: (params: GridValueGetterParams) => (
        <EmailStatusChip level={params.value as IEmailRead["status"]} variant="outlined" />
      ),
    },
    {
      field: "subject",
      headerName: t("common.subject"),
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "",
      align: "right",
      width: 100,
      //@ts-ignore-next-line
      getActions: getEmailActions,
    },
    {
      field: "assigned_to",
      headerAlign: "center",
      align: "center",
      headerName: "",
      width: 100,
      renderCell: (params: GridRenderCellParams<any, any>) => {
        const assignedTo = params.row.assigned_to || null;

        if (!assignedTo || !assignedTo.display_name) {
          return null;
        }

        const displayName = assignedTo.display_name;
        const initial = displayName.charAt(0).toUpperCase() + displayName.charAt(1).toUpperCase();

        return (
          <Tooltip title={displayName}>
            <Avatar
              sx={{
                width: 30,
                height: 30,
                bgcolor: 'primary.main',
                fontSize: '0.875rem'
              }}
            >
              {initial}
            </Avatar>
          </Tooltip>
        );
      }
    },
    {
      field: "is_certified",
      headerAlign: "center",
      align: "center",
      headerName: "",
      width: 100,
      //@ts-ignore-next-line
      renderCell: (params: GridValueGetterParams) => {
        const { is_saved, is_certified, is_registered } = params.row;
        let icon = null;
        let tooltipText = "";

        if (is_registered) {
          icon = (
            <>
              <DoneAllIcon color="primary" sx={{ opacity: 0.8 }} />
            </>
          );
          tooltipText = t("common.sent");
        } else if (is_certified) {
          icon = (
            <>
              <DoneAllIcon color="action" sx={{ opacity: 0.6 }} />
            </>
          );
          tooltipText = t("common.certified");
        } else if (is_saved) {
          icon = <DoneIcon color="action" sx={{ opacity: 0.6 }} />;
          tooltipText = t("common.saved");
        }

        return icon ? (
          <Tooltip title={tooltipText}>
            <div style={{ display: "flex", alignItems: "center" }}>{icon}</div>
          </Tooltip>
        ) : null;
      },
    },
    {
      field: "is_exported",
      headerAlign: "center",
      align: "center",
      headerName: "",
      width: 100,
      //@ts-ignore-next-line
      renderCell: (params: GridValueGetterParams) => {
        const { is_exported } = params.row;
        let icon = null;
        let tooltipText = "";
        //@ts-ignore-next-line
        if (is_exported) {
          icon = (
            <>
              <TaskIcon color="primary" sx={{ opacity: 0.8 }} />
            </>
          );
          tooltipText = t("common.exported");
        }
        return icon ? (
          <Tooltip title={tooltipText}>
            <div style={{ display: "flex", alignItems: "center" }}>{icon}</div>
          </Tooltip>
        ) : null;
      },
    },

    {
      field: "created_at",
      headerName: t("email.receivedAt"),
      width: 150,
      //@ts-ignore-next-line
      renderCell: (params: GridValueGetterParams) => <TimeAgo date={new Date(params.value as string)} />,
    },
  ];

  const idColumn: GridColDef = {
    field: "id",
    headerName: "ID",
    width: 70,
    headerAlign: "center",
    align: "center",
  };

  const columns = settings.showIdColumn ? [idColumn, ...baseColumns] : baseColumns;

  const handleDragEnd = (result: any) => {
    // Handle the drag end event
  };

  return (
    <>
      <style>
        {`
        .MuiDataGrid-row .actions {
          visibility: hidden;
        }
        .MuiDataGrid-row:hover .actions {
          visibility: visible;
        }
      `}
      </style>
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} sx={{ mb: 1 }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <IconButton component={Link} sx={{ mr: 2 }} to="/">
            <ArrowBackIcon />
          </IconButton>
          <Typography fontWeight={"bold"} variant="h5" component="h1" noWrap sx={{ mb: 2 }}>
            {workflow?.title || t("common.loading")}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          <AssignEmailBtn ids={rowSelectionModel as number[]} />
          <DownloadCsvBtn ids={rowSelectionModel as number[]} />
          <DeleteEmailBtn ids={rowSelectionModel as number[]} />
          <UploadFileIcon sx={{ cursor: "pointer", opacity: 0.7 }} onClick={handleOpenUploadDialog} />
          {isUploadDialogOpen && workflowId && <FileUploadDialog onClose={handleCloseUploadDialog} />}
          <WorkflowSettings workflowId={Number(workflowId)} />
          {workflowId && <EmailCopyBtn workflowId={Number(workflowId)} />}
        </Stack>
      </Stack>
      <DataGrid
        rows={data?.results || []}
        columns={columns}
        sx={{ flex: 1 }}
        loading={isLoading}
        pageSizeOptions={[10, 20, 50]}
        paginationMode="server"
        rowCount={data?.count || 0}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        checkboxSelection
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        isRowSelectable={(params: GridRowParams) => params.row.status === "PROCESSED"}
        disableColumnMenu
        sortingMode="server"
      />
      <Stack justifyContent="space-between" direction="row" gap={1}>
        {settings.showBenchmark && <BenchmarkBtn ids={rowSelectionModel as number[]} />}
      </Stack>
    </>
  );
}

export default withAuth(EmailListPage);
