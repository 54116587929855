import React, { createContext, useContext, useEffect, useMemo, useCallback, useState } from 'react';
import { FieldValues, UseFormReturn, useForm } from 'react-hook-form';
import { IEmailRead } from '../../emailTypes';
import { sanitizeParsedInfo } from '../tabs/fields/EmailFieldsTab';
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

interface EmailFormContextType {
    form: UseFormReturn<any>;
    isDirty: any;
    errors: any;
    isSubmitting: boolean;
    handleValidation2: (data: any) => Promise<boolean>;
    isDisableValidation2: boolean;
    setIsDisableValidation2: (value: boolean) => void;
}

const EmailFormContext = createContext<EmailFormContextType | null>(null);

export const useEmailForm = () => {
    const context = useContext(EmailFormContext);
    if (!context) {
        throw new Error('useEmailForm must be used within an EmailFormProvider');
    }
    return context;
};

interface EmailFormProviderProps {
    children: React.ReactNode;
    email: IEmailRead;
    onValidation2Success: (data: any) => Promise<boolean>;
    isSubmitting: boolean;
    validationPlugins?: any[];
}

export const EmailFormProvider: React.FC<EmailFormProviderProps> = ({
    children,
    email,
    onValidation2Success,
    isSubmitting,
    validationPlugins = [],
}) => {
    const { t } = useTranslation();
    const [isDisableValidation2, setIsDisableValidation2] = useState<boolean>(false);


    const parsed_info = JSON.stringify(email.parsed_info); // WORKAROUND: This is done so we correctly rerender the component when the parsed_info changes
    const ui_schema = JSON.stringify(email.ui_schema); // WORKAROUND: This is done so we correctly rerender the component when the ui_schema changes
    const sanitizedParsedInfo = useMemo(
        () => sanitizeParsedInfo(email.parsed_info, email.ui_schema),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [email, parsed_info, ui_schema]
    );

    const form = useForm<any>({
        defaultValues: sanitizedParsedInfo,
        resolver: async (values, context, options) => {
            let errors = {};
            for (const plugin of validationPlugins) {
                if (plugin.getResolver) {
                    const resolver = plugin.getResolver();
                    if (resolver) {
                        const result = await resolver(values, context, options);
                        errors = { ...errors, ...result.errors };
                    }
                }
            }
            return { values, errors };
        },
    });

    // Update form default values when email data changes. this is necessary to reset isDirty flag when the form is submitted
    useEffect(() => {
        form.reset(sanitizedParsedInfo);
    }, [sanitizedParsedInfo, form]);

    /**
     * Validate the form and call onValidation2Success if the form is valid.
     * If the form is invalid, show an error message and return false
     */
    const handleValidation2 = useCallback(async (data: FieldValues) => {
        try {
            if (isDisableValidation2) {
                return true
            }

            for (const plugin of validationPlugins) {
                if (plugin.validate) {
                    const result = await plugin.validate(data);
                    if (!result.isValid) {
                        Object.entries(result.errors || {}).forEach(([field, message]) => {
                            form.setError(field as any, { type: 'manual', message: message as string });
                        });
                        throw new Error("Validation failed");
                    }
                }
            }
            // If the form is valid, call onValidation2Success and return its result
            return onValidation2Success ? await onValidation2Success(data) : true;
        } catch (error) {
            enqueueSnackbar(t("email.formError"), { variant: "error" });
            return false;
        }
    }, [validationPlugins, form, onValidation2Success, isDisableValidation2, t]);

    const isDirty = form.formState.isDirty; // This is done so we correctly rerender the component when the form is dirty
    const errors = JSON.stringify(form.formState.errors); // WORKAROUND: This is done so we correctly rerender the component when the errors change
    const value = useMemo(() => ({
        form,
        isDirty,
        errors,
        isSubmitting,
        isDisableValidation2,
        setIsDisableValidation2,
        handleValidation2,
    }), [form, isDirty, errors, isSubmitting, handleValidation2, isDisableValidation2, setIsDisableValidation2]);

    return (
        <EmailFormContext.Provider value={value}>
            {children}
        </EmailFormContext.Provider>
    );
};
