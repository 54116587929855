import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { Allotment } from "allotment";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { EmailFormProvider } from "./context/EmailFormContext";
import { getFormValidationPlugins } from "./tabs/fields/dynamicForm/plugins/externalValidationPluginRegistry";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import "react18-json-view/src/style.css";
import { useGetEmailQuery, usePatchEmailMutation } from "../../../app/services/appApi";
import { withAuth } from "@core/auth/authWrappers";
import EmailToolbar from "../shared/EmailToolbar";
import ErrorPlaceholder from "./ErrorPlaceholder";
import LoadingPlaceholder from "./LoadingPlaceholder";
import EmailTabsColumn from "./tabs/EmailTabsColumn";
import EmailContent from "./viewer/EmailViewerColumn";
import { HighlightAreaWithLabel } from "./viewer/PDFViewer";

export interface HighlightAreaWithLabelAndId extends HighlightAreaWithLabel {
  tabID: string;
}

function EmailDetailPage() {
  const { t } = useTranslation();
  const { id, workflowId } = useParams<{ id: string; workflowId: string }>();

  const [tabID, setTabID] = useState<string | null>(null);
  const [activeArea, setActiveArea] = useState<HighlightAreaWithLabelAndId | null>(null);

  const { data: email, isLoading, isFetching, isError } = useGetEmailQuery(Number(id), { skip: !id });
  const [patchEmail, { isLoading: isSubmitting }] = usePatchEmailMutation();

  /**
   * Handles the submission of the email form data.
   * Displays a success or error snackbar based on the result.
   */
  const handleEmailPatching = useCallback(async (data: any): Promise<boolean> => {
    if (!id) {
      return false;
    }
    try {
      await patchEmail({ id: Number(id), body: { parsed_info: data } }).unwrap();
      enqueueSnackbar(t("common.dataUpdateSuccess"), { variant: "success" });
      return true;
    } catch (error) {
      enqueueSnackbar(t("common.dataUpdateFailure"), { variant: "error" });
      return false;
    }
  }, [id, patchEmail, t]);


  const handleTarget = (target: HighlightAreaWithLabelAndId) => {
    setActiveArea(target);
    setTabID(target.tabID);
  };

  const handleAttachmentChange = (_: React.SyntheticEvent, newValue: string) => {
    setTabID(newValue);
  };

  useEffect(() => {
    if (email) {
      setTabID((prevTabID) => {
        if (prevTabID === null) {
          return email.attachments.length > 0 ? `attachment::${email.attachments[0].id}` : "email";
        } else if (prevTabID === "email") {
          return "email";
        } else {
          return email.attachments.length > 0 ? `attachment::${email.attachments[0].id}` : "email";
        }
      });
    }
  }, [email]);

  const validationPlugins = useMemo(
    () => getFormValidationPlugins(email?.workflow?.name || ''),
    [email?.workflow?.name]
  );

  if (isLoading) {
    return <LoadingPlaceholder />;
  }

  if (isError || !email) {
    return <ErrorPlaceholder />;
  }

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <IconButton component={Link} to={`/workflow/${workflowId}`} sx={{ mr: 2 }}>
          <ArrowBackIcon />
        </IconButton>
        <Box sx={{ flexGrow: 1, mb: 2, width: "100px" }}>
          <Typography variant="h5" component="h1" noWrap>
            <b>{email.subject === "" ? t("email.emailWithoutSubject") : (email.subject ?? t("email.loadingEmail"))}</b>
          </Typography>
        </Box>
        {id && <EmailToolbar emailId={Number(id)} email={email} />}
      </Stack>
      <EmailFormProvider
        email={email}
        onValidation2Success={handleEmailPatching}
        isSubmitting={isSubmitting}
        validationPlugins={validationPlugins}
      >
        <Allotment
          onDragEnd={(sizes) => localStorage.setItem("emailDetailSplit", JSON.stringify(sizes))}
          defaultSizes={JSON.parse(localStorage.getItem("emailDetailSplit") || "[0.66,0.33]")}
        >
          <EmailContent
            email={email}
            tabID={tabID || "email"}
            handleAttachmentChange={handleAttachmentChange}
            activeArea={activeArea}
          />
          <EmailTabsColumn
            email={email}
            isLoading={isSubmitting || isFetching}
            handleTarget={handleTarget}
          />
        </Allotment>
      </EmailFormProvider>
    </>
  );
}

export default withAuth(EmailDetailPage);