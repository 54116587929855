import React from "react";
import { Control, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, Button, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { EmailDetailAccordion } from "../EmailDetailAccordion";
import DynamicFieldRenderer, { EmailViewer } from "./DynamicFieldRenderer";
import { UISchema, UISchemaField, ArrayUISchemaField } from "./dynamicFormTypes";
import VerifiedBadge from "./VerifiedBadge";
import { ItemActionsToolbar } from "../ItemActions";

interface DynamicEmailFormProps {
  uiSchema: UISchema;
  control: Control<any>;
  disabled?: boolean;
  onTarget: (target: EmailViewer) => void;
}
const DynamicEmailForm: React.FC<DynamicEmailFormProps> = ({
  uiSchema,
  control,
  disabled,
  onTarget,
}) => {
  const { t } = useTranslation();

  const renderField = (field: UISchemaField, parentKey: string = "", index: number = 0) => {
    const fieldKey = field.key ? `${parentKey}${field.key}` : `${parentKey}field_${index}`;

    switch (field.type) {
      case "group":
        return (
          <EmailDetailAccordion key={fieldKey} id={fieldKey} label={field.label}>
            <Stack spacing={2}>
              {field.fields.map((subField, subIndex) =>
                renderField(subField, field.key ? `${fieldKey}.` : "", subIndex)
              )}
            </Stack>
          </EmailDetailAccordion>
        );
      case "array":
        return (
          <ArrayField
            key={fieldKey}
            field={field}
            parentKey={fieldKey}
            disabled={disabled}
            onTarget={onTarget}
          />
        );
      default:
        return (
          <DynamicFieldRenderer
            key={fieldKey}
            fieldName={fieldKey}
            fieldSchema={field}
            control={control}
            disabled={disabled}
            onTarget={onTarget}
          />
        );
    }
  };

  return (
    <Box>
      {uiSchema.fields.map((field, index) => renderField(field, "", index))}
    </Box>
  );
};

interface ArrayFieldProps {
  field: ArrayUISchemaField;
  parentKey: string;
  disabled?: boolean;
  onTarget: (target: EmailViewer) => void;
}

const ArrayField: React.FC<ArrayFieldProps> = ({ field, parentKey, disabled, onTarget }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { fields, append, move, remove, insert } = useFieldArray({
    control,
    name: parentKey,
  });

  return (
    <>
      {fields.map((item, index) => {
        return (
          <EmailDetailAccordion
            key={item.id}
            id={`${parentKey}-${index}`}
            label={
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography component="span" variant="subtitle1">{`${field.label} ${index + 1}`}</Typography>
                <VerifiedBadge item={item} />
              </Stack>
            }
          >
            <Stack spacing={2}>
              {field.fields.map((subField) => {
                const nestedParentKey = `${parentKey}.${index}.${subField.key}`;

                if (subField.type === "array") {
                  return (
                    <ArrayField
                      key={nestedParentKey}
                      field={subField as ArrayUISchemaField}
                      parentKey={nestedParentKey}
                      disabled={disabled}
                      onTarget={onTarget}
                    />
                  );
                }

                return (
                  <DynamicFieldRenderer
                    key={nestedParentKey}
                    fieldName={nestedParentKey}
                    fieldSchema={subField}
                    control={control}
                    disabled={disabled}
                    onTarget={onTarget}
                  />
                );
              })}
              <ItemActionsToolbar
                fields={fields}
                move={move}
                remove={remove}
                insert={insert}
                index={index}
                item={item}
                disabled={disabled}
              />
            </Stack>
          </EmailDetailAccordion>
        );
      })}
      <Button
        fullWidth
        color="primary"
        disabled={disabled}
        onClick={() => append({})}
        size="small"
        startIcon={<AddIcon fontSize="inherit" />}
        sx={{ my: 2 }}
        variant="outlined"
      >
        {t("email.addRow", { row: field.label })}
      </Button>
    </>
  );
};

export default DynamicEmailForm;