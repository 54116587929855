import { Box, Button, CircularProgress, LinearProgress, Typography, useTheme, Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetSheetImportStatusQuery, useTriggerSheetImportMutation } from "../../app/services/appApi";
import { SheetImportProgress } from "./sheetImportTypes";

type ProgressType =
  | SheetImportProgress["addresses"]
  | SheetImportProgress["contacts"]
  | SheetImportProgress["products"];

const ProgressBar: React.FC<{ progress: ProgressType; label: string }> = ({ progress, label }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const percentage = progress.total > 0 ? (progress.current / progress.total) * 100 : 0;
  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="subtitle1" fontWeight="medium">{t(label)}</Typography>
      <LinearProgress
        variant="determinate"
        value={percentage}
        sx={{
          mt: 1,
          mb: 1,
          height: 8,
          borderRadius: 4,
          backgroundColor: theme.palette.grey[200],
          '& .MuiLinearProgress-bar': {
            borderRadius: 4,
          },
        }}
      />
      <Box display="flex" justifyContent="space-between">
        <Typography variant="body2">{`${Math.round(percentage)}% (${progress.current}/${progress.total})`}</Typography>
        <Typography variant="body2">
          {t("sheetImport.progress.details", { created: progress.created, updated: progress.updated, deleted: progress.deleted })}
        </Typography>
      </Box>
    </Box>
  );
};

const SheetImportComponent: React.FC = () => {
  const { t } = useTranslation();
  const [triggerImport, { isLoading: isImportTriggering }] = useTriggerSheetImportMutation();
  const theme = useTheme();
  const [taskId, setTaskId] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [importComplete, setImportComplete] = useState(false);

  const { data: importStatus, isLoading: isImportStatusLoading, error: importStatusError } = useGetSheetImportStatusQuery(
    { task_id: taskId || "" },
    { skip: !taskId, pollingInterval: 1000 },
  );

  useEffect(() => {
    if (importStatus && importStatus.status === "SUCCESS") {
      setTaskId(null);
      setFile(null);
      setImportComplete(true);
    }

    // Handle non-200 status responses
    if (importStatusError) {
      console.error("Import status error:", importStatusError);
      setTaskId(null);
    }
  }, [importStatus, taskId, importStatusError]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile && selectedFile.name.endsWith(".xlsx")) {
      setFile(selectedFile);
    } else {
      alert(t("sheetImport.error.invalidFile"));
      event.target.value = "";
    }
  };

  const handleImportClick = async () => {
    if (!file) return;

    try {
      const result = await triggerImport(file).unwrap();
      setTaskId(result.task_id);
    } catch (error) {
      console.error("Failed to trigger import:", error);
    }
  };

  const isImporting = !!taskId && !importComplete;

  const getImportStatusMessage = () => {
    if (!importStatus) return t("sheetImport.status.initializing");
    switch (importStatus.status) {
      case "IMPORTING":
        return t("sheetImport.status.importing");
      case "SUCCESS":
        return t("sheetImport.status.completed");
      default:
        return t("sheetImport.status.inProgress");
    }
  };

  const renderImportReport = () => {
    if (!importStatus || !importStatus.progress) return null;
    const { products, contacts, addresses } = importStatus.progress;
    const totalCreated = products.created + contacts.created + addresses.created;
    const totalUpdated = products.updated + contacts.updated + addresses.updated;
    const totalDeleted = products.deleted + contacts.deleted + addresses.deleted;

    return (
      <Alert severity="success" sx={{ mt: 3 }}>
        <Typography variant="h6" gutterBottom>{t("sheetImport.success.title")}</Typography>
        <Typography>{t("sheetImport.success.totalProcessed", { total: totalCreated + totalUpdated + totalDeleted })}</Typography>
        <Typography>{t("sheetImport.success.created", { count: totalCreated })}</Typography>
        <Typography>{t("sheetImport.success.updated", { count: totalUpdated })}</Typography>
        <Typography>{t("sheetImport.success.deleted", { count: totalDeleted })}</Typography>
      </Alert>
    );
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <input
          accept=".xlsx"
          style={{ display: "none" }}
          id="raised-button-file"
          type="file"
          onChange={handleFileChange}
          disabled={isImportTriggering || isImporting}
        />
        <label htmlFor="raised-button-file">
          <Button
            variant="contained"
            component="span"
            disabled={isImportTriggering || isImporting}
            size="large"
            sx={{ px: 3, py: 1 }}
          >
            {t("sheetImport.button.selectFile")}
          </Button>
        </label>
        <Button
          variant="contained"
          onClick={handleImportClick}
          disabled={!file || isImportTriggering || isImporting}
          startIcon={isImportTriggering || isImporting ? <CircularProgress size={20} color="inherit" /> : null}
          size="large"
          sx={{ px: 4, py: 1 }}
        >
          {t("sheetImport.button.startImport")}
        </Button>
      </Box>

      {file && (
        <Typography variant="body2" sx={{ mt: 2, color: theme.palette.text.secondary }}>
          {t("sheetImport.label.selectedFile")}: {file.name}
        </Typography>
      )}

      {importStatusError && (
        <Alert severity="error" sx={{ mt: 3 }}>
          {t("sheetImport.error.fetchingStatus")}
        </Alert>
      )}

      {(isImporting || importComplete) && (
        isImporting ? (
          <>
            <Typography sx={{ mt: 3 }} variant="h6" gutterBottom>{getImportStatusMessage()}</Typography>
            {importStatus && importStatus.progress && (
              <>
                <ProgressBar progress={importStatus.progress.products} label="sheetImport.progress.products" />
                <ProgressBar progress={importStatus.progress.contacts} label="sheetImport.progress.contacts" />
                <ProgressBar progress={importStatus.progress.addresses} label="sheetImport.progress.addresses" />
              </>
            )}
          </>
        ) : (
          renderImportReport()
        )
      )}
    </Box>
  );
};

export default SheetImportComponent;
