import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export function ErrorFallback({ error, resetErrorBoundary }: any) {
  const { t } = useTranslation();

  const handleForceRefresh = () => {
    window.location.reload();
  }

  return (
    <Stack spacing={2} direction="column" justifyContent="center" alignItems="center" sx={{ height: "60vh" }}>
      <h1>{t("error.somethingWentWrong")}</h1>
      <Typography variant="body1" align="center">
        {t("error.unexpectedError")}
        <br />
        {t("error.contactSupport")}
      </Typography>
      <Stack spacing={2} direction="column" justifyContent="center" alignItems="center" sx={{ width: "100%", maxWidth: 300 }}>
        <Button fullWidth variant="contained" onClick={handleForceRefresh}>
          {t("error.reloadApp")}
        </Button>
        <Typography variant="body2" align="center">
          oppure
        </Typography>
        <Button fullWidth component="a" variant="outlined" color="primary" href="/">
          {t("error.goBackHome")}
        </Button>
      </Stack>
    </Stack>
  );
}
