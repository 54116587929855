import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

interface UnsavedChangesDialogProps {
  open: boolean;
  onClose: () => void;
  onContinue: () => void;
  onSave: () => void;
}

const UnsavedChangesDialog: React.FC<UnsavedChangesDialogProps> = ({ open, onClose, onContinue, onSave }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      aria-labelledby="unsaved-changes-dialog-title"
      aria-describedby="unsaved-changes-dialog-description"
    >
      <DialogTitle id="unsaved-changes-dialog-title">
        {t('email.unsavedChangesDialog.title')}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="unsaved-changes-dialog-description">
          {t('email.unsavedChangesDialog.message')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onContinue} color="primary">
          {t('email.unsavedChangesDialog.continue')}
        </Button>
        <Button onClick={onSave} color="primary" variant="contained">
          {t('email.unsavedChangesDialog.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnsavedChangesDialog;