/**
 * User
 */

export interface IUserBase {
  username: string;
  display_name: string;
  role: UserRoles;
}
export interface IUserCreate extends IUserBase {
  password: string;
  organization_id: string;
}
export interface IUserUpdate extends Omit<IUserCreate, "password"> {
  password?: string;
}
export interface IUserDelete extends IUserBase {
  id: number;
}
export interface IUserRead extends IUserBase {
  id: number;
  organization: IOrganizationRead;
  created_at: string;
  updated_at: string;
  authz_sub: IAuthzSub;
}

/**
 * Organization
 */
export interface IOrganizationBase {
  name: string;
  is_deactivated: boolean;
}
export interface IOrganizationCreate extends IOrganizationBase {}
export interface IOrganizationUpdate extends IOrganizationCreate {}
export interface IOrganizationDelete extends IOrganizationBase {
  id: string;
}
export interface IOrganizationRead extends IOrganizationBase {
  id: string;
  created_at: string;
  updated_at: string;
}

/**
 * API Requests and responses
 */

/**
 * ILoginRequest is the type used for the login endpoint.
 * It contains the username and password.
 */

export interface ILoginRequest {
  username: string;
  password: string;
}
/**
 * ILoginResponse is the type returned by the login endpoint.
 * It contains the token to be used for subsequent requests.
 */

export interface ILoginResponse {
  token: string;
}

export enum UserRoles {
  ROOT = "ROOT",
  SUPERADMIN = "SUPERADMIN",
  ADMIN = "ADMIN",
  MODERATOR = "MODERATOR",
  USER = "USER",
  GUEST = "GUEST",
}

export interface IChangePasswordUser {
  old_password: string;
  new_password: string;
  confirm_password: string;
}

/**
 * Authorization framework types
 */

export interface IAuthzSub {
  user_id: string;
  org_id: string;
  role: UserRoles;
}
