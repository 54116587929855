import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { Box, Button, Typography } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/system/Stack";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDeleteWorkflowMutation, useGetWorkflowsByTitleQuery } from "../../app/services/appApi";
import { withAuth } from "@core/auth/authWrappers";
import { IWorkflowRead } from "@features/workflow/workflowTypes"
import WorkflowSearchBar from "./WorkflowSearchBar";
import WorkflowTableRowMenu from "./WorkflowTableRowMenu";
import DeleteDialog from "@core/delete_dialog/DeleteDialog";

const WorkflowAdminListPage = () => {

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  });

  const [searchValue, setSearchValue] = useState("");
  const { data, isLoading } = useGetWorkflowsByTitleQuery({
    page: paginationModel.page + 1,
    pageSize: paginationModel.pageSize,
    search: searchValue,
    show_all: true,
  });

  const [deleteWorkflow] = useDeleteWorkflowMutation();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [workflowToDelete, setWorkflowToDelete] = useState<IWorkflowRead | null>(null);
  const { t } = useTranslation();

  const handleDeleteConfirm = async () => {
    if (workflowToDelete) {
      try {
        await deleteWorkflow(workflowToDelete.id).unwrap();
        enqueueSnackbar(t("Model deleted", { model: "Workflow", count: 1 }), { variant: "success" });
      } catch (error) {
        enqueueSnackbar(t("Model deletion failed", { model: "Workflow", count: 1 }), { variant: "error" });
      }
    }
    setOpenDeleteDialog(false);
  };

  const columns: GridColDef[] = [
    {
      width: 80,
      field: "id",
      headerName: "Id",
    },
    {
      width: 120,
      field: "code",
      headerName: "Code",
    },
    {
      flex: 1,
      field: "title",
      headerName: "Title",
    },
    {
      flex: 1,
      field: "organization",
      headerName: "Organization",
      renderCell: (params) => `#${params.row?.organization?.id} ${params.row?.organization?.name || "unknown"}`,
    },
    {
      width: 80,
      field: "actions",
      headerName: "",
      renderCell: (params) =>
        <WorkflowTableRowMenu workflowId={params.row.id} duplicateBtn deleteBtn isDataGrid editBtn />,
    },
  ];

  return (
    <>
      <Stack justifyContent="space-between" gap={2} direction="row" sx={{ mb: 2, alignItems: "center" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <ManageAccountsIcon />
          <Typography variant="h5" component="h1" noWrap>
            <b> Flussi di lavoro</b>
          </Typography>
        </Box>
        <Stack direction="row" gap={2} alignItems="center">
          {/* <WorkflowSearchBar onSearch={setSearchValue} size="small" /> */}
        </Stack>
      </Stack>

      <DataGrid
        disableRowSelectionOnClick
        rows={data?.results || []}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        pageSizeOptions={[20, 50, 100]}
        sx={{ flex: 1 }}
        loading={isLoading}
        paginationMode="server"
        rowCount={data?.count || 0}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
      />

      <DeleteDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onConfirm={handleDeleteConfirm}
        itemName={workflowToDelete?.title || ""}
        itemType={t("Workflow")}
      />
    </>
  );
};

export default withAuth(WorkflowAdminListPage);