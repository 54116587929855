import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LoadingButton from "@mui/lab/LoadingButton";
import { Tooltip } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCertifyEmailMutation } from "../../../../../app/services/appApi";
import { IEmailRead } from "../../../emailTypes";

interface CertifyEmailBtnProps extends React.ComponentProps<typeof LoadingButton> {
  email: IEmailRead;
  disable?: boolean;
  forceEnable?: boolean;
}

function CertifyEmailBtn({ email, disable, forceEnable, ...props }: CertifyEmailBtnProps) {
  const { t } = useTranslation();

  const [certifyEmail, { isLoading, isSuccess, isError }] = useCertifyEmailMutation();

  const disabled = !forceEnable && (!email.is_saved || email.is_certified || disable)

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(t("email.certificationSuccess"), { variant: "success" });
    }
    if (isError) {
      enqueueSnackbar(t("email.certificationFailure"), { variant: "error" });
    }
  }, [isSuccess, isError, t]);

  return (
    <Tooltip title={t("email.certifyDataTooltip")}>
      <span>
        <LoadingButton
          variant="contained"
          color="primary"
          startIcon={<CheckCircleIcon />}
          {...props}
          onClick={() => certifyEmail({ id: email.id })}
          loading={isLoading}
          disabled={disabled}
        >
          {t("common.certify")}
        </LoadingButton>
      </span>
    </Tooltip>
  );
}

export default CertifyEmailBtn;
