import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Stack from '@mui/material/Stack';
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { LoadingButton } from "@mui/lab";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import { styled } from '@mui/material/styles';
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useAuthContext from "@core/auth/AuthContext";
import { withNoAuth } from "@core/auth/authWrappers";
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '10px',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  [theme.breakpoints.up('sm')]: {
    width: '450px',
  },
}));

function LoginForm() {
  const { t } = useTranslation();
  const { login } = useAuthContext();
  const [loading, setLoading] = React.useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { username: "", password: "" },
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 700));
    await login(data);
    setLoading(false);
  };


  return (
    <StyledCard>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2
        }}
      >
        <Stack direction="row" spacing={2} sx={{ mt: 'auto', alignSelf: 'center', mb: 3 }}>
          <Avatar sx={{ bgcolor: 'primary.main', width: 45, height: 45 }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography
            component="h1"
            variant="h4"
            sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)', textAlign: 'center' }}
          >
            {t("Log in")}
          </Typography>
        </Stack>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <FormControl>
            <Controller
              name="username"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  sx={{
                    borderRadius: '10px',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '10px',
                    },
                  }}
                  {...field}
                  id='username'
                  label={t("Username")}
                  type="username"
                  placeholder={t("Enter your username")}
                  autoComplete="off"
                  required
                  fullWidth
                  error={!!errors.username}
                  helperText={errors.username && t("Required field")}
                  autoFocus
                />
              )}
            />
          </FormControl>

          <FormControl>
            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  sx={{
                    borderRadius: '10px',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '10px',
                    },
                  }}
                  {...field}
                  id="password"
                  label={t("Password")}
                  placeholder="••••••"
                  type="password"
                  required
                  fullWidth
                  error={!!errors.password}
                  helperText={errors.password && t("Check your password again")}
                />
              )}
            />
          </FormControl>

          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label={t("Remember me")}
          />

          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            loading={loading}
            sx={{
              mt: 1,
              borderRadius: '10px',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
              '& .MuiOutlinedInput-root': {
                borderRadius: '10px',
              },
            }}
          >
            {t("Sign In")}
          </LoadingButton>
        </Box>

      </Box>
    </StyledCard>
  );
}

function WelcomeContent() {
  const { t } = useTranslation();
  return (
    <Stack spacing={2} sx={{ maxWidth: 400 }}>
      <Typography variant="h3" component="h1">
        {t("Benvenuto su Typelens")}
      </Typography>
      <Typography variant="body1" color="text.secondary">
        {t("Inserisci le tue credenziali per accedere")}
      </Typography>
    </Stack>
  );
}

function LoginPage() {
  return (
    <>
      <CssBaseline />
      <Stack
        direction="column"
        component="main"
        sx={{
          justifyContent: 'center',
          height: '100vh',
          minHeight: '100%',
          background: 'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 50%, 100%))',
        }}
      >
        <Stack
          direction={{ xs: 'column-reverse', md: 'row' }}
          sx={{
            justifyContent: 'center',
            gap: { xs: 6, sm: 12 },
            p: { xs: 2, sm: 4 },
            m: 'auto',
          }}
        >
          <WelcomeContent />
          <LoginForm />
        </Stack>
      </Stack>
    </>
  );
}

export default withNoAuth(LoginPage);