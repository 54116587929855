import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import { styled } from "@mui/system";
import { Allotment } from "allotment";
import "allotment/dist/style.css";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useGetBenchmarkReportQuery, useGetEmailQuery } from "../../app/services/appApi";
import { withAuth } from "@core/auth/authWrappers";
import EmailContent from "../email/emailDetailPage/viewer/EmailViewerColumn";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(2, 0),
}));

const ValueBox = styled(Box)<{ match: boolean }>(({ theme, match }) => ({
  padding: theme.spacing(1),
  backgroundColor: match ? green[100] : red[100],
  color: match ? green[900] : red[900],
  borderRadius: theme.shape.borderRadius,
}));

const MAX_NESTING_DEPTH = 5;

const BenchmarkDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: report, isLoading, error } = useGetBenchmarkReportQuery(Number(id));
  const { t } = useTranslation();
  const [selectedEmailId, setSelectedEmailId] = useState<string | null>(null);
  const { data: selectedEmail } = useGetEmailQuery(Number(selectedEmailId), { skip: !selectedEmailId });
  const [currActiveTab, setCurrActiveTab] = useState<string>("email");

  if (isLoading) return <Typography>{t("Loading...")}</Typography>;
  if (error) return <Typography color="error">{t("Error loading benchmark report")}</Typography>;
  if (!report) return <Typography>{t("Benchmark report not found")}</Typography>;

  const getColorForAccuracy = (accuracy: number) => {
    const hue = (accuracy * 100 * 120) / 100; // 0 is red, 120 is green
    return `hsl(${hue}, 80%, 50%)`;
  };

  const renderFieldAccuracies = () => {
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="field-accuracies-content"
          id="field-accuracies-header"
        >
          <Typography variant="h6">{t("Field Accuracies")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{t("Field")}</TableCell>
                  <TableCell align="right">{t("Accuracy")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(report.field_accuracies).map(([field, accuracy]) => (
                  <TableRow key={field}>
                    <TableCell component="th" scope="row">
                      {field}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        backgroundColor: getColorForAccuracy(accuracy),
                        color: accuracy > 0.5 ? "black" : "white",
                        fontWeight: "bold",
                      }}
                    >
                      {(accuracy * 100).toFixed(2)}%
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderComparisonResults = () => {
    return Object.entries(report.comparison_results).map(([emailId, emailComparison]) => (
      <Accordion key={emailId}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`email-${emailId}-content`}
          id={`email-${emailId}-header`}
        >
          <Typography variant="h6">
            {t("Email ID")}: {emailId} - {t("Accuracy")}: {(report.email_accuracies[emailId] * 100).toFixed(2)}%
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box mb={2}>
            <Chip label={t("View Email")} onClick={() => setSelectedEmailId(emailId)} color="primary" clickable />
          </Box>
          {renderComparison(emailComparison, 0)}
        </AccordionDetails>
      </Accordion>
    ));
  };

  const renderComparison = (comparison: any, depth: number) => {
    if (depth > MAX_NESTING_DEPTH) {
      return <Typography color="error">{t("Maximum nesting depth reached")}</Typography>;
    }

    if (Array.isArray(comparison)) {
      return (
        <Box mb={2}>
          <Typography variant="subtitle2" gutterBottom>
            {t("Array")} ({comparison.length} {t("items")})
          </Typography>
          {comparison.map((item, index) => (
            <Paper key={index} sx={{ p: 2, mb: 2 }}>
              <Typography variant="subtitle2" gutterBottom>
                {t("Item")} {index + 1}
              </Typography>
              {renderComparison(item, depth + 1)}
            </Paper>
          ))}
        </Box>
      );
    }

    if (typeof comparison === "object" && comparison !== null) {
      if ("match" in comparison && "original" in comparison && "cloned" in comparison) {
        return (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <ValueBox match={comparison.match}>
                <Typography>{comparison.original}</Typography>
              </ValueBox>
            </Grid>
            <Grid item xs={4}>
              <ValueBox match={comparison.match}>
                <Typography>{comparison.cloned}</Typography>
              </ValueBox>
            </Grid>
          </Grid>
        );
      }

      return Object.entries(comparison).map(([key, value]) => (
        <Box key={key} mb={2}>
          <Typography variant="subtitle1" gutterBottom>
            {key}
          </Typography>
          {renderComparison(value, depth + 1)}
        </Box>
      ));
    }

    return <Typography>{JSON.stringify(comparison)}</Typography>;
  };

  return (
    <Box sx={{ height: "calc(100vh - 64px)", display: "flex", flexDirection: "column" }}>
      <Box sx={{ p: 2 }}>
        <IconButton component={Link} sx={{ mr: 2 }} to="/benchmarks">
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" component="span">
          {t("Benchmark Report Details")}
        </Typography>
      </Box>
      <Allotment defaultSizes={[1, 1]}>
        <Allotment.Pane>
          <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
            {selectedEmailId && selectedEmail ? (
              <EmailContent
                email={selectedEmail}
                tabID={currActiveTab}
                handleAttachmentChange={(event, newValue) => setCurrActiveTab(newValue)}
                activeArea={null}
              />
            ) : (
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                <Typography>{t("No email selected")}</Typography>
              </Box>
            )}
          </Box>
        </Allotment.Pane>
        <Allotment.Pane>
          <Box sx={{ height: "100%", overflowY: "auto", p: 2 }}>
            <StyledPaper>
              <Typography variant="h6" gutterBottom>
                {t("Overall Information")}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography>
                    <strong>{t("ID")}:</strong> {report.id}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    <strong>{t("App Version")}:</strong> {report.app_version}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    <strong>{t("Overall Accuracy")}:</strong> {(report.overall_accuracy * 100).toFixed(2)}%
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    <strong>{t("Created At")}:</strong> {new Date(report.created_at).toLocaleString()}
                  </Typography>
                </Grid>
              </Grid>
            </StyledPaper>
            {renderFieldAccuracies()}
            <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
              {t("Comparison Results (Original / Extracted)")}
            </Typography>
            {renderComparisonResults()}
          </Box>
        </Allotment.Pane>
      </Allotment>
    </Box>
  );
};

export default withAuth(BenchmarkDetailPage);