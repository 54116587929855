import { IconBtn } from '@features/input/Btn';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemButton, ListItemText, Menu, MenuItem, styled, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePatchEmailMutation, useGetOrganizationUsersQuery } from '../../../app/services/appApi';
import useAuthContext from '@core/auth/AuthContext';
import { skipToken } from "@reduxjs/toolkit/query";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    minWidth: 500,
    maxWidth: '100vw',
    maxHeight: '80vh',
    borderRadius: '10px',
    boxShadow: theme.shadows[4],
    position: 'relative'
  },
  '& .MuiDialogTitle-root': {
    '& .MuiIconButton-root': {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.error.main,
      '&:hover': {
        color: theme.palette.error.dark,
        backgroundColor: 'transparent'
      }
    }
  }
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.divider}`
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(3)
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(0.5)
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: '10px',
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  transition: theme.transitions.create(['box-shadow', 'background-color']),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    boxShadow: theme.shadows[2]
  }
}));

type ButtonVariant = "text" | "outlined" | "contained";

interface IAssignEmailBtnProps extends Omit<React.ComponentProps<typeof Button>, "variant"> {
  ids: number[];
  variant?: ButtonVariant;
  onAssignSuccess?: () => void;
}

export default function AssignEmailBtn({ ids, variant = "contained", onAssignSuccess, ...props }: IAssignEmailBtnProps) {
  const { user } = useAuthContext();

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [patchEmail, { isLoading, isError, isSuccess, reset }] = usePatchEmailMutation();
  //TODO: update endpoint to block cross-organization list
  const { data: users = [], isLoading: isLoadingUsers } = useGetOrganizationUsersQuery(user?.organization.id.toString() ?? skipToken, { skip: !open });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUserSelect = (userId: number | null) => {
    if (ids.length > 0) {
      ids.forEach((id) => patchEmail({ id, body: { assigned_to_id: userId } }));
      handleClose();
    }
  };

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(t("email.assignEmailFailure"), { variant: "error" });
      reset();
    } else if (isSuccess) {
      enqueueSnackbar(t("email.assignEmailSuccess"), { variant: "success" });
      onAssignSuccess?.();
      reset();
    }
  }, [isError, isSuccess]);

  if (ids.length >= 1) {
    return (
      <>
        <IconBtn
          disabled={isLoading || ids.length === 0}
          onClick={handleClickOpen}
          {...props}
        >
          <PersonAddIcon />
        </IconBtn>

        <StyledDialog
          open={open}
          onClose={handleClose}
          aria-labelledby="assign-email-dialog-title"
        >
          <StyledDialogTitle id="assign-email-dialog-title">
            <Typography variant="h6">{t("email.selectUserToAssign")}</Typography>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </StyledDialogTitle>

          <StyledDialogContent>
            {isLoadingUsers ? (
              <Typography align="center" py={3}>
                {t("common.loading")}...
              </Typography>
            ) : users.length === 0 ? (
              <Typography align="center" py={3}>
                {t("email.noUsersFound")}
              </Typography>
            ) : (
              <List>
                {users.map((user) => (
                  <ListItem key={user.id} disablePadding sx={{ mb: 1 }}>
                    <StyledListItemButton onClick={() => handleUserSelect(user.id)}>
                      <ListItemText
                        primary={user.display_name}
                        primaryTypographyProps={{ fontWeight: 500 }}
                      />
                    </StyledListItemButton>
                  </ListItem>
                ))}
                <ListItem disablePadding sx={{ mb: 1 }}>
                  <StyledListItemButton onClick={() => handleUserSelect(null)}>
                    <ListItemText
                      primary={"Rimuovi assegnamento"}
                      sx={{ color: 'red' }}
                      primaryTypographyProps={{ fontWeight: 500 }}
                    />
                  </StyledListItemButton>
                </ListItem>
              </List>
            )}
          </StyledDialogContent>
        </StyledDialog>
      </>
    );
  }
}
