import React, { useEffect, useState } from "react";
import {
  useGetAddressesQuery,
  useDeleteAddressMutation,
} from "../../app/services/appApi";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import Stack from "@mui/system/Stack";
import { withAuth } from "@core/auth/authWrappers";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import PlaceIcon from '@mui/icons-material/Place';
import { IAddressRecord } from "./addressTypes";
import AddressSearchBar from "./AddressSearchBar";


const AddressListPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  });

  const [searchValue, setSearchValue] = useState("");
  const { data, isLoading } = useGetAddressesQuery(
    {
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
      search: searchValue,
    }
  );


  const columns: GridColDef[] = [
    {
      field: "code",
      headerName: t("address.list.code"),
    },
    {
      field: "contact_name",
      headerName: t("address.list.contact"),
      flex: 1,
    },
    {

      field: "formatted_address",
      headerName: t("address.list.address"),
      flex: 2,
    },
  ];

  return (
    <>
      <Stack justifyContent="space-between" gap={2} direction="row" sx={{ mb: 2, alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <PlaceIcon />
          <Typography variant="h5" component="h1" noWrap>
            <b>{t("address.list.title")}</b>
          </Typography>
        </Box>


        <Stack direction="row" gap={2} alignItems="center">
          <AddressSearchBar onSearch={setSearchValue} size="small" />
          <Tooltip title={t("address.list.noPermissionTooltip")}>
            <div>
              <Button
                startIcon={<AddIcon />}
                variant="contained"
                color="primary"
                disabled
              >
                {t("address.list.add")}
              </Button>
            </div>
          </Tooltip>
        </Stack>
      </Stack>

      <DataGrid
        disableRowSelectionOnClick
        rows={data?.results || []}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        pageSizeOptions={[20, 50, 100]}
        sx={{ height: "80vh", my: 2 }}
        loading={isLoading}
        paginationMode="server"
        rowCount={data?.count || 0}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
      />
    </>
  );
}


const AddressDeleteBtn: React.FC<{ addressId: Pick<IAddressRecord, "id"> }> = ({ addressId }) => {
  const [triggerDelete, { isLoading, isError, isSuccess }] = useDeleteAddressMutation();
  const { t } = useTranslation();

  // Handle error and success notifications
  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(t("Model deleted", { model: "Address", count: 1 }), { variant: "success" });
    }
    if (isError) {
      enqueueSnackbar(t("Model deletion failed", { model: "Address", count: 1 }), { variant: "error" });
    }
  }, [isSuccess, isError, t]);


  return (
    <IconButton
      onClick={() => triggerDelete(addressId)}
      color="error"
      disabled={isLoading}
    >
      <DeleteOutlineOutlinedIcon />
    </IconButton>
  )


}

export default withAuth(AddressListPage);
