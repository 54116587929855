import LanguageIcon from "@mui/icons-material/Language";
import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { withAuth } from "@core/auth/authWrappers";
import LanguageSelect from "./LanguageSelect";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

const SettingsPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Stack >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <SettingsOutlinedIcon />
        <Typography variant="h5" component="h1" noWrap>
          <b>{t("settings.title")}</b>
        </Typography>
      </Box>
      <Container maxWidth="xs">

        <Stack spacing={3} alignItems="center" sx={{ width: '100%', mt: 4 }}>
          <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 1 }}>
            <LanguageIcon />
            <Typography variant="body1">{t("settings.language")}</Typography>
          </Stack>
          <LanguageSelect />
        </Stack>

      </Container>
    </Stack>

  );
};

export default withAuth(SettingsPage);
